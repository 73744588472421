import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import API from 'services/defaultInstance';
import * as actions from './index';

import {
  GetTiersResponse,
  GetProductsResponse,
  GetCustomersResponse,
  GetSeasonsResponse,
  GetZonesResponse,
} from 'models';
function* getTiers({ payload }: ReturnType<typeof actions.getTiersRequest>) {
  try {
    const params: any = {
      pageSize: 250,
    };
    if (payload.pageToken) {
      params.pageToken = payload.pageToken;
    }
    const { data }: GetTiersResponse = yield call(
      API.get,
      `/v1/admin/tier/tiers`,
      {
        params,
      }
    );
    yield put(
      actions.getTiersSuccess({
        entities: data.tiers,
        nextPageToken: data.nextPageToken,
      })
    );
  } catch (error) {
    yield put(actions.getTiersFailure());
  }
}
function* getSeasons({
  payload,
}: ReturnType<typeof actions.getSeasonsRequest>) {
  try {
    const params: any = {
      pageSize: 250,
    };
    if (payload.pageToken) {
      params.pageToken = payload.pageToken;
    }
    const { data }: GetSeasonsResponse = yield call(
      API.get,
      `/v1/admin/tier/seasons`,
      {
        params,
      }
    );
    yield put(
      actions.getSeasonsSuccess({
        entities: data.seasons,
        nextPageToken: data.nextPageToken,
      })
    );
  } catch (error) {
    yield put(actions.getSeasonsFailure(''));
  }
}
function* getZones({ payload }: ReturnType<typeof actions.getZonesRequest>) {
  try {
    const params: any = {
      pageSize: 250,
    };
    if (payload.pageToken) {
      params.pageToken = payload.pageToken;
    }
    const { data }: GetZonesResponse = yield call(
      API.get,
      `/v1/admin/tier/zones`,
      {
        params,
      }
    );
    yield put(
      actions.getZonesSuccess({
        entities: data.zones,
        nextPageToken: data.nextPageToken,
      })
    );
  } catch (error) {
    yield put(actions.getZonesFailure(''));
  }
}
function* getProducts({
  payload,
}: ReturnType<typeof actions.getProductsRequest>) {
  try {
    const params: any = {
      pageSize: 250,
    };
    if (payload.pageToken) {
      params.pageToken = payload.pageToken;
    }
    const { data }: GetProductsResponse = yield call(API.get, `/v1/products`, {
      params,
    });
    yield put(
      actions.getProductsSuccess({
        entities: data.products,
        nextPageToken: '',
      })
    );
  } catch (error) {
    yield put(actions.getProductsFailure(''));
  }
}
function* getCustomers({
  payload,
}: ReturnType<typeof actions.getCustomersRequest>) {
  try {
    const params: any = {
      pageSize: 250,
    };
    if (payload.pageToken) {
      params.pageToken = payload.pageToken;
    }
    const { data }: GetCustomersResponse = yield call(
      API.get,
      `/v1/admin/tier/customers`,
      {
        params,
      }
    );
    yield put(
      actions.getCustomersSuccess({
        entities: data.customers,
        nextPageToken: data.nextPageToken,
      })
    );
  } catch (error) {
    yield put(actions.getProductsFailure(''));
  }
}
function* watchGetTiersRequest() {
  yield takeLatest(actions.Types.getTiersRequest, getTiers);
}
function* watchGetSeasonsRequest() {
  yield takeLatest(actions.Types.getSeasonsRequest, getSeasons);
}
function* watchGetZonesRequest() {
  yield takeLatest(actions.Types.getZonesRequest, getZones);
}
function* watchGetProductsRequest() {
  yield takeLatest(actions.Types.getProductsRequest, getProducts);
}
function* watchGetCustomersRequest() {
  yield takeLatest(actions.Types.getCustomersRequest, getCustomers);
}
function* saga() {
  yield all([
    fork(watchGetTiersRequest),
    fork(watchGetSeasonsRequest),
    fork(watchGetZonesRequest),
    fork(watchGetProductsRequest),
    fork(watchGetCustomersRequest),
  ]);
}
export default saga;
