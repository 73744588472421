import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  Stock,
  StocksQueries,
  Lot,
  CreateStockToLotForm,
} from 'models';

type DialogID = null | 'confirmTransForm' | 'confirmSelectLot';
type Queries = DefaultQueries & StocksQueries;

export interface InitialState {
  stocksList: {
    data: Stock[];
    page: number;
    pageSize: number;
    pageTokens: string[];
    isLoading: boolean;
    error: string | null;
  };
  dialogID: DialogID;
  transForm: {
    isCreating: boolean;
    error: string | null;
    stock: Stock | null;
  };
  moveStockToLot: {
    isCreating: boolean;
    error: string | null;
    stock: Stock | null;
    lots: Lot[];
    isLoading: boolean;
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  stocksList: {
    data: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
    isLoading: false,
    error: null,
  },
  dialogID: null,
  transForm: {
    isCreating: false,
    error: null,
    stock: null,
  },
  moveStockToLot: {
    isCreating: false,
    error: null,
    stock: null,
    lots: [],
    isLoading: false,
  },
  exportData: {
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/inventory/stocks@cancelRequestAPI',
  onChangeDialogID = 'tier/inventory/stocks@onChangeDialogID',

  getStocksRequest = 'tier/inventory/stocks@getStocksRequest',
  getStocksSuccess = 'tier/inventory/stocks@getStocksSuccess',
  getStocksFailure = 'tier/inventory/stocks@getStocksFailure',
  stocksOnChangePage = 'tier/inventory/stocks@stocksOnChangePage',
  stocksOnChangePageSize = 'tier/inventory/stocks@stocksOnChangePageSize',
  confirmStockTransForm = 'tier/inventory/stocks@confirmStockTransForm',
  createTransFormRequest = 'tier/inventory/stocks@createTransFormRequest',
  createTransFormSuccess = 'tier/inventory/stocks@createTransFormSuccess',
  createTransFormFailure = 'tier/inventory/stocks@createTransFormFailure',
  openMoveStockToLotDialog = 'tier/inventory/stocks@openMoveStockToLotDialog',
  getLotsSuccess = 'tier/inventory/stocks@getLotsSuccess',
  getLotsFailure = 'tier/inventory/stocks@getLotsFailure',
  moveStockToLotRequest = 'tier/inventory/stocks@moveStockToLotRequest',
  moveStockToLotSuccess = 'tier/inventory/stocks@moveStockToLotSuccess',
  moveStockToLotFailure = 'tier/inventory/stocks@moveStockToLotFailure',
  exportCsvRequest = 'tier/inventory/stocks@exportCsvRequest',
  exportCsvSuccess = 'tier/inventory/stocks@exportCsvSuccess',
  exportCsvFailure = 'tier/inventory/stocks@exportCsvFailure',
}

export type GetStocksSuccess = Queries & {
  data: Stock[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getStocksRequest = createAction<Queries>(Types.getStocksRequest);
export const getStocksSuccess = createAction<GetStocksSuccess>(
  Types.getStocksSuccess
);
export const getStocksFailure = createAction<string>(Types.getStocksFailure);
export const confirmStockTransForm = createAction<Stock>(
  Types.confirmStockTransForm
);
export const stocksOnChangePage = createAction<Queries>(
  Types.stocksOnChangePage
);
export const stocksOnChangePageSize = createAction<Queries>(
  Types.stocksOnChangePageSize
);
export const createTransFormRequest = createAction(
  Types.createTransFormRequest
);
export const createTransFormSuccess = createAction(
  Types.createTransFormSuccess
);
export const createTransFormFailure = createAction<string>(
  Types.createTransFormFailure
);
export const openAddStockToLotDialog = createAction<Stock>(
  Types.openMoveStockToLotDialog
);
export const getLotsSuccess = createAction<Lot[]>(Types.getLotsSuccess);
export const getLotsFailure = createAction<string>(Types.getLotsFailure);
export const moveStockToLotRequest = createAction<CreateStockToLotForm>(
  Types.moveStockToLotRequest
);
export const moveStockToLotSuccess = createAction(Types.moveStockToLotSuccess);
export const moveStockToLotFailure = createAction<string | null>(
  Types.moveStockToLotFailure
);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.stocksList.isLoading = false;
    state.transForm.isCreating = false;
    state.dialogID = null;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.dialogID = action.payload;
  });
  builder.addCase(getStocksRequest, (state) => {
    state.stocksList.isLoading = true;
    state.stocksList.error = null;
  });
  builder.addCase(getStocksSuccess, (state, action) => {
    return {
      ...state,
      stocksList: {
        ...state.stocksList,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getStocksFailure, (state, action) => {
    state.stocksList.isLoading = false;
    state.stocksList.error = action.payload;
  });
  builder.addCase(confirmStockTransForm, (state, action) => {
    state.transForm.stock = action.payload;
    state.dialogID = 'confirmTransForm';
  });
  builder.addCase(createTransFormRequest, (state) => {
    state.transForm.isCreating = true;
    state.transForm.error = null;
  });
  builder.addCase(createTransFormSuccess, (state) => {
    state.transForm.isCreating = false;
    state.transForm.stock = null;
    state.dialogID = null;
  });
  builder.addCase(createTransFormFailure, (state, action) => {
    state.transForm.isCreating = false;
    state.transForm.error = action.payload;
  });
  builder.addCase(openAddStockToLotDialog, (state, action) => {
    state.moveStockToLot.isLoading = true;
    state.moveStockToLot.error = null;
    state.moveStockToLot.stock = action.payload;
    state.dialogID = 'confirmSelectLot';
    state.moveStockToLot.error = null;
  });
  builder.addCase(getLotsSuccess, (state, action) => {
    state.moveStockToLot.isLoading = false;
    state.moveStockToLot.lots = action.payload;
  });
  builder.addCase(getLotsFailure, (state, action) => {
    state.moveStockToLot.isLoading = false;
    state.moveStockToLot.error = action.payload;
  });
  builder.addCase(moveStockToLotRequest, (state) => {
    state.moveStockToLot.isCreating = true;
    state.moveStockToLot.error = null;
  });
  builder.addCase(moveStockToLotSuccess, (state) => {
    state.moveStockToLot.isCreating = false;
    state.moveStockToLot.stock = null;
    state.dialogID = null;
  });
  builder.addCase(moveStockToLotFailure, (state, action) => {
    state.moveStockToLot.isCreating = false;
    state.moveStockToLot.error = action.payload;
  });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
