import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  Order,
  OrdersQueries,
  CreateOrderForm,
  SelectOption,
  DefaultQueries,
  UpdateOrderForm,
  Tier,
  SaleInvoice,
  SaleTransaction,
} from 'models';

export type DialogId = null | 'updateStatus';
type Queries = DefaultQueries & OrdersQueries & {};

export interface InitialState {
  dialogId: DialogId;
  ordersList: {
    orders: Order[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  orderCreate: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
  };
  customersList: {
    isLoading: boolean;
    error: string | null;
    customers: SelectOption[];
  };
  orderDetail: {
    isLoading: boolean;
    error: string | null;
    order: Order | null;
    isUpdating: boolean;
    invoices: SaleInvoice[];
  };
  productsList: {
    isLoading: boolean;
    error: string | null;
    products: SelectOption[];
  };
  printOrder: {
    order: Order | null;
    isPrinting: 'ready' | 'pending' | 'error' | 'wait';
  };
  exportData: {
    isLoading: boolean;
  };
  tiers: {
    entries: Tier[];
    isLoading: boolean;
  };
  printInvoice: {
    invoice: SaleInvoice | null;
    isPrinting: 'ready' | 'pending' | 'error' | 'wait';
    transactions: SaleTransaction[];
  };
}
const initialState: InitialState = {
  dialogId: null,
  ordersList: {
    orders: [],
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  customersList: {
    customers: [],
    error: null,
    isLoading: false,
  },
  orderCreate: {
    error: null,
    isCreating: false,
    isLoading: false,
  },
  orderDetail: {
    error: null,
    isLoading: false,
    order: null,
    isUpdating: false,
    invoices: [],
  },
  productsList: {
    error: null,
    isLoading: false,
    products: [],
  },
  printOrder: {
    isPrinting: 'wait',
    order: null,
  },
  exportData: {
    isLoading: false,
  },
  tiers: {
    entries: [],
    isLoading: false,
  },
  printInvoice: {
    isPrinting: 'wait',
    invoice: null,
    transactions: [],
  },
};
export enum Types {
  cancelRequestAPI = 'tier/sale-orders@cancelRequestAPI',
  onChangeDialog = 'tier/sale-orders@onChangeDialog',
  getOrdersRequest = 'tier/sale-orders@getOrdersRequest',
  getOrdersSuccess = 'tier/sale-orders@getOrdersSuccess',
  getOrdersFailure = 'tier/sale-orders@getOrdersFailure',
  onChangeOrdersPage = 'tier/sale-orders@onChangeOrdersPage',
  onChangeOrdersPageSize = 'tier/sale-orders@onChangeOrdersPageSize',
  openCreateOrderPage = 'tier/sale-orders@openCreateOrderPage',
  createOrderRequest = 'tier/sale-orders@createOrderRequest',
  createOrderSuccess = 'tier/sale-orders@createOrderSuccess',
  createOrderFailure = 'tier/sale-orders@createOrderFailure',
  getCustomersRequest = 'tier/sale-orders@getCustomersRequest',
  getCustomersSuccess = 'tier/sale-orders@getCustomersSuccess',
  getCustomersFailure = 'tier/sale-orders@getCustomersFailure',
  getOrderRequest = 'tier/sale-orders@getOrderRequest',
  getOrderSuccess = 'tier/sale-orders@getOrderSuccess',
  getOrderFailure = 'tier/sale-orders@getOrderFailure',
  getProductsRequest = 'tier/sale-orders@getProductsRequest',
  getProductsSuccess = 'tier/sale-orders@getProductsSuccess',
  getProductsFailure = 'tier/sale-orders@getProductsFailure',
  updateOrderRequest = 'tier/sale-orders@updateOrderRequest',
  updateOrderSuccess = 'tier/sale-orders@updateOrderSuccess',
  updateOrderFailure = 'tier/sale-orders@updateOrderFailure',
  printOrderRequest = 'tier/sale-orders@printOrderRequest',
  printOrderReady = 'tier/sale-orders@printOrderReady',
  printOrderSuccess = 'tier/sale-orders@printOrderSuccess',
  printOrderFailure = 'tier/sale-orders@printOrderFailure',
  updateStatusRequest = 'tier/sale-orders@updateStatusRequest',
  updateStatusSuccess = 'tier/sale-orders@updateStatusSuccess',
  updateStatusFailure = 'tier/sale-orders@updateStatusFailure',
  setOrderUpdateStatus = 'tier/sale-orders@setOrderUpdateStatus',
  exportCsvRequest = 'tier/sale-orders@exportCsvRequest',
  exportCsvSuccess = 'tier/sale-orders@exportCsvSuccess',
  exportCsvFailure = 'tier/sale-orders@exportCsvFailure',
  getTiersRequest = 'tier/sale-orders@getTiersRequest',
  getTiersSuccess = 'tier/sale-orders@getTiersSuccess',
  getTiersFailure = 'tier/sale-orders@getTiersFailure',
  printInvoiceRequest = 'tier/sale-invoices@printInvoiceRequest',
  printInvoiceReady = 'tier/sale-orders@printInvoiceReady',
  printInvoiceSuccess = 'tier/sale-orders@printInvoiceSuccess',
  printInvoiceFailure = 'tier/sale-orders@printInvoiceFailure',
  setDataToPrint = 'tier/sale-orders@setDataToPrint',
}
export interface GetOrdersSuccess extends Queries {
  orders: Order[];
}
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialog = createAction<DialogId>(Types.onChangeDialog);
export const getOrdersRequest = createAction<Queries>(Types.getOrdersRequest);
export const getOrdersSuccess = createAction<GetOrdersSuccess>(
  Types.getOrdersSuccess
);
export const getOrdersFailure = createAction<string>(Types.getOrdersFailure);
export const onChangeOrdersPage = createAction<Queries>(
  Types.onChangeOrdersPage
);
export const onChangeOrdersPageSize = createAction<Queries>(
  Types.onChangeOrdersPageSize
);
export const createOrderRequest = createAction<CreateOrderForm>(
  Types.createOrderRequest
);
export const createOrderSuccess = createAction(Types.createOrderSuccess);
export const createOrderFailure = createAction<string>(
  Types.createOrderFailure
);
export const getCustomersRequest = createAction(Types.getCustomersRequest);
export const getCustomersSuccess = createAction<SelectOption[]>(
  Types.getCustomersSuccess
);
export const getCustomersFailure = createAction<string>(
  Types.getCustomersFailure
);
export const getOrderRequest = createAction<string>(Types.getOrderRequest);
export const getOrderSuccess = createAction<{
  order: Order;
  invoices: SaleInvoice[];
}>(Types.getOrderSuccess);
export const getOrderFailure = createAction<string>(Types.getOrderFailure);
export const getProductsRequest = createAction(Types.getProductsRequest);
export const getProductsSuccess = createAction<SelectOption[]>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);
export const openCreateOrderPage = createAction(Types.openCreateOrderPage);
export const updateOrderRequest = createAction<UpdateOrderForm>(
  Types.updateOrderRequest
);
export const updateOrderSuccess = createAction(Types.updateOrderSuccess);
export const updateOrderFailure = createAction<string>(
  Types.updateOrderFailure
);
export const printOrderRequest = createAction<Order>(Types.printOrderRequest);
export const printOrderSuccess = createAction(Types.printOrderSuccess);
export const printOrderReady = createAction(Types.printOrderReady);
export const printOrderFailure = createAction(Types.printOrderFailure);
export const updateStatusRequest = createAction(Types.updateStatusRequest);
export const updateStatusSuccess = createAction(Types.updateStatusSuccess);
export const updateStatusFailure = createAction<string>(
  Types.updateStatusFailure
);
export const setUpdateStatus = createAction<Order>(Types.setOrderUpdateStatus);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);
export const getTiersRequest = createAction(Types.getTiersRequest);
export const getTiersSuccess = createAction<Tier[]>(Types.getTiersSuccess);
export const getTiersFailure = createAction(Types.getTiersFailure);
export const printInvoiceRequest = createAction<SaleInvoice>(
  Types.printInvoiceRequest
);
export const printInvoiceSuccess = createAction(Types.printInvoiceSuccess);
export const printInvoiceReady = createAction(Types.printInvoiceReady);
export const printInvoiceFailure = createAction(Types.printInvoiceFailure);
export const setDataToPrint = createAction<{
  transactions: SaleTransaction[];
}>(Types.setDataToPrint);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(onChangeDialog, (state, action) => {
      return { ...state, dialogId: action.payload };
    })
    .addCase(getOrdersRequest, (state) => {
      return {
        ...state,
        ordersList: { ...state.ordersList, isLoading: true, error: null },
      };
    })
    .addCase(getOrdersSuccess, (state, action) => {
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getOrdersFailure, (state, action) => {
      state.ordersList.isLoading = false;
      state.ordersList.error = action.payload;
    })
    .addCase(createOrderRequest, (state) => {
      state.orderCreate.isLoading = true;
      state.orderCreate.error = null;
    })
    .addCase(createOrderSuccess, (state) => {
      state.orderCreate.isLoading = false;
      state.orderCreate.isCreating = false;
    })
    .addCase(createOrderFailure, (state, action) => {
      state.orderCreate.isLoading = false;
      state.orderCreate.error = action.payload;
    })
    .addCase(getCustomersRequest, (state) => {
      state.customersList.isLoading = true;
      state.customersList.error = null;
    })
    .addCase(getCustomersSuccess, (state, action) => {
      state.customersList.isLoading = false;
      state.customersList.customers = action.payload;
    })
    .addCase(getCustomersFailure, (state, action) => {
      state.customersList.isLoading = false;
      state.customersList.error = action.payload;
    })
    .addCase(getOrderRequest, (state) => {
      state.orderDetail.isLoading = true;
      state.orderDetail.error = null;
    })
    .addCase(getOrderSuccess, (state, action) => {
      state.orderDetail.isLoading = false;
      state.orderDetail.order = action.payload.order;
      state.orderDetail.invoices = action.payload.invoices;
    })
    .addCase(getOrderFailure, (state, action) => {
      state.orderDetail.isLoading = false;
      state.orderDetail.error = action.payload;
    })
    .addCase(getProductsRequest, (state) => {
      state.productsList.isLoading = true;
      state.productsList.error = null;
    })
    .addCase(getProductsSuccess, (state, action) => {
      state.productsList.isLoading = false;
      state.productsList.products = action.payload;
    })
    .addCase(getProductsFailure, (state, action) => {
      state.productsList.isLoading = false;
      state.productsList.error = action.payload;
    })
    .addCase(onChangeOrdersPage, (state) => {
      state.productsList.isLoading = false;
      state.productsList.error = null;
      state.customersList.isLoading = false;
      state.customersList.error = null;
    })
    .addCase(updateOrderRequest, (state) => {
      state.orderDetail.isUpdating = true;
      state.orderDetail.error = null;
    })
    .addCase(updateOrderSuccess, (state) => {
      state.orderDetail.isUpdating = false;
    })
    .addCase(updateOrderFailure, (state, action) => {
      state.orderDetail.isUpdating = false;
      state.orderDetail.error = action.payload;
    })
    .addCase(printOrderRequest, (state, { payload }) => {
      state.printOrder.isPrinting = 'pending';
      state.printOrder.order = payload;
    })
    .addCase(printOrderReady, (state) => {
      state.printOrder.isPrinting = 'ready';
    })
    .addCase(printOrderFailure, (state) => {
      state.printOrder.isPrinting = 'error';
      state.printOrder.order = null;
    })
    .addCase(printOrderSuccess, (state) => {
      state.printOrder.isPrinting = 'wait';
      state.printOrder.order = null;
    })
    .addCase(updateStatusRequest, (state) => {
      state.orderDetail.isUpdating = true;
      state.orderDetail.error = null;
    })
    .addCase(updateStatusSuccess, (state) => {
      state.orderDetail.isUpdating = false;
      state.dialogId = null;
      if (state.orderDetail.order) {
        state.ordersList.orders = state.ordersList.orders.map((order) => {
          if (order.id === state.orderDetail.order?.id) {
            return {
              ...order,
              status: 'CONFIRMED',
            };
          }
          return order;
        });
      }
    })
    .addCase(updateStatusFailure, (state, action) => {
      state.orderDetail.isUpdating = false;
      state.orderDetail.error = action.payload;
    })
    .addCase(setUpdateStatus, (state, { payload }) => {
      state.orderDetail.order = payload;
      state.dialogId = 'updateStatus';
    });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
  builder
    .addCase(getTiersRequest, (state) => {
      state.tiers.isLoading = true;
    })
    .addCase(getTiersSuccess, (state, { payload }) => {
      state.tiers.isLoading = false;
      state.tiers.entries = payload;
    })
    .addCase(getTiersFailure, (state) => {
      state.tiers.isLoading = false;
    });
  builder
    .addCase(printInvoiceRequest, (state, { payload }) => {
      state.printInvoice.isPrinting = 'pending';
      state.printInvoice.invoice = payload;
    })
    .addCase(printInvoiceReady, (state) => {
      state.printInvoice.isPrinting = 'ready';
    })
    .addCase(printInvoiceFailure, (state) => {
      state.printInvoice.isPrinting = 'error';
      state.printInvoice.invoice = null;
    })
    .addCase(printInvoiceSuccess, (state) => {
      state.printInvoice.isPrinting = 'wait';
      state.printInvoice.invoice = null;
    })
    .addCase(setDataToPrint, (state, { payload }) => {
      state.printInvoice.transactions = payload.transactions;
    });
});

export default reducer;
