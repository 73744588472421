import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  ExchangeRate,
  ExchangeRateForm,
  ExchangeRatesFilter,
} from 'models';

type DialogID = null | 'createExchangeRate';

export interface InitialState {
  exchangeRates: {
    data: ExchangeRate[];
    isLoading: boolean;
    error: string | null;
  };
  dialogID: DialogID;
  form: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
    isSuccess: boolean;
    rate: ExchangeRate | null;
  };
}
const initialState: InitialState = {
  exchangeRates: {
    data: [],
    isLoading: false,
    error: null,
  },
  dialogID: null,
  form: {
    isLoading: false,
    error: null,
    isCreating: false,
    isSuccess: false,
    rate: null,
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/exchange-rate@cancelRequestAPI',
  onChangeDialogID = 'super-admin/exchange-rate@onChangeDialogID',
  getExchangeRatesRequest = 'super-admin/exchange-rate@getExchangeRatesRequest',
  getExchangeRatesSuccess = 'super-admin/exchange-rate@getExchangeRatesSuccess',
  getExchangeRatesFailure = 'super-admin/exchange-rate@getExchangeRatesFailure',
  createExchangeRateRequest = 'super-admin/exchange-rate@createExchangeRateRequest',
  createExchangeRateSuccess = 'super-admin/exchange-rate@createExchangeRateSuccess',
  createExchangeRateFailure = 'super-admin/exchange-rate@createExchangeRateFailure',
  afterCreateSuccess = 'super-admin/exchange-rate@afterCreateSuccess',
  setRate = 'super-admin/exchange-rate@setRate',
}

export type GetExchangeRatesSuccess = DefaultQueries & {
  data: ExchangeRate[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getExchangeRatesRequest = createAction<
  ExchangeRatesFilter,
  Types.getExchangeRatesRequest
>(Types.getExchangeRatesRequest);
export const getExchangeRatesSuccess = createAction<
  GetExchangeRatesSuccess,
  Types.getExchangeRatesSuccess
>(Types.getExchangeRatesSuccess);
export const getExchangeRatesFailure = createAction<string>(
  Types.getExchangeRatesFailure
);
export const createExchangeRateRequest = createAction<ExchangeRateForm>(
  Types.createExchangeRateRequest
);
export const createExchangeRateSuccess = createAction<ExchangeRateForm>(
  Types.createExchangeRateSuccess
);
export const createExchangeRateFailure = createAction<string>(
  Types.createExchangeRateFailure
);
export const afterCreateSuccess = createAction(Types.afterCreateSuccess);
export const setRate = createAction<ExchangeRate>(Types.setRate);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.exchangeRates.isLoading = false;
    state.exchangeRates.error = null;
    state.form.isLoading = false;
    state.form.error = null;
    state.form.isCreating = false;
    state.dialogID = null;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.dialogID = action.payload;
  });
  builder.addCase(getExchangeRatesRequest, (state) => {
    state.exchangeRates.isLoading = true;
    state.exchangeRates.error = null;
  });
  builder.addCase(getExchangeRatesSuccess, (state, action) => {
    return {
      ...state,
      exchangeRates: {
        ...state.exchangeRates,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getExchangeRatesFailure, (state, action) => {
    state.exchangeRates.isLoading = false;
    state.exchangeRates.error = action.payload;
  });
  builder.addCase(createExchangeRateRequest, (state) => {
    state.form.error = null;
    state.form.isCreating = true;
  });
  builder.addCase(createExchangeRateSuccess, (state, { payload }) => {
    state.form.isCreating = false;
    state.form.isSuccess = true;
    state.dialogID = null;
    state.exchangeRates.data = state.exchangeRates.data.map((item) => {
      if (item.from === payload.from) {
        return {
          ...item,
          buy: payload.buy,
          sell: payload.sell,
        };
      }
      return item;
    });
  });
  builder.addCase(createExchangeRateFailure, (state, action) => {
    state.form.isCreating = false;
    state.form.error = action.payload;
  });
  builder.addCase(afterCreateSuccess, (state) => {
    state.form.isSuccess = false;
  });
  builder.addCase(setRate, (state, action) => {
    state.form.rate = action.payload;
  });
});

export default reducer;
