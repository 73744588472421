import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  findErrorToData,
  getAllDataWithCursorNextPage,
  setPageInfo,
} from 'utils';
import { Tier, Season, Zone } from 'models';
import { SummaryStock, SummaryLots } from 'models/superAdminReport';
import { exportStocksTable } from 'utils/report';
let cancels: Canceler[] = [];

function* getSummaryStocksRequest({
  payload,
}: ReturnType<typeof actions.getSummaryStocksRequest>) {
  try {
    const entries: SummaryStock[] = yield getAllDataWithCursorNextPage<
      SummaryStock[],
      'stocks'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/inventory/report/summary-stocks${queries}`, {
          params: { ...payload },
        }),
      keyResponse: 'stocks',
    });
    const {
      data: { summaries, ...entities },
    } = exportStocksTable(entries);
    yield put(
      actions.getSummaryStocksSuccess({
        summary: summaries,
        entities,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSummaryStocksFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getGetSummaryLots({
  payload,
}: ReturnType<typeof actions.getSummaryLotsRequest>) {
  try {
    const entries: SummaryLots[] = yield getAllDataWithCursorNextPage<
      SummaryStock[],
      'summaryLots'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/inventory/report/summary-lots${queries}`, {
          params: { ...payload },
        }),
      keyResponse: 'summaryLots',
    });
    yield put(
      actions.getSummaryLotsSuccess(
        entries.sort((a, b) => {
          const nameA = a.tier.title.toUpperCase(); // ignore upper and lowercase
          const nameB = b.tier.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })
      )
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSummaryLotsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getTiersRequest() {
  try {
    const tiers: Tier[] = yield getAllDataWithCursorNextPage<Tier[], 'tiers'>({
      httpRequest: (queries) => API.get(`/v1/admin/tier/tiers${queries}`),
      keyResponse: 'tiers',
    });
    yield put(actions.getTiersSuccess(tiers));
  } catch (error) {
    yield put(actions.getTiersFailure());
  }
}
function* getSeasonsRequest() {
  try {
    const tiers: Season[] = yield getAllDataWithCursorNextPage<
      Season[],
      'seasons'
    >({
      httpRequest: (queries) => API.get(`/v1/admin/tier/seasons${queries}`),
      keyResponse: 'seasons',
    });
    yield put(actions.getSeasonsSuccess(tiers));
  } catch (error) {
    yield put(actions.getSeasonsFailure());
  }
}
function* getZones() {
  try {
    const zones: Zone[] = yield getAllDataWithCursorNextPage<Zone[], 'zones'>({
      httpRequest: (queries) => API.get(`/v1/admin/tier/zones${queries}`),
      keyResponse: 'zones',
    });
    yield put(actions.getZonesSuccess(zones));
  } catch (error) {
    yield put(actions.getZonesFailure());
  }
}
function* getEntitiesLotSummary({
  payload,
}: ReturnType<typeof actions.getEntitiesLotSummaryRequest>) {
  try {
    const { summary: summaryPayload } = payload;
    const getSummary = JSON.parse(summaryPayload || '{}') as SummaryLots;
    if (typeof getSummary !== 'object') {
      yield put(actions.getEntitiesLotSummaryFailure(''));
      yield put(
        addAlert({
          message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
          serviceType: 'snackbar',
        })
      );
      return;
    }
    yield put(actions.getEntitiesLotSummarySuccess(getSummary));
    yield put(actions.getEntitiesLotsRequest(payload));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getEntitiesLotSummaryFailure(''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getEntitiesLots({
  payload,
}: ReturnType<typeof actions.getEntitiesLotSummaryRequest>) {
  try {
    const { summary: summaryPayload, page, pageSize, ...props } = payload;
    const getSummary = JSON.parse(summaryPayload || '{}') as SummaryLots;
    if (typeof getSummary !== 'object') {
      yield put(actions.getEntitiesLotsFailure(''));
      yield put(
        addAlert({
          message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
          serviceType: 'snackbar',
        })
      );
      return;
    }
    const { lotsEntities }: actions.InitialState = yield select(
      (state) => state.superAdmin.report.inventories
    );
    const queries: any = {
      zone: getSummary.zone.name,
      tier: getSummary.tier.name,
      product: getSummary.product.name,
      pageSize,
      ...props,
    };

    const { data } = yield call(API.get, `v1/inventory/report/lots?`, {
      params: queries,
    });
    const pageInfo = setPageInfo(
      {
        page: lotsEntities.page,
        pageSize: lotsEntities.pageSize,
        pageTokens: lotsEntities.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    yield put(
      actions.getEntitiesLotsSuccess({
        entities: data.Lots,
        ...pageInfo,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getEntitiesLotsFailure(''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangePageEntitiesLots({
  payload,
}: ReturnType<typeof actions.onChangePageEntitiesLots>) {
  const {
    lotsEntities: { pageTokens, page },
  }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.inventories
  );
  const { nextOrPrev, ...payloadParams } = payload;
  let params: any = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getEntitiesLotsRequest(params));
}
function* onChangePageSizeEntitiesLots({
  payload,
}: ReturnType<typeof actions.onChangePageSizeEntitiesLots>) {
  const {
    lotsEntities: { pageSize },
  }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.inventories
  );
  if (payload.pageSize !== pageSize) {
    yield put(actions.getEntitiesLotsRequest({ ...payload }));
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetSummaryStocksRequest() {
  yield takeLatest(
    actions.Types.getSummaryStocksRequest,
    getSummaryStocksRequest
  );
}
function* watchGetSummaryLotsRequest() {
  yield takeLatest(actions.Types.getSummaryLotsRequest, getGetSummaryLots);
}

function* watchGetTiersRequest() {
  yield takeLatest(actions.Types.getTiersRequest, getTiersRequest);
}
function* watchGetSeasonsRequest() {
  yield takeLatest(actions.Types.getSeasonsRequest, getSeasonsRequest);
}
function* watchGetZonesRequest() {
  yield takeLatest(actions.Types.getZonesRequest, getZones);
}
function* watchGetEntitiesLotSummary() {
  yield takeLatest(
    actions.Types.getEntitiesLotSummaryRequest,
    getEntitiesLotSummary
  );
}
function* watchGetEntitiesLot() {
  yield takeLatest(actions.Types.getEntitiesLotsRequest, getEntitiesLots);
}
function* watchOnChangePageEntitiesLots() {
  yield takeLatest(
    actions.Types.onChangePageEntitiesLots,
    onChangePageEntitiesLots
  );
}
function* watchOnChangePageSizeEntitiesLots() {
  yield takeLatest(
    actions.Types.onChangePageSizeEntitiesLots,
    onChangePageSizeEntitiesLots
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetSummaryStocksRequest),
    fork(watchGetTiersRequest),
    fork(watchGetSeasonsRequest),
    fork(watchGetSummaryLotsRequest),
    fork(watchGetZonesRequest),
    fork(watchGetEntitiesLotSummary),
    fork(watchGetEntitiesLot),
    fork(watchOnChangePageEntitiesLots),
    fork(watchOnChangePageSizeEntitiesLots),
  ]);
}
export default saga;
