import { ElementType, FC, memo, ReactNode, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Drawer as MuiDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItem,
  Button,
  Divider,
  AppBar,
  AppBarProps,
  ListSubheader,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import themeSetting from 'config/theme/themeConfig';
import {
  NavigationItem,
  NavigationKey,
  icons,
  IconName,
} from 'config/navigations';
import BoxScrollbar from 'components/page-layout/BoxScrollbar';
import { useAppSelector } from 'store/hooks';
const Img = styled('img')({
  height: '156px',
  width: '155px',
});
const DrawerHeader = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '180px',
  paddingTop: '10px',
  paddingBottom: '10px',
}));
const NavList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'item',
})<{
  component?: ElementType;
  item?: boolean;
}>(({ item }) => ({
  '& .MuiListItemButton-root': {
    paddingLeft: 10,
    paddingRight: 10,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 20,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  background: 'background.paper',
  ...(item && {
    '& .MuiListItemButton-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiListItemText-root ': {
      paddingLeft: 28,
    },
  }),
}));
const ItemMenu = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{
  component?: ElementType;
  active?: boolean;
}>(({ active }) => ({
  color: 'rgba(0, 0, 0, 0.87)',
  ...(active && {
    backgroundColor: 'rgba(25, 118, 210, 0.08)',
    '& .MuiListItemText-root ': {
      color: '#1976D2',
    },
    '& .MuiSvgIcon-root': {
      color: '#1976D2',
    },
  }),
}));
interface Props {
  openSidebar: boolean;
  onChangeSidebar: () => void;
  breakpoint: boolean;
}
const Sidebar: FC<Props> = ({ openSidebar, onChangeSidebar, breakpoint }) => {
  const {
    accept: { navigations, tier },
  } = useAppSelector((state) => state.auth);

  return (
    <MuiDrawer
      color={themeSetting.sidebar.color}
      sx={{
        width: themeSetting.sidebar.drawerWidth,
        minWidth: themeSetting.sidebar.drawerWidth,
        maxWidth: themeSetting.sidebar.drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: themeSetting.sidebar.drawerWidth,
          boxSizing: 'border-box',
        },
        '& ::-webkit-scrollbar': {
          width: '0.1em',
          height: '0.1em',
          backgroundColor: '#1f7acf',
        },
        '& ::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 0 20px rgba(0, 0, 0, 0.24)`,
          backgroundColor: '#06427a',
          outline: '1px solid slategrey',
          borderRadius: 8,
        },
        '& ::-webkit-scrollbar-thumb:active': {
          boxShadow: `inset 0 0 0 20px rgba(0, 0, 0, 0.37)`,
        },
        '& ::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          backgroundColor: '#F5F5F5',
        },
      }}
      PaperProps={{ elevation: 3 }}
      variant={breakpoint ? 'temporary' : 'persistent'}
      anchor="left"
      open={openSidebar}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onClose={onChangeSidebar}
    >
      <DrawerHeader
        position="sticky"
        color={themeSetting.sidebar.header.color}
        elevation={themeSetting.sidebar.header.elevation}
      >
        <Img src="/company.png" alt="logo192" />
      </DrawerHeader>
      <Divider sx={{ mx: 3 }} />
      <BoxScrollbar>
        {Object.keys(navigations).length > 0 &&
          Object.keys(navigations).map((key) => (
            <NavList
              key={key}
              component="nav"
              disablePadding
              aria-labelledby="list-menu"
              subheader={
                <ListSubheader
                  sx={{ fontFamily: 'Defago Noto Sans' }}
                  component="div"
                  id="nested-list-subheader"
                >
                  {navigations[key as NavigationKey]?.title}
                </ListSubheader>
              }
            >
              {navigations[key as NavigationKey]?.items.map((item) => (
                <Content
                  nav={item}
                  key={item.id}
                  tierName={key === 'tier' ? tier?.name : undefined}
                />
              ))}
            </NavList>
          ))}
      </BoxScrollbar>
    </MuiDrawer>
  );
};

interface Nav {
  nav: NavigationItem;
  tierName?: string;
}
const Content: FC<Nav> = ({ nav, tierName }) => {
  let resolved = useResolvedPath(nav.to || '');
  const isMatch = useMatch({
    path: resolved.pathname,
  });
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const Icon = icons[nav.icon as IconName];
  return (
    <>
      <ItemMenu
        disablePadding
        active={!!isMatch && isMatch && isMatch.pathname !== '/'}
      >
        <ListItemLink
          onClick={handleClick}
          link={
            nav.to ? nav.to.replace(':tierName', tierName || '') : undefined
          }
        >
          <ListItemIcon>{nav.icon && <Icon />}</ListItemIcon>
          <ListItemText primary={nav.name} />
          {nav.children && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemLink>
      </ItemMenu>
      {nav.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <NavList
            component="nav"
            disablePadding
            aria-labelledby="list-sub-menu"
            item
          >
            {nav.children.map((children) => (
              <Content nav={children} key={children.id} tierName={tierName} />
            ))}
          </NavList>
        </Collapse>
      )}
    </>
  );
};
interface PropsListItemLink {
  children: ReactNode;
  link?: string;
  onClick?: () => void;
}
const ListItemLink: FC<PropsListItemLink> = ({ children, onClick, link }) => {
  if (link) {
    return (
      <ListItemButton component={Link} to={link} sx={{ py: 1, minHeight: 32 }}>
        {children}
      </ListItemButton>
    );
  }
  return (
    <ListItemButton
      component={Button}
      onClick={onClick}
      sx={{ py: 1, minHeight: 32 }}
    >
      {children}
    </ListItemButton>
  );
};
export default memo(Sidebar);
