import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  Purchase,
  SummaryPurchase,
  SummariesPurchasesQueries,
} from 'models';

export interface InitialState {
  summariesPurchases: {
    summaries: SummaryPurchase[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  summaryPurchase: {
    summary: SummaryPurchase | null;
    purchases: Purchase[];
    isLoading: boolean;
    error: string | null;
  };
  export: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  summariesPurchases: {
    error: null,
    isLoading: false,
    summaries: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  summaryPurchase: {
    isLoading: false,
    error: null,
    purchases: [],
    summary: null,
  },
  export: {
    isLoading: false,
  },
};
type Queries = DefaultQueries & SummariesPurchasesQueries;
export enum Types {
  cancelRequestAPI = 'tier/purchases-summaries@cancelRequestAPI',
  getSummariesPurchasesRequest = 'tier/purchases-summaries@getSummariesPurchasesRequest',
  getSummariesPurchasesSuccess = 'tier/purchases-summaries@getSummariesPurchasesSuccess',
  getSummariesPurchasesFailure = 'tier/purchases-summaries@getSummariesPurchasesFailure',
  summariesPurchasesOnChangePage = 'tier/purchases-summaries@summariesPurchasesOnChangePage',
  summariesPurchasesOnChangePageSize = 'tier/purchases-summaries@summariesPurchasesOnChangePageSize',
  getSummaryPurchaseSuccess = 'tier/purchases-summaries@getSummaryPurchaseSuccess',
  getPurchasesSuccess = 'tier/purchases-summaries@getPurchasesSuccess',
  viewSummaryPurchaseRequest = 'tier/purchases-summaries@viewSummaryPurchaseRequest',
  viewSummaryPurchaseSuccess = 'tier/purchases-summaries@viewSummaryPurchaseSuccess',
  viewSummaryPurchaseFailure = 'tier/purchases-summaries@viewSummaryPurchaseFailure',
  exportCsvRequest = 'tier/purchases-summaries@exportCsvRequest',
  exportCsvSuccess = 'tier/purchases-summaries@exportCsvSuccess',
  exportCsvFailure = 'tier/purchases-summaries@exportCsvFailure',
}

export type GetSummariesPurchasesSuccess = Queries & {
  summaries: SummaryPurchase[];
};

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getSummariesPurchasesRequest = createAction<Queries>(
  Types.getSummariesPurchasesRequest
);
export const getSummariesPurchasesSuccess = createAction<Queries>(
  Types.getSummariesPurchasesSuccess
);
export const getSummariesPurchasesFailure = createAction<string | null>(
  Types.getSummariesPurchasesFailure
);
export const summariesPurchasesOnChangePage = createAction<Queries>(
  Types.summariesPurchasesOnChangePage
);
export const summariesPurchasesOnChangePageSize = createAction<Queries>(
  Types.summariesPurchasesOnChangePageSize
);

export const getSummaryPurchaseSuccess = createAction<
  SummaryPurchase,
  Types.getSummaryPurchaseSuccess
>(Types.getSummaryPurchaseSuccess);

export const getPurchasesSuccess = createAction<
  Purchase[],
  Types.getPurchasesSuccess
>(Types.getPurchasesSuccess);
export const viewSummaryPurchaseRequest = createAction<string>(
  Types.viewSummaryPurchaseRequest
);
export const viewSummaryPurchaseSuccess = createAction(
  Types.viewSummaryPurchaseSuccess
);
export const viewSummaryPurchaseFailure = createAction<string | null>(
  Types.viewSummaryPurchaseFailure
);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.summaryPurchase.isLoading = false;
    state.summaryPurchase.isLoading = false;
  });
  builder.addCase(getSummariesPurchasesRequest, (state) => {
    state.summariesPurchases.isLoading = true;
  });
  builder.addCase(getSummariesPurchasesSuccess, (state, action) => {
    return {
      ...state,
      summariesPurchases: {
        ...state.summariesPurchases,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getSummariesPurchasesFailure, (state, action) => {
    state.summariesPurchases.isLoading = false;
    state.summariesPurchases.error = action.payload;
  });
  builder.addCase(viewSummaryPurchaseRequest, (state) => {
    state.summaryPurchase.isLoading = true;
    state.summaryPurchase.error = null;
  });
  builder.addCase(viewSummaryPurchaseSuccess, (state, action) => {
    state.summaryPurchase.isLoading = false;
  });
  builder.addCase(viewSummaryPurchaseFailure, (state, action) => {
    state.summaryPurchase.isLoading = false;
    state.summaryPurchase.error = action.payload;
  });
  builder.addCase(getSummaryPurchaseSuccess, (state, action) => {
    state.summaryPurchase.summary = action.payload;
  });
  builder
    .addCase(getPurchasesSuccess, (state, action) => {
      state.summaryPurchase.purchases = action.payload;
    })
    .addCase(exportCsvRequest, (state) => {
      state.export.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.export.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.export.isLoading = false;
    });
});

export default reducer;
