import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  Expense,
  ExpensesFilter,
  CreateExpenseForm,
  ExpenseType,
} from 'models';

type DialogId = 'addExpense' | 'updateExpense' | null;
export interface InitialState {
  dialogId: DialogId;
  expensesList: {
    expenses: Expense[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageTokens: string[];
    pageSize: number;
  };
  createExpense: {
    isCreating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
    expenseTypes: ExpenseType[];
  };
  updateExpense: {
    isUpdating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
    expense: Expense | null;
  };
  expenseTypeList: {
    isLoading: boolean;
    error: string | null;
    expenseType: ExpenseType[];
  };
}
const initialState: InitialState = {
  dialogId: null,
  createExpense: {
    error: null,
    isCreating: false,
    isAfterSubmitted: false,
    expenseTypes: [],
  },
  expensesList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    expenses: [],
  },
  expenseTypeList: {
    error: null,
    expenseType: [],
    isLoading: false,
  },
  updateExpense: {
    error: null,
    isAfterSubmitted: false,
    isUpdating: false,
    expense: null,
  },
};
export enum Types {
  onChangeDialogId = 'tier/expenses@onChangeDialogId',
  cancelRequestAPI = 'tier/expenses@cancelRequestAPI',
  getExpensesRequest = 'tier/expenses@getExpensesRequest',
  getExpensesSuccess = 'tier/expenses@getExpensesSuccess',
  getExpensesFailure = 'tier/expenses@getExpensesFailure',
  onChangeExpensesPage = 'tier/expenses@onChangeExpensesPage',
  onChangeExpensesPageSize = 'tier/expenses@onChangeExpensesPageSize',
  createExpensesRequest = 'tier/expenses@createExpensesRequest',
  createExpensesSuccess = 'tier/expenses@createExpensesSuccess',
  createExpensesFailure = 'tier/expenses@createExpensesFailure',
  afterCreateExpensesSuccess = 'tier/expenses@afterCreateExpensesSuccess',
  updateExpensesRequest = 'tier/expenses@updateExpensesRequest',
  updateExpensesSuccess = 'tier/expenses@updateExpensesSuccess',
  updateExpensesFailure = 'tier/expenses@updateExpensesFailure',
  afterUpdateExpensesSuccess = 'tier/expenses@afterUpdateExpensesSuccess',
  getExpenseTypeRequest = 'tier/expenses@getExpenseTypeRequest',
  getExpenseTypeSuccess = 'tier/expenses@getExpenseTypeSuccess',
  getExpenseTypeFailure = 'tier/expenses@getExpenseTypeFailure',
  setExpense = 'tier/expenses@setExpense',
}
export type GetExpensesSuccess = DefaultQueries & {
  expenses: Expense[];
};
type ExpensesQueries = DefaultQueries & ExpensesFilter;

export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);
export const getExpensesRequest = createAction<ExpensesQueries>(
  Types.getExpensesRequest
);
export const getExpensesSuccess = createAction<GetExpensesSuccess>(
  Types.getExpensesSuccess
);
export const getExpensesFailure = createAction<string>(
  Types.getExpensesFailure
);
export const onChangeExpensesPage = createAction<ExpensesQueries>(
  Types.onChangeExpensesPage
);
export const onChangeExpensesPageSize = createAction<ExpensesQueries>(
  Types.onChangeExpensesPageSize
);
export const createExpensesRequest = createAction<CreateExpenseForm>(
  Types.createExpensesRequest
);
export const createExpensesSuccess = createAction(Types.createExpensesSuccess);
export const createExpensesFailure = createAction<string>(
  Types.createExpensesFailure
);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const afterCreateExpensesSuccess = createAction(
  Types.afterCreateExpensesSuccess
);
export const getExpenseTypeRequest = createAction(Types.getExpenseTypeRequest);
export const getExpenseTypeSuccess = createAction<ExpenseType[]>(
  Types.getExpenseTypeSuccess
);
export const getExpenseTypeFailure = createAction<string>(
  Types.getExpenseTypeFailure
);

export const updateExpensesRequest = createAction<CreateExpenseForm>(
  Types.updateExpensesRequest
);
export const updateExpensesSuccess = createAction(Types.updateExpensesSuccess);
export const updateExpensesFailure = createAction<string>(
  Types.updateExpensesFailure
);
export const afterUpdateExpensesSuccess = createAction(
  Types.afterUpdateExpensesSuccess
);
export const setExpense = createAction<Expense>(Types.setExpense);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(onChangeDialogId, (state, action) => {
      state.dialogId = action.payload;
      state.updateExpense.expense = null;
    })
    .addCase(getExpensesRequest, (state) => {
      state.expensesList.isLoading = true;
      state.expensesList.error = null;
    })
    .addCase(getExpensesSuccess, (state, action) => {
      return {
        ...state,
        expensesList: {
          ...state.expensesList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getExpensesFailure, (state, action) => {
      state.expensesList.isLoading = false;
      state.expensesList.error = action.payload;
    })
    .addCase(createExpensesRequest, (state) => {
      state.createExpense.error = null;
      state.createExpense.isCreating = true;
    })
    .addCase(createExpensesSuccess, (state) => {
      state.createExpense.isCreating = false;
      state.createExpense.isAfterSubmitted = true;
      state.dialogId = null;
    })
    .addCase(createExpensesFailure, (state, action) => {
      state.createExpense.error = action.payload;
      state.createExpense.isCreating = false;
    })
    .addCase(cancelRequestAPI, (state) => {
      state.expensesList.isLoading = false;
      state.createExpense.isCreating = false;
    })
    .addCase(afterCreateExpensesSuccess, (state) => {
      state.createExpense.isAfterSubmitted = false;
    })
    .addCase(getExpenseTypeRequest, (state) => {
      state.expenseTypeList.error = null;
      state.expenseTypeList.isLoading = true;
    })
    .addCase(getExpenseTypeSuccess, (state, { payload }) => {
      state.expenseTypeList.expenseType = payload;
      state.expenseTypeList.isLoading = false;
    })
    .addCase(getExpenseTypeFailure, (state, { payload }) => {
      state.expensesList.error = payload;
      state.expenseTypeList.isLoading = false;
    })
    .addCase(updateExpensesRequest, (state) => {
      state.updateExpense.error = null;
      state.updateExpense.isUpdating = true;
    })
    .addCase(updateExpensesSuccess, (state) => {
      state.updateExpense.isUpdating = false;
      state.updateExpense.isAfterSubmitted = true;
      state.dialogId = null;
      state.updateExpense.expense = null;
    })
    .addCase(updateExpensesFailure, (state, action) => {
      state.updateExpense.error = action.payload;
      state.updateExpense.isUpdating = false;
    })
    .addCase(afterUpdateExpensesSuccess, (state) => {
      state.updateExpense.isAfterSubmitted = false;
    })
    .addCase(setExpense, (state, { payload }) => {
      state.updateExpense.expense = payload;
    });
});

export default reducer;
