import { createReducer, createAction } from '@reduxjs/toolkit';
import { Activity, ActivitiesFilter, DefaultQueries } from 'models';

export interface InitialState {
  activitiesList: {
    activities: Activity[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageTokens: string[];
    pageSize: number;
  };
}
const initialState: InitialState = {
  activitiesList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    activities: [],
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/activities@cancelRequestAPI',
  getActivitiesRequest = 'super-admin/activities@getActivitiesRequest',
  getActivitiesSuccess = 'super-admin/activities@getActivitiesSuccess',
  getActivitiesFailure = 'super-admin/activities@getActivitiesFailure',
  onChangeActivitiesPage = 'super-admin/activities@onChangeActivitiesPage',
  onChangeActivitiesPageSize = 'super-admin/activities@onChangeActivitiesPageSize',
}
export type GetActivitiesSuccess = DefaultQueries & {
  activities: Activity[];
};

export const getActivitiesRequest = createAction<ActivitiesFilter>(
  Types.getActivitiesRequest
);
export const getActivitiesSuccess = createAction<GetActivitiesSuccess>(
  Types.getActivitiesSuccess
);
export const getActivitiesFailure = createAction<string>(
  Types.getActivitiesFailure
);
export const onChangeActivitiesPage = createAction<ActivitiesFilter>(
  Types.onChangeActivitiesPage
);
export const onChangeActivitiesPageSize = createAction<ActivitiesFilter>(
  Types.onChangeActivitiesPageSize
);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getActivitiesRequest, (state) => {
      state.activitiesList.isLoading = true;
      state.activitiesList.error = null;
    })
    .addCase(getActivitiesSuccess, (state, action) => {
      return {
        ...state,
        activitiesList: {
          ...state.activitiesList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getActivitiesFailure, (state, action) => {
      state.activitiesList.isLoading = false;
      state.activitiesList.error = action.payload;
    })
    .addCase(cancelRequestAPI, (state) => {
      state.activitiesList.isLoading = false;
    });
});

export default reducer;
