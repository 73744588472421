import { createReducer, createAction } from '@reduxjs/toolkit';
import { Predict, PredictFormValues } from 'models';

type DialogID = 'create' | null;
export interface InitialState {
  predict: {
    entities: Predict[];
    isLoading: boolean;
  };
  form: {
    isCreating: boolean;
    isLoading: boolean;
    dialogID: DialogID;
    error: string | null;
    isAfterSubmitSuccess: boolean;
  };
}
const initialState: InitialState = {
  predict: {
    entities: [],
    isLoading: false,
  },
  form: {
    isCreating: false,
    isLoading: false,
    error: null,
    dialogID: null,
    isAfterSubmitSuccess: false,
  },
};
export enum Types {
  onChangeDialogID = 'super-admin/predict@onChangeDialogID',
  getPredictRequest = 'super-admin/predict@getPredictRequest',
  getPredictSuccess = 'super-admin/predict@getPredictSuccess',
  getPredictFailure = 'super-admin/predict@getPredictFailure',
  createPredictRequest = 'super-admin/predict@createPredictRequest',
  createPredictSuccess = 'super-admin/predict@createPredictSuccess',
  createPredictFailure = 'super-admin/predict@createPredictFailure',
  afterSubmitSuccess = 'super-admin/predict@afterSubmitSuccess',
}
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getPredictRequest = createAction(Types.getPredictRequest);
export const getPredictSuccess = createAction<Predict[]>(
  Types.getPredictSuccess
);
export const getPredictFailure = createAction<string>(Types.getPredictFailure);
export const createPredictRequest = createAction<PredictFormValues>(
  Types.createPredictRequest
);
export const createPredictSuccess = createAction<Predict[]>(
  Types.createPredictSuccess
);
export const createPredictFailure = createAction<string | null>(
  Types.createPredictFailure
);
export const afterSubmitSuccess = createAction(Types.afterSubmitSuccess);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(onChangeDialogID, (state, action) => {
    state.form.dialogID = action.payload;
  });
  builder
    .addCase(getPredictRequest, (state, _) => {
      state.predict.isLoading = true;
    })
    .addCase(getPredictSuccess, (state, { payload }) => {
      state.predict.isLoading = false;
      state.predict.entities = payload;
    })
    .addCase(getPredictFailure, (state, action) => {
      state.predict.isLoading = false;
    });
  builder
    .addCase(createPredictRequest, (state) => {
      state.form.isCreating = true;
      state.form.error = null;
    })
    .addCase(createPredictSuccess, (state, { payload }) => {
      state.form.isCreating = false;
      state.form.dialogID = null;
      state.form.isAfterSubmitSuccess = true;
      state.predict.entities = payload;
    })
    .addCase(createPredictFailure, (state, action) => {
      return {
        ...state,
        form: { ...state.form, isCreating: false, error: action.payload },
      };
    });
  builder.addCase(afterSubmitSuccess, (state) => {
    state.form.isAfterSubmitSuccess = !state.form.isAfterSubmitSuccess;
  });
});

export default reducer;
