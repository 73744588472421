import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  SummaryExpensesFilters,
  SummaryExpenses,
  ExpensesFilters,
  Expenses,
} from 'models/superAdminReport';
import type { DefaultQueries, Tier } from 'models';

type SummaryExpensesQueries = SummaryExpensesFilters & {};
type ExpensesQueries = DefaultQueries & ExpensesFilters & {};
export interface InitialState {
  summaryExpenses: {
    entities: SummaryExpenses[];
    isLoading: boolean;
    error: string | null;
  };
  expenses: {
    entities: Expenses[];
    summary: SummaryExpenses;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  tiers: {
    entities: Tier[];
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  summaryExpenses: {
    entities: [],
    isLoading: false,
    error: null,
  },
  expenses: {
    entities: [],
    summary: {
      localAmount: {
        amount: '',
        currency: '',
      },
      type: {
        name: '',
        title: '',
      },
    },
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    isExporting: false,
  },
  tiers: {
    entities: [],
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/report-expenses@cancelRequestAPI',
  getSummaryExpensesRequest = 'super-admin/report-expenses@getSummaryExpensesRequest',
  getSummaryExpensesSuccess = 'super-admin/report-expenses@getSummaryExpensesSuccess',
  getSummaryExpensesFailure = 'super-admin/report-expenses@getSummaryExpensesFailure',
  getExpensesRequest = 'super-admin/report-expenses@getExpensesRequest',
  getExpensesSuccess = 'super-admin/report-expenses@getExpensesSuccess',
  getExpensesFailure = 'super-admin/report-expenses@getExpensesFailure',
  onChangeExpensesPage = 'super-admin/report-expenses@onChangeExpensesPage',
  onChangeExpensesPageSize = 'super-admin/report-expenses@onChangeExpensesPageSize',
  exportExpensesCsvRequest = 'super-admin/report-expenses@exportExpensesCsvRequest',
  exportExpensesCsvSuccess = 'super-admin/report-expenses@exportExpensesCsvSuccess',
  exportExpensesCsvFailure = 'super-admin/report-expenses@exportExpensesCsvFailure',
  getTiersRequest = 'super-admin/report-expenses@getTiersRequest',
  getTiersSuccess = 'super-admin/report-expenses@getTiersSuccess',
  getTiersFailure = 'super-admin/report-expenses@getTiersFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getTiersRequest = createAction(Types.getTiersRequest);
export const getTiersSuccess = createAction<Tier[]>(Types.getTiersSuccess);
export const getTiersFailure = createAction(Types.getTiersFailure);
export const getSummaryExpensesRequest = createAction<SummaryExpensesQueries>(
  Types.getSummaryExpensesRequest
);
export const getSummaryExpensesSuccess = createAction<SummaryExpenses[]>(
  Types.getSummaryExpensesSuccess
);
export const getSummaryExpensesFailure = createAction<string>(
  Types.getSummaryExpensesFailure
);

export const getExpensesRequest = createAction<ExpensesQueries>(
  Types.getExpensesRequest
);
export const getExpensesSuccess = createAction<
  DefaultQueries & {
    entities: Expenses[];
    summary: SummaryExpenses;
  }
>(Types.getExpensesSuccess);
export const getExpensesFailure = createAction<string>(
  Types.getExpensesFailure
);
export const onChangeExpensesPage = createAction<ExpensesQueries>(
  Types.onChangeExpensesPage
);
export const onChangeExpensesPageSize = createAction<ExpensesQueries>(
  Types.onChangeExpensesPageSize
);
export const exportExpensesCsvRequest = createAction<ExpensesQueries>(
  Types.exportExpensesCsvRequest
);
export const exportExpensesCsvSuccess = createAction(
  Types.exportExpensesCsvSuccess
);
export const exportExpensesCsvFailure = createAction(
  Types.exportExpensesCsvFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSummaryExpensesRequest, (state) => {
      state.summaryExpenses.isLoading = true;
      state.summaryExpenses.error = null;
    })
    .addCase(getSummaryExpensesSuccess, (state, { payload }) => {
      return {
        ...state,
        summaryExpenses: {
          ...state.summaryExpenses,
          entities: payload,
          isLoading: false,
        },
      };
    })
    .addCase(getSummaryExpensesFailure, (state, action) => {
      state.summaryExpenses.isLoading = false;
      state.summaryExpenses.error = action.payload;
    });

  builder
    .addCase(getExpensesRequest, (state) => {
      state.expenses.isLoading = true;
      state.expenses.error = null;
    })
    .addCase(getExpensesSuccess, (state, { payload }) => {
      return {
        ...state,
        expenses: {
          ...state.expenses,
          isLoading: false,
          ...payload,
        },
      };
    })
    .addCase(getExpensesFailure, (state, action) => {
      state.expenses.isLoading = false;
      state.expenses.error = action.payload;
    });
  builder
    .addCase(exportExpensesCsvRequest, (state) => {
      state.expenses.isExporting = true;
    })
    .addCase(exportExpensesCsvSuccess, (state) => {
      state.expenses.isExporting = false;
    })
    .addCase(exportExpensesCsvFailure, (state) => {
      state.expenses.isExporting = false;
    });
  builder
    .addCase(getTiersRequest, (state) => {
      state.tiers.isLoading = true;
    })
    .addCase(getTiersSuccess, (state, { payload }) => {
      state.tiers = {
        entities: payload,
        isLoading: false,
      };
    })
    .addCase(getTiersFailure, (state) => {
      state.tiers.isLoading = false;
    });
});

export default reducer;
