import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  Role,
  RolesPageFilter,
  DefaultQueries,
  CreateRoleForm,
  Resource,
} from 'models';
import { ResourceKey, ResourceAccess, resourceAccess } from 'utils';
export interface InitialState {
  rolesList: {
    roles: Role[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: string[];
  };
  roleCreate: {
    isCreating: boolean;
    error: string | null;
  };
  roleGet: {
    role: Role | null;
    isLoading: boolean;
    error: string | null;
    resources: ResourceAccess;
    isUpdating: boolean;
  };
}
const initialState: InitialState = {
  rolesList: {
    roles: [],
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  roleCreate: {
    isCreating: false,
    error: null,
  },
  roleGet: {
    role: null,
    error: null,
    isLoading: false,
    resources: resourceAccess,
    isUpdating: false,
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/roles@cancelRequestAPI',
  getRolesRequest = 'super-admin/roles@getRolesRequest',
  getRolesSuccess = 'super-admin/roles@getRolesSuccess',
  getRolesFailure = 'super-admin/roles@getRolesFailure',
  onChangeRolesPage = 'super-admin/roles@onChangeRolesPage',
  onChangeRolesPageSize = 'super-admin/roles@onChangeRolesPageSize',
  createRoleRequest = 'super-admin/roles@createRoleRequest',
  createRoleSuccess = 'super-admin/roles@createRoleSuccess',
  createRoleFailure = 'super-admin/roles@createRoleFailure',
  getRoleRequest = 'super-admin/roles@getRoleRequest',
  getRoleSuccess = 'super-admin/roles@getRoleSuccess',
  getRoleFailure = 'super-admin/roles@getRoleFailure',
  getResourcesRequest = 'super-admin/roles@getResourcesRequest',
  getResourcesSuccess = 'super-admin/roles@getResourcesSuccess',
  getResourcesFailure = 'super-admin/roles@getResourcesFailure',
  getRoleResourcesRequest = 'super-admin/roles@getRoleResourcesRequest',
  getRoleResourcesSuccess = 'super-admin/roles@getRoleResourcesSuccess',
  getRoleResourcesFailure = 'super-admin/roles@getRoleResourcesFailure',
  updateRoleResourcesRequest = 'super-admin/roles@updateRoleResourcesRequest',
  updateRoleResourcesSuccess = 'super-admin/roles@updateRoleResourcesSuccess',
  updateRoleResourcesFailure = 'super-admin/roles@updateRoleResourcesFailure',
}
export type GetRolesSuccess = DefaultQueries & {
  roles: Role[];
};
export type GetRoleResourcesSuccess = {
  resources: ResourceAccess;
  role: Role;
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getRolesRequest = createAction<RolesPageFilter>(
  Types.getRolesRequest
);
export const getRolesSuccess = createAction<GetRolesSuccess>(
  Types.getRolesSuccess
);
export const getRolesFailure = createAction<string>(Types.getRolesFailure);
export const onChangeRolesPage = createAction<RolesPageFilter>(
  Types.onChangeRolesPage
);
export const onChangeRolesPageSize = createAction<RolesPageFilter>(
  Types.onChangeRolesPageSize
);
export const createRoleRequest = createAction<CreateRoleForm>(
  Types.createRoleRequest
);
export const createRoleSuccess = createAction(Types.createRoleSuccess);
export const createRoleFailure = createAction<string>(Types.createRoleFailure);
export const getRoleRequest = createAction<string>(Types.getRoleRequest);
export const getRoleSuccess = createAction<Role>(Types.getRoleSuccess);
export const getRoleFailure = createAction<string>(Types.getRoleFailure);
export const getResourcesRequest = createAction(Types.getResourcesRequest);
export const getResourcesSuccess = createAction<Resource<ResourceKey>[]>(
  Types.getResourcesSuccess
);
export const getResourcesFailure = createAction<string>(
  Types.getResourcesFailure
);
export const getRoleResourcesRequest = createAction<string>(
  Types.getRoleResourcesRequest
);
export const getRoleResourcesSuccess = createAction<GetRoleResourcesSuccess>(
  Types.getRoleResourcesSuccess
);
export const getRoleResourcesFailure = createAction<string>(
  Types.getRoleResourcesFailure
);
export const updateRoleResourcesRequest = createAction<CreateRoleForm>(
  Types.updateRoleResourcesRequest
);
export const updateRoleResourcesSuccess = createAction(
  Types.updateRoleResourcesSuccess
);
export const updateRoleResourcesFailure = createAction<string>(
  Types.updateRoleResourcesFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    return { ...state, isLoading: false, isCreating: false };
  });
  builder.addCase(getRolesRequest, (state) => {
    state.rolesList.isLoading = true;
    state.rolesList.error = null;
  });
  builder.addCase(getRolesSuccess, (state, action) => {
    return {
      ...state,
      rolesList: {
        ...state.rolesList,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getRolesFailure, (state, action) => {
    state.rolesList.isLoading = false;
    state.rolesList.error = action.payload;
  });
  builder.addCase(createRoleRequest, (state) => {
    state.roleCreate.isCreating = true;
    state.roleCreate.error = null;
  });
  builder.addCase(createRoleSuccess, (state) => {
    state.roleCreate.isCreating = false;
  });
  builder.addCase(createRoleFailure, (state, action) => {
    state.roleCreate.isCreating = false;
    state.roleCreate.error = action.payload;
  });
  builder.addCase(getRoleRequest, (state) => {
    state.roleCreate.isCreating = true;
    state.roleCreate.error = null;
  });
  builder.addCase(getRoleSuccess, (state, action) => {
    state.roleGet.isLoading = false;
    state.roleGet.role = action.payload;
  });
  builder.addCase(getRoleFailure, (state, action) => {
    state.roleGet.isLoading = false;
    state.roleGet.error = action.payload;
  });
  builder.addCase(getResourcesRequest, (state) => {
    state.roleGet.isLoading = true;
    state.roleGet.error = null;
  });
  builder.addCase(getResourcesFailure, (state, action) => {
    state.roleGet.isLoading = false;
    state.roleGet.error = action.payload;
  });
  builder.addCase(getRoleResourcesRequest, (state) => {
    state.roleGet.isLoading = true;
    state.roleGet.error = null;
  });
  builder.addCase(getRoleResourcesSuccess, (state, action) => {
    state.roleGet.isLoading = false;
    state.roleGet.resources = action.payload.resources;
    state.roleGet.role = action.payload.role;
  });
  builder.addCase(getRoleResourcesFailure, (state, action) => {
    state.roleGet.isLoading = false;
    state.roleGet.error = action.payload;
  });
  builder.addCase(updateRoleResourcesRequest, (state) => {
    state.roleGet.isUpdating = true;
    state.roleGet.error = null;
  });
  builder.addCase(updateRoleResourcesSuccess, (state) => {
    state.roleGet.isUpdating = false;
  });
  builder.addCase(updateRoleResourcesFailure, (state, action) => {
    state.roleGet.isUpdating = false;
    state.roleGet.error = action.payload;
  });
});

export default reducer;
