import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { GetWalletAccountsAdminResponse, CreateWalletAccount } from 'models';
import { findErrorToData } from 'utils';

import moment from 'moment';

let cancels: Canceler[] = [];

function* getWalletAccounts({
  payload,
}: ReturnType<typeof actions.getSeasonsRequest>) {
  const {
    accountsList: { pageTokens, pageSize },
  }: actions.InitialState = yield select((state) => state.superAdmin.wallets);
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.createdAt) {
    query += `&startAt=${encodeURIComponent(
      moment(payload.createdAt).format('YYYY-MM-DDTHH:mm:ssZ')
    )})}`;
  }
  let dataPayload: actions.GetAccountsSuccess = {
    accounts: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetWalletAccountsAdminResponse = yield call(
      API.get,
      `/v1/admin/wallet/accounts?${query}`
    );
    dataPayload.accounts = data.accounts;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getSeasonsSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSeasonsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeWalletAccountsPage({
  payload,
}: ReturnType<typeof actions.onChangeSeasonsPage>) {
  const {
    accountsList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.wallets);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getSeasonsRequest(cloneFilters));
}
function* createWalletAccount({
  payload,
}: ReturnType<typeof actions.createAccountRequest>) {
  const account: CreateWalletAccount = {
    currency: payload.currency,
    number: payload.number,
    title: payload.title,
    type: payload.type,
  };
  try {
    yield call(API.post, '/v1/admin/wallet/accounts', account);
    yield put(actions.createAccountSuccess());
    yield put(
      addAlert({
        message: 'ສ້າງກະເປົາເງິນສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.getSeasonsRequest({}));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createAccountFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetWalletAccounts() {
  yield takeLatest(actions.Types.getAccountsRequest, getWalletAccounts);
}
function* watchOnChangeWalletAccountsPage() {
  yield takeLatest(
    actions.Types.onChangeAccountsPage,
    onChangeWalletAccountsPage
  );
}
function* watchOnChangeWalletAccountsPageSize() {
  yield takeLatest(
    actions.Types.onChangeAccountsPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeSeasonsPageSize>) {
      yield put(actions.getSeasonsRequest(payload));
    }
  );
}
function* watchCreateSeason() {
  yield takeLatest(actions.Types.createAccountRequest, createWalletAccount);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetWalletAccounts),
    fork(watchOnChangeWalletAccountsPage),
    fork(watchOnChangeWalletAccountsPageSize),
    fork(watchCreateSeason),
  ]);
}
export default saga;
