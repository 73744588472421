import { createReducer, createAction } from '@reduxjs/toolkit';
import { Product } from 'models';
export interface InitialState {
  products: Product[];
  isLoading: boolean;
  error: string | null;
}
const initialState: InitialState = {
  products: [],
  isLoading: false,
  error: null,
};
export enum Types {
  getProductsRequest = 'getProductsRequest',
  getProductsSuccess = 'getProductsSuccess',
  getProductsFailure = 'getProductsFailure',
}
export const getProductsRequest = createAction(Types.getProductsRequest);
export const getProductsSuccess = createAction<Product[]>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProductsRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(getProductsSuccess, (state, action) => {
      state.products = action.payload;
      state.isLoading = false;
    })
    .addCase(getProductsFailure, (state, action) => {
      return { ...state, isLoading: false, error: action.payload };
    });
});

export default reducer;
