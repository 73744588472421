import { SnackbarProvider } from 'notistack';
import ThemeProvider from 'config/theme';
import Snackbar from 'helper/snackbar';
import Layout from 'components/layouts';
import Authorization from 'helper/authorization';

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider
        preventDuplicate
        maxSnack={8}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          containerRoot: 'alert',
        }}
        autoHideDuration={3000}
      >
        <Snackbar>
          <Authorization>
            <Layout />
          </Authorization>
        </Snackbar>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
