import { createReducer, createAction } from '@reduxjs/toolkit';
import { Customer, CustomersFilter, DefaultQueries } from 'models';
export interface InitialState {
  customersList: {
    customers: Customer[];
    isLoading: boolean;
    error: string | null;
    pageTokens: string[];
    pageSize: number;
    page: number;
  };
}
const initialState: InitialState = {
  customersList: {
    error: null,
    isLoading: false,
    customers: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/customers@cancelRequestAPI',
  getCustomersRequest = 'super-admin/customers@getCustomersRequest',
  getCustomersSuccess = 'super-admin/customers@getCustomersSuccess',
  getCustomersFailure = 'super-admin/customers@getCustomersFailure',
  onChangeCustomersPage = 'super-admin/customers@onChangeCustomersPage',
  onChangeCustomersPageSize = 'super-admin/customers@onChangeCustomersPageSize',
}
export type GetCustomersSuccess = DefaultQueries & {
  customers: Customer[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getCustomersRequest = createAction<CustomersFilter>(
  Types.getCustomersRequest
);
export const getCustomersSuccess = createAction<GetCustomersSuccess>(
  Types.getCustomersSuccess
);
export const getCustomersFailure = createAction<string>(
  Types.getCustomersFailure
);
export const onChangeCustomersPage = createAction<CustomersFilter>(
  Types.onChangeCustomersPage
);
export const onChangeCustomersPageSize = createAction<CustomersFilter>(
  Types.onChangeCustomersPageSize
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(getCustomersRequest, (state) => {
      state.customersList.isLoading = true;
      state.customersList.error = null;
    })
    .addCase(getCustomersSuccess, (state, { payload }) => {
      return {
        ...state,
        customersList: {
          ...state.customersList,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getCustomersFailure, (state, { payload }) => {
      state.customersList.isLoading = false;
      state.customersList.error = payload;
    });
});

export default reducer;
