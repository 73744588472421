import { Box, styled, CircularProgress, Typography } from '@mui/material';

const StyledPage = styled(Box, {
  shouldForwardProp: (props) => props !== 'minFullScreen',
})<{ minFullScreen?: boolean }>(({ minFullScreen, theme }) => ({
  height: `calc(100vh - 64px)`,
  minHeight: `calc(100vh - 64px)`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5rem',
  backgroundColor: '#E5E5E5',
  ...(!minFullScreen && {
    height: '100%',
  }),
  [theme.breakpoints.down('sm')]: {
    height: '100%',
    marginTop: '64px',
  },
}));
const StylePageContent = styled(Box, {
  shouldForwardProp: (props) => props !== 'minFullScreen',
})<{ minFullScreen?: boolean }>(({ minFullScreen, theme }) => ({
  overflow: 'auto',
  flex: 'none',
  ...(minFullScreen && {
    flex: '1 1 0%',
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
  }),
  [theme.breakpoints.down('sm')]: {
    flex: 'none',
  },
}));
const PageLayout = () => {
  return (
    <StyledPage
      sx={{
        bgcolor: 'white',
      }}
      minFullScreen
    >
      <StylePageContent minFullScreen>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5">ກຳລັງໂຫຼດ...</Typography>
          <CircularProgress sx={{ mt: 3 }} />
        </Box>
      </StylePageContent>
    </StyledPage>
  );
};

export default PageLayout;
