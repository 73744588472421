import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  SummaryStockFilters,
  SummaryLotsFilters,
  SummaryLots,
  StockSummariesColumn,
  Lot,
} from 'models/superAdminReport';
import type { Tier, Season, DefaultQueries, Zone } from 'models';

type SummaryStockQueries = DefaultQueries & SummaryStockFilters & {};
type SummaryLotsQueries = DefaultQueries & SummaryLotsFilters & {};
type Stock = {
  tier: {
    name: string;
    title: string;
  };
  zone: {
    name: string;
    title: string;
  };
  product: {
    name: string;
    title: string;
  };
  closeAt: string;
  province: string;
  actualCost: number;
  price: number;
  count: number;
  weight: number;
  paidCost: number;
};
export interface InitialState {
  summaryStocks: {
    entities: StockSummariesColumn;
    summary: Stock;
    isLoading: boolean;
    error: string | null;
  };
  summaryLots: {
    entities: SummaryLots[];
    isLoading: boolean;
    error: string | null;
  };
  tiers: {
    entities: Tier[];
    isLoading: boolean;
  };
  seasons: {
    entities: Season[];
    isLoading: boolean;
  };
  zones: {
    entities: Zone[];
    isLoading: boolean;
  };
  lotsEntities: {
    entities: Lot[];
    summary: SummaryLots;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    pageTokens: string[];
    page: number;
    pageSize: number;
  };
}
const initialState: InitialState = {
  summaryStocks: {
    entities: {},
    isLoading: false,
    error: null,
    summary: {
      actualCost: 0,
      closeAt: '',
      count: 0,
      paidCost: 0,
      price: 0,
      product: {
        name: '',
        title: '',
      },
      province: '',
      tier: {
        name: '',
        title: '',
      },
      weight: 0,
      zone: {
        name: '',
        title: '',
      },
    },
  },
  summaryLots: {
    entities: [],
    isLoading: false,
    error: null,
  },
  seasons: {
    entities: [],
    isLoading: false,
  },
  tiers: {
    entities: [],
    isLoading: false,
  },
  zones: {
    entities: [],
    isLoading: false,
  },
  lotsEntities: {
    entities: [],
    error: null,
    isLoading: false,
    pageTokens: [],
    isExporting: false,
    page: 1,
    pageSize: 25,
    summary: {
      countLot: 0,
      inActualCost: '0',
      inPaidCost: '0',
      intPrice: '0',
      inVolumeKG: '0',
      lossCost: '0',
      lossVolumeKG: '0',
      outActualCost: '0',
      outPaidCost: '0',
      outPrice: '0',
      outVolumeKG: '0',
      product: {
        name: '',
        title: '',
      },
      profit: '0',
      tier: {
        name: '',
        title: '',
      },
      transform: false,
      transformKG: '0',
      transformLossCost: '0',
      transformLossKG: '0',
      zone: {
        name: '',
        title: '',
      },
    },
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/report-inventories@cancelRequestAPI',
  getSummaryStocksRequest = 'super-admin/report-inventories@getSummaryStocksRequest',
  getSummaryStocksSuccess = 'super-admin/report-inventories@getSummaryStocksSuccess',
  getSummaryStocksFailure = 'super-admin/report-inventories@getSummaryStocksFailure',
  getSummaryLotsRequest = 'super-admin/report-inventories@getSummaryLotsRequest',
  getSummaryLotsSuccess = 'super-admin/report-inventories@getSummaryLotsSuccess',
  getSummaryLotsFailure = 'super-admin/report-inventories@getSummaryLotsFailure',
  getTiersRequest = 'super-admin/report-inventories@getTiersRequest',
  getTiersSuccess = 'super-admin/report-inventories@getTiersSuccess',
  getTiersFailure = 'super-admin/report-inventories@getTiersFailure',
  getSeasonsRequest = 'super-admin/report-inventories@getSeasonsRequest',
  getSeasonsSuccess = 'super-admin/report-inventories@getSeasonsSuccess',
  getSeasonsFailure = 'super-admin/report-inventories@getSeasonsFailure',
  getZonesRequest = 'super-admin/report-inventories@getZonesRequest',
  getZonesSuccess = 'super-admin/report-inventories@getZonesSuccess',
  getZonesFailure = 'super-admin/report-inventories@getZonesFailure',
  getEntitiesLotSummaryRequest = 'super-admin/report-inventories@getEntitiesLotSummaryRequest',
  getEntitiesLotSummarySuccess = 'super-admin/report-inventories@getEntitiesLotSummarySuccess',
  getEntitiesLotSummaryFailure = 'super-admin/report-inventories@getEntitiesLotSummaryFailure',
  getEntitiesLotsRequest = 'super-admin/report-inventories@getEntitiesLotsRequest',
  getEntitiesLotsSuccess = 'super-admin/report-inventories@getEntitiesLotsSuccess',
  getEntitiesLotsFailure = 'super-admin/report-inventories@getEntitiesLotsFailure',
  onChangePageEntitiesLots = 'super-admin/report-inventories@onChangePageEntitiesLots',
  onChangePageSizeEntitiesLots = 'super-admin/report-inventories@onChangePageSizeEntitiesLots',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getSummaryStocksRequest = createAction<SummaryStockQueries>(
  Types.getSummaryStocksRequest
);
export const getSummaryStocksSuccess = createAction<{
  entities: StockSummariesColumn;
  summary: Stock;
}>(Types.getSummaryStocksSuccess);
export const getSummaryStocksFailure = createAction<string>(
  Types.getSummaryStocksFailure
);

export const getSummaryLotsRequest = createAction<SummaryLotsQueries>(
  Types.getSummaryLotsRequest
);
export const getSummaryLotsSuccess = createAction<SummaryLots[]>(
  Types.getSummaryLotsSuccess
);
export const getSummaryLotsFailure = createAction<string>(
  Types.getSummaryLotsFailure
);

export const getTiersRequest = createAction(Types.getTiersRequest);
export const getTiersSuccess = createAction<Tier[]>(Types.getTiersSuccess);
export const getTiersFailure = createAction(Types.getTiersFailure);
export const getSeasonsRequest = createAction(Types.getSeasonsRequest);
export const getSeasonsSuccess = createAction<Season[]>(
  Types.getSeasonsSuccess
);
export const getSeasonsFailure = createAction(Types.getSeasonsFailure);
export const getZonesRequest = createAction(Types.getZonesRequest);
export const getZonesSuccess = createAction<Zone[]>(Types.getZonesSuccess);
export const getZonesFailure = createAction(Types.getZonesFailure);
export const getEntitiesLotSummaryRequest = createAction<SummaryLotsQueries>(
  Types.getEntitiesLotSummaryRequest
);
export const getEntitiesLotSummarySuccess = createAction<SummaryLots>(
  Types.getEntitiesLotSummarySuccess
);
export const getEntitiesLotSummaryFailure = createAction<string>(
  Types.getEntitiesLotSummaryFailure
);
export const getEntitiesLotsRequest = createAction<SummaryLotsQueries>(
  Types.getEntitiesLotsRequest
);
export const getEntitiesLotsSuccess = createAction<{
  page?: number;
  pageSize?: number;
  pageTokens: string[];
  entities: Lot[];
}>(Types.getEntitiesLotsSuccess);
export const getEntitiesLotsFailure = createAction<string>(
  Types.getEntitiesLotsFailure
);
export const onChangePageEntitiesLots = createAction<SummaryLotsQueries>(
  Types.onChangePageEntitiesLots
);
export const onChangePageSizeEntitiesLots = createAction<SummaryLotsQueries>(
  Types.onChangePageSizeEntitiesLots
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSummaryStocksRequest, (state) => {
      state.summaryStocks.isLoading = true;
      state.summaryStocks.error = null;
    })
    .addCase(getSummaryStocksSuccess, (state, { payload }) => {
      state.summaryStocks.isLoading = false;
      state.summaryStocks.entities = payload.entities;
      state.summaryStocks.summary = payload.summary;
    })
    .addCase(getSummaryStocksFailure, (state, action) => {
      state.summaryStocks.isLoading = false;
      state.summaryStocks.error = action.payload;
    });
  builder
    .addCase(getTiersRequest, (state) => {
      state.tiers.isLoading = true;
    })
    .addCase(getTiersSuccess, (state, { payload }) => {
      state.tiers = {
        entities: payload,
        isLoading: false,
      };
    })
    .addCase(getTiersFailure, (state) => {
      state.tiers.isLoading = false;
    });
  builder
    .addCase(getSeasonsRequest, (state) => {
      state.seasons.isLoading = true;
    })
    .addCase(getSeasonsSuccess, (state, { payload }) => {
      state.seasons = {
        entities: payload,
        isLoading: false,
      };
    })
    .addCase(getSeasonsFailure, (state) => {
      state.seasons.isLoading = false;
    });
  builder
    .addCase(getSummaryLotsRequest, (state) => {
      state.summaryLots.isLoading = true;
      state.summaryLots.error = null;
    })
    .addCase(getSummaryLotsSuccess, (state, { payload }) => {
      return {
        ...state,
        summaryLots: {
          ...state.summaryLots,
          entities: payload,
          isLoading: false,
        },
      };
    })
    .addCase(getSummaryLotsFailure, (state, action) => {
      state.summaryLots.isLoading = false;
      state.summaryLots.error = action.payload;
    });
  builder
    .addCase(getZonesRequest, (state) => {
      state.zones.isLoading = true;
    })
    .addCase(getZonesSuccess, (state, { payload }) => {
      state.zones = {
        entities: payload,
        isLoading: false,
      };
    })
    .addCase(getZonesFailure, (state) => {
      state.zones.isLoading = false;
    });
  builder
    .addCase(getEntitiesLotSummarySuccess, (state, { payload }) => {
      state.lotsEntities.summary = payload;
    })
    .addCase(getEntitiesLotSummaryFailure, (state) => {
      state.lotsEntities.isLoading = false;
    });
  builder
    .addCase(getEntitiesLotsRequest, (state) => {
      state.lotsEntities.isLoading = true;
    })
    .addCase(getEntitiesLotsSuccess, (state, { payload }) => {
      state.lotsEntities = {
        ...state.lotsEntities,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(getEntitiesLotsFailure, (state) => {
      state.lotsEntities.isLoading = false;
    });
});

export default reducer;
