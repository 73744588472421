import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { GetExchangeRatesResponse } from 'models';
import { findErrorToData } from 'utils';

let cancels: Canceler[] = [];

type GetExchangeRatesRequest = ReturnType<
  typeof actions.getExchangeRatesRequest
>;
function* getExchangeRates({ payload }: GetExchangeRatesRequest) {
  try {
    const { data }: GetExchangeRatesResponse = yield call(
      API.get,
      `/v1/exchangerate/exchangeRates`,
      {
        cancelToken: new CancelToken((c) => cancels.push(c)),
      }
    );
    cancels = [];
    const exchangeRates = data.exchangeRates.filter(
      (rate) => !(rate.from === 'LAK' && rate.to === 'LAK')
    );
    yield put(
      actions.getExchangeRatesSuccess({
        data: exchangeRates,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getExchangeRatesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createExchangeRate({
  payload,
}: ReturnType<typeof actions.createExchangeRateRequest>) {
  try {
    yield call(API.post, `/v1/admin/exchangerate/exchangeRates-update`, {
      from: payload.from,
      to: payload.to,
      buy: payload.buy,
      sell: payload.sell,
    });
    yield put(actions.createExchangeRateSuccess(payload));
    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createExchangeRateFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetExchangeRates() {
  yield takeLatest(actions.Types.getExchangeRatesRequest, getExchangeRates);
}
function* watchCreateExchangeRate() {
  yield takeLatest(actions.Types.createExchangeRateRequest, createExchangeRate);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetExchangeRates),
    fork(watchCreateExchangeRate),
  ]);
}
export default saga;
