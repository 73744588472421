import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  SaleInvoice,
  CreateInvoiceForm,
  SaleInvoicesQueries,
  Order,
  SaleTransaction,
  UpdateInvoiceForm,
} from 'models';

export type DialogId = null | 'updateInvoice';
type Queries = DefaultQueries & SaleInvoicesQueries & {};

export interface InitialState {
  dialogId: DialogId;
  invoicesList: {
    data: SaleInvoice[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  invoiceDetail: {
    isLoading: boolean;
    error: string | null;
    invoice: SaleInvoice | null;
    transactions: SaleTransaction[];
    isUpdating: boolean;
  };
  invoiceCreate: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
  };
  ordersList: {
    isLoading: boolean;
    error: string | null;
    orders: Order[];
  };
  updateInvoice: {
    isUpdating: boolean;
    error: null | string;
    invoice: SaleInvoice | null;
    order: Order | null;
    isLoading: boolean;
  };
  printInvoice: {
    invoice: SaleInvoice | null;
    isPrinting: 'ready' | 'pending' | 'error' | 'wait';
    order: Order | null;
    transactions: SaleTransaction[];
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  dialogId: null,
  invoicesList: {
    data: [],
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  invoiceDetail: {
    isLoading: false,
    error: null,
    invoice: null,
    transactions: [],
    isUpdating: false,
  },
  invoiceCreate: {
    error: null,
    isCreating: false,
    isLoading: false,
  },
  ordersList: {
    isLoading: false,
    error: null,
    orders: [],
  },
  updateInvoice: {
    error: null,
    isUpdating: false,
    invoice: null,
    order: null,
    isLoading: false,
  },
  printInvoice: {
    isPrinting: 'wait',
    invoice: null,
    order: null,
    transactions: [],
  },
  exportData: {
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/sale-invoices@cancelRequestAPI',
  onChangeDialog = 'tier/sale-invoices@onChangeDialog',
  getInvoicesRequest = 'tier/sale-invoices@getSaleTransactionsRequest',
  getInvoicesSuccess = 'tier/sale-invoices@getSaleTransactionsSuccess',
  getInvoicesFailure = 'tier/sale-invoices@getSaleTransactionsFailure',
  getInvoiceRequest = 'tier/sale-invoices@getSaleTransactionRequest',
  getInvoiceSuccess = 'tier/sale-invoices@getSaleTransactionSuccess',
  getInvoiceFailure = 'tier/sale-invoices@getSaleTransactionFailure',
  onChangeInvoicesListPage = 'tier/sale-invoices@onChangeTransactionsListPage',
  onChangeInvoicesListPageSize = 'tier/sale-invoices@onChangeInvoicesListPageSize',
  openCreateInvoicePage = 'tier/sale-invoices@openCreateSaleTransactionPage',
  createInvoiceRequest = 'tier/sale-invoices@createSaleTransactionRequest',
  createInvoiceSuccess = 'tier/sale-invoices@createSaleTransactionSuccess',
  createInvoiceFailure = 'tier/sale-invoices@createSaleTransactionFailure',
  getOrdersRequest = 'tier/sale-invoices@getOrdersRequest',
  getOrdersSuccess = 'tier/sale-invoices@getOrdersSuccess',
  getOrdersFailure = 'tier/sale-invoices@getOrdersFailure',
  getTransactionsRequest = 'tier/sale-invoices@getTransactionsRequest',
  getTransactionsSuccess = 'tier/sale-invoices@getTransactionsSuccess',
  getTransactionsFailure = 'tier/sale-invoices@getTransactionsFailure',
  updateInvoiceRequest = 'tier/sale-invoices@updateInvoiceRequest',
  updateInvoiceSuccess = 'tier/sale-invoices@updateInvoiceSuccess',
  updateInvoiceFailure = 'tier/sale-invoices@updateInvoiceFailure',
  updateSummaryInvoiceRequest = 'tier/sale-invoices@updateSummaryInvoiceRequest',
  updateSummaryInvoiceSuccess = 'tier/sale-invoices@updateSummaryInvoiceSuccess',
  updateSummaryInvoiceFailure = 'tier/sale-invoices@updateSummaryInvoiceFailure',
  getInvoiceDataRequest = 'tier/sale-invoices@getInvoiceDataRequest',
  getInvoiceDataSuccess = 'tier/sale-invoices@getInvoiceDataSuccess',
  getInvoiceDataFailure = 'tier/sale-invoices@getInvoiceDataFailure',
  printInvoiceRequest = 'tier/sale-invoices@printInvoiceRequest',
  printInvoiceReady = 'tier/sale-invoices@printInvoiceReady',
  printInvoiceSuccess = 'tier/sale-invoices@printInvoiceSuccess',
  printInvoiceFailure = 'tier/sale-invoices@printInvoiceFailure',
  setDataToPrint = 'tier/sale-invoices@setDataToPrint',
  exportCsvRequest = 'tier/sale-invoices@exportCsvRequest',
  exportCsvSuccess = 'tier/sale-invoices@exportCsvSuccess',
  exportCsvFailure = 'tier/sale-invoices@exportCsvFailure',
}
export interface GetTransactionsSuccess extends Queries {
  data: SaleInvoice[];
}
export type GetInvoiceSuccess = {
  invoice: SaleInvoice;
  transactions: SaleTransaction[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialog = createAction<DialogId>(Types.onChangeDialog);
export const getInvoicesRequest = createAction<Queries>(
  Types.getInvoicesRequest
);
export const getInvoicesSuccess = createAction<GetTransactionsSuccess>(
  Types.getInvoicesSuccess
);
export const getInvoicesFailure = createAction<string>(
  Types.getInvoicesFailure
);
export const onChangeInvoicesListPage = createAction<Queries>(
  Types.onChangeInvoicesListPage
);
export const onChangeInvoicesListPageSize = createAction<Queries>(
  Types.onChangeInvoicesListPageSize
);
export const getInvoiceRequest = createAction<string>(Types.getInvoiceRequest);
export const getInvoiceSuccess = createAction<GetInvoiceSuccess>(
  Types.getInvoiceSuccess
);
export const getInvoiceFailure = createAction<string>(Types.getInvoiceFailure);
export const openCreateInvoicePage = createAction(Types.openCreateInvoicePage);
export const createInvoiceRequest = createAction<CreateInvoiceForm>(
  Types.createInvoiceRequest
);
export const createInvoiceSuccess = createAction(Types.createInvoiceSuccess);
export const createInvoiceFailure = createAction<string>(
  Types.createInvoiceFailure
);
export const getOrdersRequest = createAction(Types.getOrdersRequest);
export const getOrdersSuccess = createAction<Order[]>(Types.getOrdersSuccess);
export const getOrdersFailure = createAction<string>(Types.getOrdersFailure);
export const updateInvoiceRequest = createAction<UpdateInvoiceForm>(
  Types.updateInvoiceRequest
);
export const updateInvoiceSuccess = createAction(Types.updateInvoiceSuccess);
export const updateInvoiceFailure = createAction<string>(
  Types.updateInvoiceFailure
);
export const updateSummaryInvoiceRequest = createAction(
  Types.updateSummaryInvoiceRequest
);
export const updateSummaryInvoiceSuccess = createAction(
  Types.updateSummaryInvoiceSuccess
);
export const updateSummaryInvoiceFailure = createAction<string>(
  Types.updateSummaryInvoiceFailure
);
export const getInvoiceDataRequest = createAction<string>(
  Types.getInvoiceDataRequest
);
export const getInvoiceDataSuccess = createAction<{
  invoice: SaleInvoice;
  order: Order;
}>(Types.getInvoiceDataSuccess);
export const getInvoiceDataFailure = createAction<string>(
  Types.getInvoiceDataFailure
);
export const printInvoiceRequest = createAction<SaleInvoice>(
  Types.printInvoiceRequest
);
export const printInvoiceSuccess = createAction(Types.printInvoiceSuccess);
export const printInvoiceReady = createAction(Types.printInvoiceReady);
export const printInvoiceFailure = createAction(Types.printInvoiceFailure);
export const setDataToPrint = createAction<{
  order: Order;
  transactions: SaleTransaction[];
}>(Types.setDataToPrint);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(onChangeDialog, (state, action) => {
      return { ...state, dialogId: action.payload };
    })
    .addCase(getInvoicesRequest, (state) => {
      state.invoicesList.isLoading = true;
      state.invoicesList.error = null;
    })
    .addCase(getInvoicesSuccess, (state, action) => {
      return {
        ...state,
        invoicesList: {
          ...state.invoicesList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getInvoicesFailure, (state, action) => {
      state.invoicesList.isLoading = false;
      state.invoicesList.error = action.payload;
    })
    .addCase(createInvoiceRequest, (state) => {
      state.invoiceCreate.isCreating = true;
      state.invoiceCreate.error = null;
    })
    .addCase(createInvoiceSuccess, (state) => {
      state.invoiceCreate.isCreating = false;
    })
    .addCase(createInvoiceFailure, (state, action) => {
      state.invoiceCreate.isCreating = false;
      state.invoiceCreate.error = action.payload;
    })
    .addCase(getInvoiceRequest, (state) => {
      state.invoiceDetail.isLoading = true;
      state.invoiceDetail.error = null;
    })
    .addCase(getInvoiceSuccess, (state, action) => {
      state.invoiceDetail.isLoading = false;
      state.invoiceDetail.invoice = action.payload.invoice;
      state.invoiceDetail.transactions = action.payload.transactions;
    })
    .addCase(getInvoiceFailure, (state, action) => {
      state.invoiceDetail.isLoading = false;
      state.invoiceDetail.error = action.payload;
    })
    .addCase(getOrdersRequest, (state) => {
      state.ordersList.isLoading = true;
      state.ordersList.error = null;
    })
    .addCase(getOrdersSuccess, (state, action) => {
      state.ordersList.isLoading = false;
      state.ordersList.orders = action.payload;
    })
    .addCase(getOrdersFailure, (state, action) => {
      state.ordersList.isLoading = false;
      state.ordersList.error = action.payload;
    })
    .addCase(updateInvoiceRequest, (state) => {
      state.updateInvoice.isUpdating = true;
      state.updateInvoice.error = null;
    })
    .addCase(updateInvoiceSuccess, (state) => {
      state.updateInvoice.isUpdating = false;
    })
    .addCase(updateInvoiceFailure, (state, { payload }) => {
      state.updateInvoice.isUpdating = false;
      state.updateInvoice.error = payload;
    })
    .addCase(updateSummaryInvoiceRequest, (state) => {
      state.invoiceDetail.isUpdating = true;
      state.invoiceDetail.error = null;
    })
    .addCase(updateSummaryInvoiceSuccess, (state) => {
      state.invoiceDetail.isUpdating = false;
      state.dialogId = null;
    })
    .addCase(updateSummaryInvoiceFailure, (state, { payload }) => {
      state.invoiceDetail.isUpdating = false;
      state.invoiceDetail.error = payload;
    })
    .addCase(getInvoiceDataRequest, (state) => {
      state.updateInvoice.isLoading = true;
      state.updateInvoice.error = null;
      state.updateInvoice.invoice = null;
      state.updateInvoice.order = null;
    })
    .addCase(getInvoiceDataSuccess, (state, { payload }) => {
      state.updateInvoice.isLoading = false;
      state.updateInvoice.invoice = payload.invoice;
      state.updateInvoice.order = payload.order;
    })
    .addCase(getInvoiceDataFailure, (state, { payload }) => {
      state.updateInvoice.isLoading = false;
      state.updateInvoice.error = payload;
    })
    .addCase(printInvoiceRequest, (state, { payload }) => {
      state.printInvoice.isPrinting = 'pending';
      state.printInvoice.invoice = payload;
    })
    .addCase(printInvoiceReady, (state) => {
      state.printInvoice.isPrinting = 'ready';
    })
    .addCase(printInvoiceFailure, (state) => {
      state.printInvoice.isPrinting = 'error';
      state.printInvoice.invoice = null;
    })
    .addCase(printInvoiceSuccess, (state) => {
      state.printInvoice.isPrinting = 'wait';
      state.printInvoice.invoice = null;
    })
    .addCase(setDataToPrint, (state, { payload }) => {
      state.printInvoice.order = payload.order;
      state.printInvoice.transactions = payload.transactions;
    });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
