import {
  all,
  fork,
  takeLatest,
  put,
  call,
  select,
  delay,
} from 'redux-saga/effects';
import API from 'services/defaultInstance';
import * as auth from './index';
import { addAlert } from 'store/notify';
import { findErrorToData } from 'utils';
import { UpdateProfile } from 'models';
import { RootState } from 'store';
import { setProfile, logoutRequest } from 'store/auth';
const { Types, ...actions } = auth;

function* updatePassword({
  payload,
}: ReturnType<typeof actions.updatePasswordRequest>) {
  const {
    auth: {
      accept: { profile },
    },
  }: RootState = yield select((state) => state);
  try {
    yield call(API.post, `/v1/users-changePassword`, {
      username: profile?.username || '',
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
    });
    yield put(actions.updatePasswordSuccess());
    yield put(
      addAlert({
        message: 'ອັບເດດລະຫັດຜ່ານສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(
      addAlert({
        message: 'ກະລຸນາເຂົ້າສູ່ລະບົບໃໝ່',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield delay(300);
    yield put(logoutRequest());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updatePasswordFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* updateProfile({
  payload,
}: ReturnType<typeof actions.updateProfileRequest>) {
  const {
    auth: {
      accept: { profile },
    },
  }: RootState = yield select((state) => state);
  const me: UpdateProfile = {
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    phoneNumber: payload.phoneNumber,
  };
  try {
    yield call(API.post, `/v1/users-update`, me);
    yield put(actions.updateProfileSuccess(payload));
    yield put(
      addAlert({
        message: 'ອັບເດດຂໍ້ມູນສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(
      setProfile({
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        phoneNumber: payload.phoneNumber,
        username: profile?.username || '',
        role: profile?.role || '',
        zone: profile?.zone || '',
        id: profile?.id || '',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateProfileFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* watchUpdatePassword() {
  yield takeLatest(actions.updatePasswordRequest, updatePassword);
}
function* watchUpdateProfile() {
  yield takeLatest(actions.updateProfileRequest, updateProfile);
}
function* sagas() {
  yield all([fork(watchUpdatePassword), fork(watchUpdateProfile)]);
}
export default sagas;
