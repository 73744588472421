import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  InvoicesFilters,
  ReceiptsFilters,
  Invoice,
  SummaryInvoices,
  SummaryInvoicesFilters,
  SummaryReceipts,
  SummaryReceiptsFilters,
  IReceipt,
  SalesQueries,
  DataSales,
  SaleTransaction,
  SaleSummaryColumn,
  DataSalesTable,
} from 'models/superAdminReport';
import type { DefaultQueries } from 'models';
import type { Sale as SaleSummaries } from 'models/dashboard';

type InvoicesQueries = DefaultQueries & InvoicesFilters & {};
type ReceiptsQueries = DefaultQueries & ReceiptsFilters & {};
export interface InitialState {
  invoices: {
    entities: Invoice[];
    summary: SummaryInvoices;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  receipts: {
    entities: IReceipt[];
    summary: SummaryReceipts;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  summaryInvoices: {
    entities: SummaryInvoices[];
    isLoading: boolean;
    error: string | null;
  };
  SummaryReceipts: {
    entities: SummaryReceipts[];
    isLoading: boolean;
    error: string | null;
  };
  saleSummaries: {
    entities: SaleSummaries[];
    isLoading: boolean;
    error: string | null;
  };
  salesTransactions: {
    entities: SaleTransaction[];
    summary: DataSales<SaleSummaryColumn>;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    pageTokens: string;
    timelines: number[];
  };
  salesQualityCheck: {
    entities: DataSalesTable<SaleSummaryColumn>;
    timelines: number[];
    isLoading: boolean;
    error: string | null;
    summaries: DataSales<SaleSummaryColumn>;
  };
}
const summary = {
  outAmount: {
    amount: '',
    currency: '',
  },
  paidAmount: {
    amount: '',
    currency: '',
  },
  tier: {
    name: '',
    title: '',
  },
  totalAmount: {
    amount: '',
    currency: '',
  },
  zone: {
    name: '',
    title: '',
  },
  countInvoice: 0,
  currency: '',
};
const initialState: InitialState = {
  invoices: {
    entities: [],
    summary: summary,
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    isExporting: false,
  },
  receipts: {
    entities: [],
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 5,
    pageTokens: [],
    isExporting: false,
    summary: {
      actualAmount: {
        amount: '',
        currency: '',
      },
      feeAmount: {
        amount: '',
        currency: '',
      },
      paidAmount: {
        amount: '',
        currency: '',
      },
      countReceipt: '',
      currency: '',
      tier: {
        name: '',
        title: '',
      },
      zone: {
        name: '',
        title: '',
      },
    },
  },
  summaryInvoices: {
    entities: [],
    error: null,
    isLoading: false,
  },
  SummaryReceipts: {
    entities: [],
    error: null,
    isLoading: false,
  },
  saleSummaries: {
    entities: [],
    error: null,
    isLoading: false,
  },
  salesTransactions: {
    entities: [],
    error: null,
    isLoading: false,
    pageTokens: '',
    isExporting: false,
    timelines: [],
    summary: {
      impurity: '',
      moisturePercent: '',
      number: '',
      product: {
        name: '',
        title: '',
      },
      province: '',
      starchPercent: '',
      summaries: {
        amount: 0,
        count: 0,
        price: 0,
        weight: 0,
      },
      tier: {
        name: '',
        title: '',
      },
      zone: {
        name: '',
        title: '',
      },
    },
  },
  salesQualityCheck: {
    entities: {},
    timelines: [],
    error: null,
    isLoading: false,
    summaries: {
      impurity: '',
      moisturePercent: '',
      number: '',
      product: {
        name: '',
        title: '',
      },
      province: '',
      starchPercent: '',
      summaries: {
        amount: 0,
        count: 0,
        price: 0,
        weight: 0,
      },
      tier: {
        name: '',
        title: '',
      },
      zone: {
        name: '',
        title: '',
      },
    },
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/report-sales@cancelRequestAPI',
  getInvoicesRequest = 'super-admin/report-sales@getInvoicesRequest',
  getInvoicesSuccess = 'super-admin/report-sales@getInvoicesSuccess',
  getInvoicesFailure = 'super-admin/report-sales@getInvoicesFailure',
  onChangeInvoicesPage = 'super-admin/report-sales@onChangeInvoicesPage',
  onChangeInvoicesPageSize = 'super-admin/report-sales@onChangeInvoicesPageSize',
  exportInvoicesCsvRequest = 'super-admin/report-sales@exportInvoicesCsvRequest',
  exportInvoicesCsvSuccess = 'super-admin/report-sales@exportInvoicesCsvSuccess',
  exportInvoicesCsvFailure = 'super-admin/report-sales@exportInvoicesCsvFailure',
  getReceiptsRequest = 'super-admin/report-sales@getReceiptsRequest',
  getReceiptsSuccess = 'super-admin/report-sales@getReceiptsSuccess',
  getReceiptsFailure = 'super-admin/report-sales@getReceiptsFailure',
  onChangeReceiptsPage = 'super-admin/report-sales@onChangeReceiptsPage',
  onChangeReceiptsPageSize = 'super-admin/report-sales@onChangeReceiptsPageSize',
  exportReceiptsCsvRequest = 'super-admin/report-sales@exportReceiptsCsvRequest',
  exportReceiptsCsvSuccess = 'super-admin/report-sales@exportReceiptsCsvSuccess',
  exportReceiptsCsvFailure = 'super-admin/report-sales@exportReceiptsCsvFailure',
  getSummaryInvoicesRequest = 'super-admin/report-sales@getSummaryInvoicesRequest',
  getSummaryInvoicesSuccess = 'super-admin/report-sales@getSummaryInvoicesSuccess',
  getSummaryInvoicesFailure = 'super-admin/report-sales@getSummaryInvoicesFailure',
  getSummaryReceiptsRequest = 'super-admin/report-sales@getSummaryReceiptsRequest',
  getSummaryReceiptsSuccess = 'super-admin/report-sales@getSummaryReceiptsSuccess',
  getSummaryReceiptsFailure = 'super-admin/report-sales@getSummaryReceiptsFailure',
  getSaleSummariesRequest = 'super-admin/report-sales@getSaleSummariesRequest',
  getSaleSummariesSuccess = 'super-admin/report-sales@getSaleSummariesSuccess',
  getSaleSummariesFailure = 'super-admin/report-sales@getSaleSummariesFailure',
  getSalesTransactionsRequest = 'super-admin/report-sales@getSalesTransactionsRequest',
  getSalesTransactionsSuccess = 'super-admin/report-sales@getSalesTransactionsSuccess',
  getSalesTransactionsFailure = 'super-admin/report-sales@getSalesTransactionsFailure',
  nextPageGetSalesTransactions = 'super-admin/report-sales@nextPageGetSalesTransactions',
  getSalesQualityCheckRequest = 'super-admin/report-sales@getSalesQualityCheckRequest',
  getSalesQualityCheckSuccess = 'super-admin/report-sales@getSalesQualityCheckSuccess',
  getSalesQualityCheckFailure = 'super-admin/report-sales@getSalesQualityCheckFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);

export const getInvoicesRequest = createAction<InvoicesQueries>(
  Types.getInvoicesRequest
);
export const getInvoicesSuccess = createAction<
  DefaultQueries & {
    entities: Invoice[];
    summary: SummaryInvoices;
  }
>(Types.getInvoicesSuccess);
export const getInvoicesFailure = createAction<string>(
  Types.getInvoicesFailure
);
export const onChangeInvoicesPage = createAction<InvoicesQueries>(
  Types.onChangeInvoicesPage
);
export const onChangeInvoicesPageSize = createAction<InvoicesQueries>(
  Types.onChangeInvoicesPageSize
);
export const exportInvoicesCsvRequest = createAction<InvoicesQueries>(
  Types.exportInvoicesCsvRequest
);
export const exportInvoicesCsvSuccess = createAction(
  Types.exportInvoicesCsvSuccess
);
export const exportInvoicesCsvFailure = createAction(
  Types.exportInvoicesCsvFailure
);

export const getReceiptsRequest = createAction<ReceiptsQueries>(
  Types.getReceiptsRequest
);
export const getReceiptsSuccess = createAction<
  DefaultQueries & {
    entities: IReceipt[];
    summary: SummaryReceipts;
  }
>(Types.getReceiptsSuccess);
export const getReceiptsFailure = createAction<string>(
  Types.getReceiptsFailure
);
export const onChangeReceiptsPage = createAction<ReceiptsQueries>(
  Types.onChangeReceiptsPage
);
export const onChangeReceiptsPageSize = createAction<ReceiptsQueries>(
  Types.onChangeReceiptsPageSize
);
export const exportReceiptsCsvRequest = createAction<ReceiptsQueries>(
  Types.exportReceiptsCsvRequest
);
export const exportReceiptsCsvSuccess = createAction(
  Types.exportReceiptsCsvSuccess
);
export const exportReceiptsCsvFailure = createAction(
  Types.exportReceiptsCsvFailure
);
export const getSummaryInvoicesRequest = createAction<SummaryInvoicesFilters>(
  Types.getSummaryInvoicesRequest
);
export const getSummaryInvoicesSuccess = createAction<SummaryInvoices[]>(
  Types.getSummaryInvoicesSuccess
);
export const getSummaryInvoicesFailure = createAction(
  Types.getSummaryInvoicesFailure
);
export const getSummaryReceiptsRequest = createAction<SummaryReceiptsFilters>(
  Types.getSummaryReceiptsRequest
);
export const getSummaryReceiptsSuccess = createAction<SummaryReceipts[]>(
  Types.getSummaryReceiptsSuccess
);
export const getSummaryReceiptsFailure = createAction(
  Types.getSummaryReceiptsFailure
);
export const getSaleSummariesRequest = createAction<SalesQueries>(
  Types.getSaleSummariesRequest
);
export const getSaleSummariesSuccess = createAction<SaleSummaries[]>(
  Types.getSaleSummariesSuccess
);
export const getSaleSummariesFailure = createAction(
  Types.getSaleSummariesFailure
);
export const getSalesTransactionsRequest = createAction<SalesQueries>(
  Types.getSalesTransactionsRequest
);
export const getSalesTransactionsSuccess = createAction<{
  entities: SaleTransaction[];
  summary: DataSales<SaleSummaryColumn>;
  pageTokens: string;
  timelines: number[];
  isFirstRender?: boolean;
}>(Types.getSalesTransactionsSuccess);
export const getSalesTransactionsFailure = createAction(
  Types.getSalesTransactionsFailure
);
export const nextPageGetSalesTransactions = createAction(
  Types.nextPageGetSalesTransactions
);
export const getSalesQualityCheckRequest = createAction<SalesQueries>(
  Types.getSalesQualityCheckRequest
);
export const getSalesQualityCheckSuccess = createAction<{
  entities: DataSalesTable<SaleSummaryColumn>;
  timelines: number[];
  summaries: DataSales<SaleSummaryColumn>;
}>(Types.getSalesQualityCheckSuccess);
export const getSalesQualityCheckFailure = createAction(
  Types.getSalesQualityCheckFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getInvoicesRequest, (state) => {
      state.invoices.isLoading = true;
      state.invoices.error = null;
    })
    .addCase(getInvoicesSuccess, (state, { payload }) => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getInvoicesFailure, (state, action) => {
      state.invoices.isLoading = false;
      state.invoices.error = action.payload;
    });
  builder
    .addCase(exportInvoicesCsvRequest, (state) => {
      state.invoices.isExporting = true;
    })
    .addCase(exportInvoicesCsvSuccess, (state) => {
      state.invoices.isExporting = false;
    })
    .addCase(exportInvoicesCsvFailure, (state) => {
      state.invoices.isExporting = false;
    });
  builder
    .addCase(getReceiptsRequest, (state) => {
      state.receipts.isLoading = true;
      state.receipts.error = null;
    })
    .addCase(getReceiptsSuccess, (state, { payload }) => {
      return {
        ...state,
        receipts: {
          ...state.receipts,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getReceiptsFailure, (state, action) => {
      state.receipts.isLoading = false;
      state.receipts.error = action.payload;
    });
  builder
    .addCase(exportReceiptsCsvRequest, (state) => {
      state.receipts.isExporting = true;
    })
    .addCase(exportReceiptsCsvSuccess, (state) => {
      state.receipts.isExporting = false;
    })
    .addCase(exportReceiptsCsvFailure, (state) => {
      state.receipts.isExporting = false;
    });
  builder
    .addCase(getSummaryInvoicesRequest, (state) => {
      state.summaryInvoices.isLoading = true;
    })
    .addCase(getSummaryInvoicesSuccess, (state, { payload }) => {
      state.summaryInvoices.isLoading = false;
      state.summaryInvoices.entities = payload;
    })
    .addCase(getSummaryInvoicesFailure, (state) => {
      state.summaryInvoices.isLoading = false;
    });
  builder
    .addCase(getSummaryReceiptsRequest, (state) => {
      state.SummaryReceipts.isLoading = true;
    })
    .addCase(getSummaryReceiptsSuccess, (state, { payload }) => {
      state.SummaryReceipts.isLoading = false;
      state.SummaryReceipts.entities = payload;
    })
    .addCase(getSummaryReceiptsFailure, (state) => {
      state.SummaryReceipts.isLoading = false;
    });
  builder
    .addCase(getSaleSummariesRequest, (state) => {
      state.saleSummaries.isLoading = true;
    })
    .addCase(getSaleSummariesSuccess, (state, { payload }) => {
      state.saleSummaries.isLoading = false;
      state.saleSummaries.entities = payload;
    })
    .addCase(getSaleSummariesFailure, (state) => {
      state.saleSummaries.isLoading = false;
    });
  builder
    .addCase(getSalesTransactionsRequest, (state) => {
      state.salesTransactions.isLoading = true;
    })
    .addCase(getSalesTransactionsSuccess, (state, { payload }) => {
      state.salesTransactions.isLoading = false;
      state.salesTransactions.summary = payload.summary;
      state.salesTransactions.timelines = payload.timelines;
      state.salesTransactions.pageTokens = payload.pageTokens;
      if (payload.isFirstRender) {
        state.salesTransactions.entities = payload.entities;
      } else {
        state.salesTransactions.entities = [
          ...state.salesTransactions.entities,
          ...payload.entities,
        ];
      }
    })
    .addCase(getSalesTransactionsFailure, (state) => {
      state.salesTransactions.isLoading = false;
    });
  builder
    .addCase(getSalesQualityCheckRequest, (state) => {
      state.salesQualityCheck.isLoading = true;
    })
    .addCase(getSalesQualityCheckSuccess, (state, { payload }) => {
      state.salesQualityCheck.isLoading = false;
      state.salesQualityCheck.entities = payload.entities;
      state.salesQualityCheck.timelines = payload.timelines;
    })
    .addCase(getSalesQualityCheckFailure, (state) => {
      state.salesQualityCheck.isLoading = false;
    });
});

export default reducer;
