import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  CreateSaleReceiptForm,
  DefaultQueries,
  SaleReceipt,
  SaleInvoice,
  SaleTransfer,
  SaleReceiptsQueries,
  Order,
  SaleTransaction,
} from 'models';

export type DialogId = null;
type Queries = DefaultQueries & SaleReceiptsQueries & {};

type ReceiptCreate = {
  invoice?: SaleInvoice;
  order?: Order;
  transactions: SaleTransaction[];
  transfers: SaleTransfer[];
};
export interface InitialState {
  dialogId: DialogId;
  receiptsList: {
    receipts: SaleReceipt[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  receiptCreate: ReceiptCreate & {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
  };
  print: {
    invoice: SaleInvoice | null;
    transactions: SaleTransaction[];
    receipt: SaleReceipt | null;
    isLoading: boolean;
    transfer?: SaleTransfer;
  };
  receiptList: {
    invoice: SaleInvoice | null;
    transfer: SaleTransfer | null;
    receipt: SaleReceipt | null;
    isLoading: boolean;
    error: string | null;
    transactions: SaleTransaction[];
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  dialogId: null,
  receiptsList: {
    receipts: [],
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  receiptCreate: {
    error: null,
    isCreating: false,
    isLoading: false,
    transactions: [],
    transfers: [],
  },

  print: {
    invoice: null,
    receipt: null,
    transactions: [],
    isLoading: false,
  },
  receiptList: {
    error: null,
    invoice: null,
    isLoading: false,
    receipt: null,
    transfer: null,
    transactions: [],
  },
  exportData: {
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/sale-receipts@cancelRequestAPI',
  onChangeDialog = 'tier/sale-receipts@onChangeDialog',
  getReceiptsRequest = 'tier/sale-receipts@getReceiptsRequest',
  getReceiptsSuccess = 'tier/sale-receipts@getReceiptsSuccess',
  getReceiptsFailure = 'tier/sale-receipts@getReceiptsFailure',
  onChangeReceiptsPage = 'tier/sale-receipts@onChangeReceiptsPage',
  onChangeReceiptsPageSize = 'tier/sale-receipts@onChangeReceiptsPageSize',

  createReceiptRequest = 'tier/sale-receipts@createReceiptRequest',
  createReceiptSuccess = 'tier/sale-receipts@createReceiptSuccess',
  createReceiptFailure = 'tier/sale-receipts@createReceiptFailure',

  printRequest = 'tier/sale-receipts@printRequest',
  printSuccess = 'tier/sale-receipts@printSuccess',
  printFailure = 'tier/sale-receipts@printFailure',

  getReceiptRequest = 'tier/sale-receipts@getReceiptRequest',
  getReceiptSuccess = 'tier/sale-receipts@getReceiptSuccess',
  getReceiptFailure = 'tier/sale-receipts@getReceiptFailure',

  getDataForCreateReceiptRequest = 'tier/sale-receipts@getDataForCreateReceiptRequest',
  getDataForCreateReceiptSuccess = 'tier/sale-receipts@getDataForCreateReceiptSuccess',
  getDataForCreateReceiptFailure = 'tier/sale-receipts@getDataForCreateReceiptFailure',
  exportCsvRequest = 'tier/sale-receipts@exportCsvRequest',
  exportCsvSuccess = 'tier/sale-receipts@exportCsvSuccess',
  exportCsvFailure = 'tier/sale-receipts@exportCsvFailure',
}
export interface GetReceiptsSuccess extends Queries {
  receipts: SaleReceipt[];
}
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialog = createAction<DialogId>(Types.onChangeDialog);
export const getReceiptsRequest = createAction<Queries>(
  Types.getReceiptsRequest
);
export const getReceiptsSuccess = createAction<GetReceiptsSuccess>(
  Types.getReceiptsSuccess
);
export const getReceiptsFailure = createAction<string>(
  Types.getReceiptsFailure
);
export const onChangeReceiptsPage = createAction<Queries>(
  Types.onChangeReceiptsPage
);
export const onChangeReceiptsPageSize = createAction<Queries>(
  Types.onChangeReceiptsPageSize
);
export const createReceiptRequest = createAction<CreateSaleReceiptForm>(
  Types.createReceiptRequest
);
export const createReceiptSuccess = createAction<CreateSaleReceiptForm>(
  Types.createReceiptSuccess
);
export const createReceiptFailure = createAction<string>(
  Types.createReceiptFailure
);

export const printRequest = createAction<SaleReceipt>(Types.printRequest);
export const printFailure = createAction(Types.printFailure);
export const printSuccess = createAction<{
  invoice: SaleInvoice;
  transactions: SaleTransaction[];
  transfer: SaleTransfer;
  receipt: SaleReceipt;
}>(Types.printSuccess);
export const getReceiptRequest = createAction<string>(Types.getReceiptRequest);
export const getReceiptFailure = createAction<string>(Types.getReceiptFailure);
export const getReceiptSuccess = createAction<{
  invoice: SaleInvoice;
  transfer: SaleTransfer;
  receipt: SaleReceipt;
  transactions: SaleTransaction[];
}>(Types.getReceiptSuccess);

export const getDataForCreateReceiptRequest = createAction<string>(
  Types.getDataForCreateReceiptRequest
);
export const getDataForCreateReceiptSuccess = createAction<
  RequireOne<ReceiptCreate, 'invoice' | 'order' | 'transactions' | 'transfers'>
>(Types.getDataForCreateReceiptSuccess);
export const getDataForCreateReceiptFailure = createAction<string>(
  Types.getDataForCreateReceiptFailure
);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(onChangeDialog, (state, action) => {
      return { ...state, dialogId: action.payload };
    })
    .addCase(getReceiptsRequest, (state) => {
      return {
        ...state,
        receiptsList: { ...state.receiptsList, isLoading: true, error: null },
      };
    })
    .addCase(getReceiptsSuccess, (state, action) => {
      return {
        ...state,
        receiptsList: {
          ...state.receiptsList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getReceiptsFailure, (state, action) => {
      state.receiptsList.isLoading = false;
      state.receiptsList.error = action.payload;
    })
    .addCase(createReceiptRequest, (state) => {
      state.receiptCreate.isCreating = true;
      state.receiptCreate.error = null;
    })
    .addCase(createReceiptSuccess, (state) => {
      state.receiptCreate.isLoading = false;
      state.receiptCreate.isCreating = false;
    })
    .addCase(createReceiptFailure, (state, action) => {
      state.receiptCreate.isCreating = false;
      state.receiptCreate.error = action.payload;
    })
    .addCase(printRequest, (state, { payload }) => {
      state.print.isLoading = true;
      state.print.receipt = payload;
      state.print.invoice = null;
      state.print.transactions = [];
      state.print.transfer = undefined;
    })
    .addCase(printSuccess, (state, { payload }) => {
      state.print.isLoading = true;
      state.print.transactions = payload.transactions;
      state.print.invoice = payload.invoice;
      state.print.transfer = payload.transfer;
      state.print.receipt = payload.receipt;
    })
    .addCase(printFailure, (state) => {
      state.print.isLoading = false;
      state.print.receipt = null;
    })
    .addCase(getReceiptRequest, (state) => {
      state.receiptList.isLoading = true;
      state.receiptList.transfer = null;
      state.receiptList.invoice = null;
      state.receiptList.receipt = null;
    })
    .addCase(getReceiptSuccess, (state, { payload }) => {
      state.receiptList.isLoading = false;
      state.receiptList.transfer = payload.transfer;
      state.receiptList.invoice = payload.invoice;
      state.receiptList.receipt = payload.receipt;
      state.receiptList.transactions = payload.transactions;
    })
    .addCase(getReceiptFailure, (state, { payload }) => {
      state.receiptList.isLoading = false;
      state.receiptList.error = payload;
    })
    .addCase(getDataForCreateReceiptRequest, (state) => {
      state.receiptCreate.error = null;
      state.receiptCreate.invoice = undefined;
      state.receiptCreate.order = undefined;
      state.receiptCreate.transactions = [];
      state.receiptCreate.error = null;
      state.receiptCreate.isLoading = true;
      state.receiptCreate.transfers = [];
    })
    .addCase(getDataForCreateReceiptSuccess, (state, { payload }) => {
      state.receiptCreate.isLoading = false;
      state.receiptCreate.invoice = payload.invoice;
      state.receiptCreate.order = payload.order;
      state.receiptCreate.transactions = payload.transactions;
      state.receiptCreate.transfers = payload.transfers;
    })
    .addCase(getDataForCreateReceiptFailure, (state, { payload }) => {
      state.receiptCreate.error = payload;
      state.receiptCreate.isLoading = false;
    });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
