import { styled } from '@mui/material/styles';

const Image = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default Image;
