import { combineReducers } from 'redux';
import sales from './sales';
import purchases from './purchases';
import lots from './lots';

export default combineReducers({
  sales,
  purchases,
  lots,
});
