import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import {
  findErrorToData,
  getAllDataWithCursorNextPage,
  joinParamsToStringHttpRequest,
  setPageInfo,
  prevOrNextCursorPage,
} from 'utils';
import {
  GetInvoicesResponse,
  SummaryInvoices,
  SummaryReceipts,
  GetReceiptsResponse,
  GetSalesTransactionsResponse,
  SaleTransaction,
} from 'models/superAdminReport';
import { exportSaleQualityCheckTable } from 'utils/report';

let cancels: Canceler[] = [];

function* getInvoicesRequest({
  payload,
}: ReturnType<typeof actions.getInvoicesRequest>) {
  const getSummary: SummaryInvoices = JSON.parse(payload.summary || '');

  if (typeof getSummary !== 'object') {
    yield put(
      actions.getInvoicesFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
      })
    );
    return;
  }
  if (Object.keys(getSummary).length !== 7) {
    yield put(
      actions.getInvoicesFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
        type: 'error',
      })
    );
    return;
  }
  const { invoices }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.sales
  );
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || invoices.pageSize,
      },
      keysParams: [
        'pageToken',
        'pageSize',
        'tier',
        'status',
        'startedAt',
        'endedAt',
        'number',
      ],
    });
    const { data }: GetInvoicesResponse = yield call(
      API.get,
      `/v1/sale/report/invoices?${queries}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const page = setPageInfo(
      {
        page: invoices.page,
        pageSize: invoices.pageSize,
        pageTokens: invoices.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    yield put(
      actions.getInvoicesSuccess({
        ...page,
        entities: data.invoices,
        summary: getSummary,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getInvoicesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeInvoicesPage({
  payload,
}: ReturnType<typeof actions.onChangeInvoicesPage>) {
  const { invoices }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.sales
  );

  const pageInfo = prevOrNextCursorPage({
    nextOrPrev: payload.nextOrPrev === 'prev' ? 'prev' : 'next',
    page: invoices.page,
    pageTokens: invoices.pageTokens,
  });

  yield put(actions.getInvoicesRequest({ ...payload, ...pageInfo }));
}
function* exportInvoicesCsv({
  payload,
}: ReturnType<typeof actions.exportInvoicesCsvRequest>) {
  const { invoices }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.sales
  );
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || invoices.pageSize,
      },
      keysParams: ['tier', 'status', 'startedAt', 'endedAt', 'number'],
    });
    const { data } = yield call(
      API.post,
      `/v1/sale/invoices-exportCsv`,
      queries
    );
    const blob = new Blob([data], {
      type: 'text/plain;charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍງານການອອກໃບຮຽກເກັບເງິນ ${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportInvoicesCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportInvoicesCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getSummaryInvoices({
  payload,
}: ReturnType<typeof actions.getSummaryInvoicesRequest>) {
  try {
    const entries: SummaryInvoices[] = yield getAllDataWithCursorNextPage<
      SummaryInvoices[],
      'invoices'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/sale/report/summary-invoices${queries}`, {
          params: { ...payload },
        }),
      keyResponse: 'invoices',
    });
    yield put(actions.getSummaryInvoicesSuccess(entries));
  } catch (error) {
    yield put(actions.getSummaryInvoicesFailure());
  }
}
function* getSummaryReceipts({
  payload,
}: ReturnType<typeof actions.getSummaryReceiptsRequest>) {
  try {
    const entries: SummaryReceipts[] = yield getAllDataWithCursorNextPage<
      SummaryReceipts[],
      'receipts'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/sale/report/summary-receipts${queries}`, {
          params: { ...payload },
        }),
      keyResponse: 'receipts',
    });
    yield put(actions.getSummaryReceiptsSuccess(entries));
  } catch (error) {
    yield put(actions.getSummaryReceiptsFailure());
  }
}
function* getReceipts({
  payload,
}: ReturnType<typeof actions.getInvoicesRequest>) {
  const getSummary: SummaryReceipts = JSON.parse(payload.summary || '');

  if (typeof getSummary !== 'object') {
    yield put(
      actions.getReceiptsFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
      })
    );
    return;
  }
  if (Object.keys(getSummary).length !== 7) {
    yield put(
      actions.getReceiptsFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
        type: 'error',
      })
    );
    return;
  }
  const { receipts }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.sales
  );
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || receipts.pageSize,
      },
      keysParams: [
        'pageToken',
        'pageSize',
        'tier',
        'status',
        'startedAt',
        'endedAt',
        'number',
      ],
    });
    const { data }: GetReceiptsResponse = yield call(
      API.get,
      `/v1/sale/report/receipts?${queries}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const page = setPageInfo(
      {
        page: receipts.page,
        pageSize: receipts.pageSize,
        pageTokens: receipts.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    yield put(
      actions.getReceiptsSuccess({
        ...page,
        entities: data.receipts,
        summary: getSummary,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getReceiptsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeReceiptsPage({
  payload,
}: ReturnType<typeof actions.onChangeReceiptsPage>) {
  const { receipts }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.sales
  );
  const pageInfo = prevOrNextCursorPage({
    nextOrPrev: payload.nextOrPrev === 'prev' ? 'prev' : 'next',
    page: receipts.page,
    pageTokens: receipts.pageTokens,
  });
  yield put(actions.getReceiptsRequest({ ...payload, ...pageInfo }));
}
function* getSaleSummaries({
  payload,
}: ReturnType<typeof actions.getSaleSummariesRequest>) {
  const { endedAt, startedAt, timeline, keyName, ...props } = payload;
  try {
    let params: any = {
      timeline,
      startedAt,
      ...props,
    };
    if (timeline === 'WEEK') {
      delete params.endedAt;
    }
    if (timeline === 'MONTH') {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data } = yield call(API.get, `/v1/dashboard/sale`, {
      params,
    });

    yield put(actions.getSaleSummariesSuccess(data.sale as any));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSaleSummariesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getSalesTransactions({
  payload,
}: ReturnType<typeof actions.getSalesTransactionsRequest>) {
  try {
    const {
      summary: summaryPayload,
      timeline,
      isFirstRender,
      startedAt,
      endedAt,
      season,
      customer,
    } = payload;
    const getSummary = JSON.parse(summaryPayload || '{}');
    if (typeof getSummary !== 'object') {
      yield put(actions.getSalesTransactionsFailure());
      yield put(
        addAlert({
          message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
          serviceType: 'snackbar',
        })
      );
      return;
    }
    //TODO : ກວດຂໍ້ມູນໃຫມ່
    const {
      salesTransactions: { timelines, pageTokens },
    }: actions.InitialState = yield select(
      (state) => state.superAdmin.report.sales
    );
    const cancelToken = CancelToken.source();
    cancels.push(cancelToken.cancel);
    const queries: any = {
      pageSize: 250,
      zone: getSummary.zone.name,
      tier: getSummary.tier.name,
      product: getSummary.product.name,
      startedAt,
      endedAt,
    };
    if (season) {
      queries.season = season;
    }
    if (customer) {
      queries.customer = customer;
    }
    if (pageTokens.length !== 0) {
      queries.pageToken = pageTokens;
    }
    const { data }: GetSalesTransactionsResponse = yield call(
      API.get,
      `/v1/sale/report/items`,
      {
        cancelToken: cancelToken.token,
        params: queries,
      }
    );
    const newTimelines: number[] = isFirstRender ? [] : [...timelines];
    const addTimeline = (t: number) => {
      const isTimeline = newTimelines.find((nt) => nt === t);
      if (!isTimeline) {
        newTimelines.push(t);
      }
    };
    const transactions: SaleTransaction[] = data.sale.map((sale) => {
      const {
        srcWeight,
        srcAmount,
        srcPrice,
        srcAmountCross,
        dstWeight,
        dstPrice,
        lossWeight,
        dstAmountCross,
        dstAmount,
        createdAt,
        ...props
      } = sale;
      const timelineValue =
        Number.parseInt(
          moment(createdAt).format(`${timeline === 'MONTH' ? 'M' : 'D'}`)
        ) || 1;
      addTimeline(timelineValue);
      const srcWeightNumber = Number.parseFloat(srcWeight);
      const srcPriceNumber = Number.parseFloat(srcPrice.amount);
      const srcAmountCrossNumber = Number.parseFloat(srcAmountCross.amount);
      const srcAmountNumber = Number.parseFloat(srcAmount.amount);
      const dstWeightNumber = Number.parseFloat(dstWeight);
      const dstPriceNumber = Number.parseFloat(dstPrice.amount);
      const dstAmountCrossNumber = Number.parseFloat(dstAmountCross.amount);
      const dstAmountNumber = Number.parseFloat(dstAmount.amount);
      const lossWeightNumber = Number.parseFloat(lossWeight);
      const summaries = {
        srcAmount: {
          amount: srcAmountNumber,
          currency: srcAmount.currency,
        },
        srcPrice: {
          amount: srcPriceNumber,
          currency: srcPrice.currency,
        },
        srcAmountCross: {
          amount: srcAmountCrossNumber,
          currency: srcAmountCross.currency,
        },
        dstPrice: {
          amount: dstPriceNumber,
          currency: dstPrice.currency,
        },
        lossWeight: lossWeightNumber,
        dstAmountCross: {
          amount: dstAmountCrossNumber,
          currency: dstAmountCross.currency,
        },
        dstAmount: {
          amount: dstAmountNumber,
          currency: dstAmount.currency,
        },
        srcWeight: srcWeightNumber,
        dstWeight: dstWeightNumber,
      };
      return {
        ...props,
        createdAt,
        summaries: summaries,
        [timelineValue]: summaries,
      };
    });
    yield put(
      actions.getSalesTransactionsSuccess({
        entities: transactions,
        summary: getSummary,
        pageTokens: data.nextPageToken,
        timelines: newTimelines.sort((a, b) => a - b),
        isFirstRender,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSalesTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getSalesQualityCheck({
  payload,
}: ReturnType<typeof actions.getSalesQualityCheckRequest>) {
  const { endedAt, startedAt, timeline, keyName, ...props } = payload;
  try {
    let params: any = {
      timeline,
      startedAt,
      ...props,
    };
    if (timeline === 'WEEK') {
      delete params.endedAt;
    }
    if (timeline === 'MONTH') {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data } = yield call(API.get, `/v1/dashboard/summary-sale`, {
      params,
    });
    const {
      timelines,
      data: { summaries, ...entities },
    } = exportSaleQualityCheckTable({
      data: data.sale,
    });
    yield put(
      actions.getSalesQualityCheckSuccess({
        entities,
        timelines,
        summaries,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSalesQualityCheckFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetInvoicesRequest() {
  yield takeLatest(actions.Types.getInvoicesRequest, getInvoicesRequest);
}
function* watchOnChangeInvoicesPage() {
  yield takeLatest(actions.Types.onChangeInvoicesPage, onChangeInvoicesPage);
}
function* watchOnChangeInvoicesPageSize() {
  yield takeLatest(
    actions.Types.onChangeInvoicesPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeInvoicesPageSize>) {
      yield put(actions.getInvoicesRequest({ ...payload }));
    }
  );
}
function* watchExportInvoicesCsv() {
  yield takeLatest(actions.Types.exportInvoicesCsvRequest, exportInvoicesCsv);
}
function* watchGetSummaryInvoices() {
  yield takeLatest(actions.Types.getSummaryInvoicesRequest, getSummaryInvoices);
}
function* watchGetSummaryReceipts() {
  yield takeLatest(actions.Types.getSummaryReceiptsRequest, getSummaryReceipts);
}
function* watchGetReceiptsRequest() {
  yield takeLatest(actions.Types.getReceiptsRequest, getReceipts);
}
function* watchOnChangeReceiptsPage() {
  yield takeLatest(actions.Types.onChangeReceiptsPage, onChangeReceiptsPage);
}
function* watchOnChangeReceiptsPageSize() {
  yield takeLatest(
    actions.Types.onChangeReceiptsPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeReceiptsPageSize>) {
      yield put(actions.getReceiptsRequest({ ...payload }));
    }
  );
}
function* watchGetSaleSummaries() {
  yield takeLatest(actions.Types.getSaleSummariesRequest, getSaleSummaries);
}
function* watchGetSalesTransactions() {
  yield takeLatest(
    actions.Types.getSalesTransactionsRequest,
    getSalesTransactions
  );
}
function* watchGetSalesQualityCheck() {
  yield takeLatest(
    actions.Types.getSalesQualityCheckRequest,
    getSalesQualityCheck
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetInvoicesRequest),
    fork(watchOnChangeInvoicesPage),
    fork(watchOnChangeInvoicesPageSize),
    fork(watchExportInvoicesCsv),
    fork(watchGetSummaryInvoices),
    fork(watchGetSummaryReceipts),
    fork(watchGetReceiptsRequest),
    fork(watchGetInvoicesRequest),
    fork(watchOnChangeReceiptsPage),
    fork(watchOnChangeReceiptsPageSize),
    fork(watchGetSaleSummaries),
    fork(watchGetSalesTransactions),
    fork(watchGetSalesQualityCheck),
  ]);
}
export default saga;
