import { createReducer, createAction } from '@reduxjs/toolkit';
import { ReportDefaultQueries, Purchase } from 'models/dashboard';

type SetZoneWeight = {
  entities: Purchase[];
  summaries: {
    [key: string]: {
      weight: number;
      price: number;
      count: number;
      title: string;
    };
  };
};
export interface InitialState {
  weight: SetZoneWeight & {
    isLoading: boolean;
    error: string | null;
  };
}
const initialState: InitialState = {
  weight: {
    entities: [],
    error: null,
    isLoading: false,
    summaries: {
      'dry-cassava': {
        weight: 0,
        count: 0,
        title: 'ມັນແຫ້ງ',
        price: 0,
      },
      'fresh-cassava': {
        weight: 0,
        count: 0,
        title: 'ມັນຫົວ',
        price: 0,
      },
    },
  },
};

export enum Types {
  cancelRequestAPI = 'super-admin/dashboard@cancelRequestAPI',
  getPurchasesWeightRequest = 'super-admin/dashboard@getPurchasesWeightRequest',
  getPurchasesWeightSuccess = 'super-admin/dashboard@getPurchasesWeightSuccess',
  getPurchasesWeightFailure = 'super-admin/dashboard@getPurchasesWeightFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getPurchasesWeightRequest = createAction<ReportDefaultQueries>(
  Types.getPurchasesWeightRequest
);
export const getPurchasesWeightSuccess = createAction<SetZoneWeight>(
  Types.getPurchasesWeightSuccess
);
export const getPurchasesWeightFailure = createAction<string>(
  Types.getPurchasesWeightFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    return { ...state, isLoading: false, isCreating: false };
  });
  builder
    .addCase(getPurchasesWeightRequest, (state) => {
      state.weight.isLoading = true;
      state.weight.error = null;
    })
    .addCase(getPurchasesWeightSuccess, (state, { payload }) => {
      return {
        ...state,
        weight: {
          ...state.weight,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getPurchasesWeightFailure, (state, { payload }) => {
      state.weight.isLoading = false;
      state.weight.error = payload;
    });
});

export default reducer;
