import type {
  Purchase,
  PurchaseZonesWeight,
  PurchaseZonesWeeks,
  TimelineType,
  Lot,
  SummariesLots,
  LotsDataChart,
  Sale,
  SummariesGroupProduct,
  SalesWeightDataChart,
  SalesTimeLineDataChart,
} from 'models/dashboard';
import { provinceName } from 'utils';
export const summariesPurchasesWeight = ({
  purchases,
  mode,
  product = 'all',
}: {
  mode: 'zone' | 'tier';
  product: 'dry-cassava' | 'fresh-cassava' | 'all';
  purchases: Purchase[];
}): PurchaseZonesWeight => {
  const entities = purchases.reduce((entities, purchase) => {
    const getValueMode =
      mode === 'zone' ? purchase.zone.name : `${purchase.tier.name}`;
    const getValueProduct = product === 'all' ? '' : product;
    let keyName = `${getValueMode}${getValueProduct}`;
    const value = entities[keyName] ?? {
      count: 0,
      weight: 0,
      amount: 0,
      price: 0,
      zone: purchase.zone.title,
      province: provinceName(purchase.province),
      tier:
        mode === 'tier'
          ? purchase.tier
          : {
              name: '',
              title: '',
            },
      product:
        product === 'all'
          ? {
              name: '',
              title: '',
            }
          : purchase.product,
    };
    if (product !== 'all' && product !== purchase.product.name) return entities;
    return {
      ...entities,
      [keyName]: {
        ...value,
        count: value.count + purchase.count,
        weight: value.weight + Number.parseFloat(purchase.weight) / 1000,
        price: value.price + Number.parseFloat(purchase.price),
        amount: value.amount + Number.parseFloat(purchase.amount),
      },
    };
  }, {} as PurchaseZonesWeight);
  return Object.keys(entities)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((acc, key) => {
      acc[key] = entities[key];
      return acc;
    }, {} as PurchaseZonesWeight);
};

interface Summaries {
  [key: string]: {
    weight: number;
    count: number;
    title: string;
    price: number;
  };
}
export const summariesPurchasesProducts = (
  purchases: Purchase[]
): Summaries => {
  return purchases.reduce((entities, purchase) => {
    const productValue = entities[purchase.product.name] ?? {
      weight: 0,
      count: 0,
      title: purchase.product.title,
      price: 0,
    };

    const weight = Number.parseFloat(purchase.weight);
    const price = Number.parseFloat(purchase.price);
    return {
      ...entities,
      [purchase.product.name]: {
        ...productValue,
        count: productValue.count + purchase.count,
        weight: productValue.weight + weight,
        price: productValue.price + price,
      },
    };
  }, {} as Summaries);
};

export const summariesPurchasesWeeks = ({
  purchases,
  mode,
  product = 'all',
}: {
  mode: 'zone' | 'tier';
  product: 'dry-cassava' | 'fresh-cassava' | 'all';
  purchases: Purchase[];
}): PurchaseZonesWeeks => {
  const entities = purchases.reduce((entities, purchase) => {
    const getValueMode =
      mode === 'zone' ? purchase.zone.name : `${purchase.tier.name}`;
    const getValueProduct = product === 'all' ? '' : product;
    let keyName = `${getValueMode}${getValueProduct}`;
    const value = entities[keyName] ?? {
      zone: purchase.zone.title,
      tier:
        mode === 'tier'
          ? purchase.tier
          : {
              name: '',
              title: '',
            },
      product:
        product === 'all'
          ? {
              name: '',
              title: '',
            }
          : purchase.product,
      province: provinceName(purchase.province),
      timeline: {},
    };
    if (product !== 'all' && product !== purchase.product.name) return entities;
    const timeline = value.timeline[purchase.timeline.value] ?? {
      count: 0,
      weight: 0,
      amount: 0,
      price: 0,
    };
    return {
      ...entities,
      [keyName]: {
        ...value,
        timeline: {
          ...value.timeline,
          [purchase.timeline.value]: {
            count: timeline.count + purchase.count,
            weight: timeline.weight + Number.parseFloat(purchase.weight) / 1000,
            price: timeline.price + Number.parseFloat(purchase.price),
            amount: timeline.amount + Number.parseFloat(purchase.amount),
          },
        },
      },
    };
  }, {} as PurchaseZonesWeeks);
  // return entities;
  return Object.keys(entities)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((acc, key) => {
      acc[key] = entities[key];
      return acc;
    }, {} as PurchaseZonesWeeks);
};
export const summariesPurchases = ({
  view,
  ...props
}: {
  view: TimelineType;
  product: 'dry-cassava' | 'fresh-cassava' | 'all';
  mode: 'zone' | 'tier';
  purchases: Purchase[];
}) => {
  if (view === 'WEEK') {
    return summariesPurchasesWeeks(props);
  }
  return summariesPurchasesWeight(props);
};
export const summariesLots = (lots: Lot[]): SummariesLots => {
  return lots.reduce(
    (entities, lot) => {
      const transformValue = entities['transform'] ?? {
        title: 'ມັນແປຮູບ',
        weight: '0',
        count: 0,
        price: '0',
      };
      const summariesValue = entities['summaries'] ?? {
        title: 'ມັນແຫ້ງທັງໝົດ',
        weight: '0',
        count: 0,
        price: '0',
      };
      const weight = Number.parseFloat(lot.inVolumeKG) / 1000;
      const price = Number.parseFloat(lot.intPrice);
      if (lot.transform) {
        return {
          ...entities,
          summaries: {
            ...summariesValue,
            count: summariesValue.count + 1,
            weight: (
              Number.parseFloat(summariesValue.weight) + weight
            ).toString(),
            price: (Number.parseFloat(summariesValue.price) + price).toString(),
          },
          transform: {
            ...transformValue,
            count: transformValue.count + 1,
            weight: (
              Number.parseFloat(transformValue.weight) + weight
            ).toString(),
            price: (Number.parseFloat(transformValue.price) + price).toString(),
          },
        };
      }
      const productValue = entities[lot.product.name] ?? {
        weight: '0',
        count: 0,
        price: '0',
        title: lot.product.title,
      };

      return {
        ...entities,
        summaries:
          lot.product.name !== 'fresh-cassava'
            ? {
                ...summariesValue,
                count: summariesValue.count + 1,
                weight: (
                  Number.parseFloat(summariesValue.weight) + weight
                ).toString(),
                price: (
                  Number.parseFloat(summariesValue.price) + price
                ).toString(),
              }
            : summariesValue,
        [lot.product.name]: {
          ...productValue,
          count: productValue.count + 1,
          weight: (Number.parseFloat(productValue.weight) + weight).toString(),
          price: (Number.parseFloat(productValue.price) + price).toString(),
        },
      };
    },
    {
      summaries: {
        weight: '0',
        count: 0,
        title: 'ມັນແຫ້ງທັງໝົດ',
        price: '0',
      },
      'dry-cassava': {
        weight: '0',
        count: 0,
        title: 'ມັນແຫ້ງ',
        price: '0',
      },
      'fresh-cassava': {
        weight: '0',
        count: 0,
        title: 'ມັນຫົວ',
        price: '0',
      },
      transform: {
        title: 'ມັນແປຮູບ',
        weight: '0',
        count: 0,
        price: '0',
      },
    } as SummariesLots
  );
};
export const summariesLotsToChart = ({
  lots,
  mode,
  product,
  transform,
}: {
  lots: Lot[];
  mode: 'zone' | 'tier';
  product: 'all' | 'fresh-cassava' | 'dry-cassava';
  transform: boolean;
}): LotsDataChart => {
  const entities = lots.reduce((entities, purchase) => {
    if (purchase.inVolumeKG === '0') return entities;
    const getValueMode =
      mode === 'zone' ? purchase.zone.name : `${purchase.tier.name}`;
    const getValueProduct = product === 'all' ? '' : product;
    const keyName = `${getValueMode}${transform ? '' : getValueProduct}`;

    const value = entities[keyName] ?? {
      count: 0,
      weight: '0',
      price: '0',
      zone: purchase.zone.title,
      tier:
        mode === 'tier'
          ? purchase.tier
          : {
              name: '',
              title: '',
            },
      product:
        product === 'all'
          ? {
              name: '',
              title: '',
            }
          : purchase.product,
    };
    if (transform && !purchase.transform) return entities;
    if (product !== 'all' && product !== purchase.product.name && !transform)
      return entities;
    return {
      ...entities,
      [keyName]: {
        ...value,
        count: value.count + 1,
        weight: (
          Number.parseFloat(value.weight) +
          Number.parseFloat(purchase.inVolumeKG) / 1000
        ).toString(),
        price: (
          Number.parseFloat(value.price) +
          Number.parseFloat(purchase.intPrice) / 1000
        ).toString(),
      },
    };
  }, {} as LotsDataChart);
  return Object.keys(entities)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((acc, key) => {
      acc[key] = entities[key];
      return acc;
    }, {} as LotsDataChart);
};
export const summariesSales = (sales: Sale[]): SummariesGroupProduct => {
  const entities = sales.reduce(
    (entities, sale) => {
      const product = entities[sale.product.name] ?? {
        price: 0,
        weight: 0,
        count: 0,
        title: sale.product.title,
        amount: 0,
      };
      const weight = Number.parseFloat(sale.weight);
      const price = Number.parseFloat(sale.price);
      const amount = Number.parseFloat(sale.revenue.amount);
      return {
        ...entities,
        [sale.product.name]: {
          ...product,
          price: product.price + price,
          weight: product.weight + weight,
          amount: product.amount + amount,
          count: product.count + sale.count,
        },
      };
    },
    {
      'dry-cassava': {
        price: 0,
        weight: 0,
        count: 0,
        title: 'ມັນແຫ້ງ',
        amount: 0,
      },
      'fresh-cassava': {
        price: 0,
        weight: 0,
        count: 0,
        title: 'ມັນຫົວ',
        amount: 0,
      },
    } as SummariesGroupProduct
  );
  return entities;
};

export const summariesSalesToChart = (
  viewMode: TimelineType,
  props: {
    sales: Sale[];
    mode: 'zone' | 'tier';
    product: 'all' | 'fresh-cassava' | 'dry-cassava';
  }
) => {
  if (viewMode === 'DATETIME') {
    return summariesSalesDateTime(props);
  }
  return summariesSalesTimWeekAndMonth(props);
};
const summariesSalesDateTime = ({
  sales,
  mode,
  product,
}: {
  sales: Sale[];
  mode: 'zone' | 'tier';
  product: 'all' | 'fresh-cassava' | 'dry-cassava';
}): SalesWeightDataChart => {
  const entities = sales.reduce((entities, sale) => {
    const getValueMode = mode === 'zone' ? sale.zone.name : `${sale.tier.name}`;
    const getValueProduct = product === 'all' ? '' : product;
    let keyName = `${getValueMode}${getValueProduct}`;
    const value = entities[keyName] ?? {
      count: 0,
      weight: '0',
      price: '0',
      amount: '0',
      zone: sale.zone.title,
      tier:
        mode === 'tier'
          ? sale.tier
          : {
              name: '',
              title: '',
            },
      product:
        product === 'all'
          ? {
              name: '',
              title: '',
            }
          : sale.product,
    };
    if (product !== 'all' && product !== sale.product.name) return entities;
    return {
      ...entities,
      [keyName]: {
        ...value,
        count: value.count + sale.count,
        weight: (
          Number.parseFloat(value.weight) +
          Number.parseFloat(sale.weight) / 1000
        ).toString(),
        price: (
          Number.parseFloat(value.price) + Number.parseFloat(sale.price)
        ).toString(),
        amount: (
          Number.parseFloat(value.amount) +
          Number.parseFloat(sale.revenue.amount)
        ).toString(),
      },
    };
  }, {} as SalesWeightDataChart);
  return Object.keys(entities)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((acc, key) => {
      const value = entities[key];
      acc[key] = {
        ...value,
        price: (Number.parseFloat(value.price) / value.count).toFixed(4),
      };
      return acc;
    }, {} as SalesWeightDataChart);
};
const summariesSalesTimWeekAndMonth = ({
  sales,
  mode,
  product,
}: {
  sales: Sale[];
  mode: 'zone' | 'tier';
  product: 'all' | 'fresh-cassava' | 'dry-cassava';
}): SalesTimeLineDataChart => {
  const entities = sales.reduce((entities, sale) => {
    const getValueMode = mode === 'zone' ? sale.zone.name : `${sale.tier.name}`;
    const getValueProduct = product === 'all' ? '' : product;
    let keyName = `${getValueMode}${getValueProduct}`;
    const value = entities[keyName] ?? {
      zone: sale.zone.title,
      tier:
        mode === 'tier'
          ? sale.tier
          : {
              name: '',
              title: '',
            },
      product:
        product === 'all'
          ? {
              name: '',
              title: '',
            }
          : sale.product,
      timeline: {},
    };
    if (product !== 'all' && product !== sale.product.name) return entities;
    const timeline = value.timeline[sale.timeline.value] ?? {
      count: 0,
      weight: '0',
      price: '0',
      amount: '0',
    };
    return {
      ...entities,
      [keyName]: {
        ...value,
        timeline: {
          ...value.timeline,
          [sale.timeline.value]: {
            count: timeline.count + sale.count,
            weight: (
              Number.parseFloat(timeline.weight) +
              Number.parseFloat(sale.weight) / 1000
            ).toString(),
            price: (
              Number.parseFloat(timeline.price) + Number.parseFloat(sale.price)
            ).toString(),
            amount: (
              Number.parseFloat(timeline.amount) +
              Number.parseFloat(sale.revenue.amount)
            ).toString(),
          },
        },
      },
    };
  }, {} as SalesTimeLineDataChart);
  return Object.keys(entities)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((acc, key) => {
      const value = entities[key];
      const timelineKeys = Object.keys(value.timeline);
      const timeline = timelineKeys.reduce((values, valueKey) => {
        const getValue = value.timeline[Number.parseInt(valueKey)];
        return {
          ...values,
          [valueKey]: {
            ...getValue,
            price: (Number.parseFloat(getValue.price) / getValue.count).toFixed(
              4
            ),
          },
        };
      }, {});
      acc[key] = {
        ...value,
        timeline: timeline,
      };
      return acc;
    }, {} as SalesTimeLineDataChart);
};
export const legendLabel = (
  value: string,
  timeline: TimelineType,
  keyValue: string
): string => {
  if (!keyValue) return value;
  const valueNumber = (value.match(/\d+/) || [0])[0];
  let name = '';
  switch (keyValue) {
    case 'weight':
      name = 'ນ້ຳໜັກ';
      break;
    case 'amount':
      name = 'ມູນຄ່າ';
      break;
    case 'price':
      name = 'ລາຄາ';
      break;
    default:
      name = value;
  }
  if (timeline === 'DATETIME') return name;
  if (timeline === 'WEEK') return `W ${valueNumber}`;
  return `M ${valueNumber}`;
};

export const tooltipLabel = (value: string = ''): string => {
  switch (value) {
    case 'weight':
      return 'T';
    case 'amount':
      return 'LAK';
    case 'price':
      return 'LAK';
    default:
      return '';
  }
};
export const createChartKey = (timeline: TimelineType, keyValue: string) => {
  if (timeline === 'WEEK') {
    return [
      {
        key: `timeline.1.${keyValue}`,
        color: '#4471c4',
      },
      {
        key: `timeline.2.${keyValue}`,
        color: '#EC7C30',
      },
      {
        key: `timeline.3.${keyValue}`,
        color: '#C7C7C7',
      },
      {
        key: `timeline.4.${keyValue}`,
        color: '#F9BF2A',
      },
      {
        key: `timeline.5.${keyValue}`,
        color: '#5B9BD4',
      },
    ];
  }
  if (timeline === 'MONTH') {
    return [
      {
        key: `timeline.1.${keyValue}`,
        color: '#4471c4',
      },
      {
        key: `timeline.2.${keyValue}`,
        color: '#EC7C30',
      },
      {
        key: `timeline.3.${keyValue}`,
        color: '#C7C7C7',
      },
      {
        key: `timeline.4.${keyValue}`,
        color: '#F9BF2A',
      },
      {
        key: `timeline.5.${keyValue}`,
        color: '#5B9BD4',
      },
      {
        key: `timeline.6.${keyValue}`,
        color: '',
      },
      {
        key: `timeline.7.${keyValue}`,
        color: '',
      },
      {
        key: `timeline.8.${keyValue}`,
        color: '',
      },
      {
        key: `timeline.9.${keyValue}`,
        color: '',
      },
      {
        key: `timeline.10.${keyValue}`,
        color: '',
      },
      {
        key: `timeline.11.${keyValue}`,
        color: '',
      },
      {
        key: `timeline.12.${keyValue}`,
        color: '',
      },
    ];
  }
  return [
    {
      key: keyValue,
      color: '#4471c4',
    },
  ];
};

type TableData = {
  [key: string]: {
    tier: string;
    zone: string;
    product: string;
    timeline: {
      [key: number]: {
        price: number;
        amount: number;
        count: number;
        weight: number;
      };
    };
  };
};
export const tableConverter = ({
  sales,
  mode,
  product,
  timelineView,
}: {
  sales: Sale[];
  mode: 'zone' | 'tier';
  product: 'all' | 'fresh-cassava' | 'dry-cassava';
  timelineView: TimelineType;
}) => {
  let timelineValue = [1];
  const entities = sales.reduce((entities, sale) => {
    const getValueMode = mode === 'zone' ? sale.zone.name : `${sale.tier.name}`;
    const getValueProduct = product === 'all' ? '' : product;
    let keyName = `${getValueMode}${getValueProduct}`;
    const value = entities[keyName] ?? {
      zone: sale.zone.title,
      tier: sale.tier.title,
      product: sale.product.title,
      timeline: {},
    };
    if (timelineView !== 'DATETIME') {
      timelineValue.push(sale.timeline.value);
    }
    const timeline = value.timeline[
      timelineView === 'DATETIME' ? 1 : sale.timeline.value
    ] ?? {
      count: 0,
      weight: 0,
      price: 0,
      amount: 0,
    };
    const weight = Number.parseFloat(sale.weight);
    const amount = Number.parseFloat(sale.revenue.amount);
    const price = Number.parseFloat(sale.price);
    return {
      ...entities,
      [keyName]: {
        ...value,
        timeline: {
          ...value.timeline,
          [sale.timeline.value]: {
            count: timeline.count + sale.count,
            weight: timeline.weight + weight,
            amount: timeline.amount + amount,
            price: timeline.price + price,
          },
        },
      },
    };
  }, {} as TableData);
  // const uniqueTimelineValue = timelineValue.reduce((acc, curr) => {
  //   if (!timelineValue.includes(curr)) {
  //     acc.push(curr);
  //   }
  //   return acc;
  // }, [] as any);
  return Object.keys(entities)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((acc, key) => {
      const value = entities[key];
      const timelineKeys = Object.keys(value.timeline);
      const timeline = timelineKeys.reduce((values, valueKey) => {
        const getValue = value.timeline[Number.parseInt(valueKey)];
        return {
          ...values,
          [valueKey]: {
            ...getValue,
            price: getValue.price / getValue.count,
          },
        };
      }, {});
      acc[key] = {
        ...value,
        timeline: timeline,
      };
      return acc;
    }, {} as TableData);
};

export type CalculatorColumn = {
  amount: number;
  weight: number;
  price: number;
  count: number;
  localPrice: number;
};
type ColumnTimeline = {
  [key: number]: CalculatorColumn;
};
export type TableProps = {
  valueKey: 'amount' | 'weight' | 'price';
  zone: string;
  tier: string;
  province: string;
  id: string;
  number: string;
  summaries: CalculatorColumn;
} & ColumnTimeline;

export type DataSale = {
  [key: string]: {
    zone: string;
    tier: string;
    province: string;
    id: string;
    number: string;
    summaries: CalculatorColumn;
  } & ColumnTimeline;
};
export type ExportDataTableProps = {
  data: Sale[];
  mode: 'company' | 'zone' | 'tier';
};
export const exportDataTable = ({
  data,
  mode,
}: ExportDataTableProps): DataSale => {
  return data.reduce((entries, sale) => {
    let valueKey = '';
    switch (mode) {
      case 'company':
        valueKey = `${sale.zone.name}`;
        break;
      case 'zone':
        valueKey = `${sale.zone.name}-${sale.tier.name}`;
        break;
      default:
        valueKey = `${sale.tier.name}`;
    }
    const getValue = entries[valueKey] ?? {
      zone: sale.zone.title,
      tier: sale.tier.title,
      province: '',
      id: '',
      number: '',
      summaries: {
        amount: 0,
        weight: 0,
        price: 0,
        localPrice: 0,
        count: 0,
      },
    };
    const getTimelineInValue = getValue[sale.timeline.value] ?? {
      amount: 0,
      weight: 0,
      price: 0,
      localPrice: 0,
      count: 0,
    };
    const weight = Number.parseFloat(sale.weight);
    const price = Number.parseFloat(sale.price);
    const amount = 0;
    const localPrice = 0;
    return {
      ...entries,
      [valueKey]: {
        ...getValue,
        [sale.timeline.value]: {
          amount: getTimelineInValue.amount + amount,
          weight: getTimelineInValue.weight + weight,
          price: getTimelineInValue.price + price,
          localPrice: getTimelineInValue.localPrice + localPrice,
          count: getTimelineInValue.count + sale.count || 1,
        },
      },
    };
  }, {} as DataSale);
};
