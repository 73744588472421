import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  findErrorToData,
  getAllDataWithCursorNextPage,
  mappingParamsToPayload,
  setPageInfo,
} from 'utils';
import { RootState } from 'store';
import * as companyActions from 'store/super-admin/company';
import {
  SaleReceiptsQueries,
  GetSaleReceiptsResponse,
  CreateSaleReceipt,
  GetSaleInvoiceResponse,
  GetSaleTransferResponse,
  GetSaleReceiptResponse,
  SaleTransaction,
  GetOrderResponse,
  SaleTransfer,
  DefaultQueries,
} from 'models';

let cancels: Canceler[] = [];
function* getReceipts({
  payload,
}: ReturnType<typeof actions.getReceiptsRequest>) {
  const {
    saleReceipts: { receiptsList },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);

  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: SaleReceiptsQueries & DefaultQueries = {
      ...payloadParams,
      pageSize: pageSize || receiptsList.pageSize,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetSaleReceiptsResponse = yield call(
      API.get,
      `/v1/sale/receipts`,
      {
        cancelToken: cancelToken.token,
        params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: receiptsList.page,
        pageSize: receiptsList.pageSize,
        pageTokens: receiptsList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetReceiptsSuccess = {
      ...pageInfo,
      receipts: data.receipts,
    };
    yield put(actions.getReceiptsSuccess(dataPayload));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getReceiptsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeReceiptsPage({
  payload,
}: ReturnType<typeof actions.onChangeReceiptsPage>) {
  const {
    receiptsList: { page, pageTokens },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleReceipts
  );
  const { nextOrPrev, ...payloadParams } = payload;
  let params: SaleReceiptsQueries & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getReceiptsRequest(params));
}
function* onChangeReceiptsPageSize({
  payload,
}: ReturnType<typeof actions.onChangeReceiptsPageSize>) {
  const {
    receiptsList: { pageSize },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleReceipts
  );
  if (payload.pageSize !== pageSize) {
    yield put(actions.getReceiptsRequest({ ...payload }));
  }
}
function* createReceipt({
  payload,
}: ReturnType<typeof actions.createReceiptRequest>) {
  const {
    auth,
    router: { location },
    saleReceipts: {
      receiptCreate: { invoice, order },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  if (!order?.currency || !invoice) {
    yield put(actions.createReceiptFailure(''));
    return;
  }
  const metadata = payload.metadata.filter(
    (data) =>
      data.amount.amount &&
      data.amount.amount !== '0' &&
      data.amount.amount.length !== 0
  );
  const receipt: CreateSaleReceipt = {
    feeAmount: {
      amount:
        !payload.feeAmount.amount || payload.feeAmount.amount.length === 0
          ? '0'
          : payload.feeAmount.amount,
      currency: order.currency,
    },
    paidAmount: {
      amount: metadata
        .reduce(
          (accumulator, value) =>
            accumulator + Number.parseFloat(value.amount.amount || '0') || 0,
          0
        )
        .toString(),
      currency: order.currency,
    },
    invoiceId: invoice.id,
    paymentMethod: payload.paymentMethod,
    transferId: payload.transferId,
    tier: auth.accept.tier?.name || '',
    metadata: metadata.map((data) => ({
      amount: {
        amount: data.amount.amount,
        currency: order.currency,
      },
      transactionId: data.transactionId,
    })),
  };
  try {
    yield call(API.post, `/v1/sale/receipts`, receipt, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.createReceiptSuccess(payload));
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );

    cancels = [];
    yield put(
      push({
        pathname: `/tiers/${auth.accept.tier?.name}/sale/invoices`,
        search: location?.search || '',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createReceiptFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* getDataPrint({ payload }: ReturnType<typeof actions.printRequest>) {
  const {
    superAdmin: { company },
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state) => state);
  try {
    const invoiceRes: GetSaleInvoiceResponse = yield call(
      API.get,
      `v1/sale/invoices/${payload.invoiceId}`
    );
    const transactions: SaleTransaction[] = yield getAllDataWithCursorNextPage<
      SaleTransaction,
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/transactions${queries}&invoiceId=${
            payload.invoiceId
          }&tier=${tier?.name || ''}`
        ),
      keyResponse: 'transactions',
    });
    const {
      data: { transfer },
    }: GetSaleTransferResponse = yield call(
      API.get,
      `/v1/sale/transfers/${payload.transferId}`
    );
    const {
      data: { receipt },
    }: GetSaleReceiptResponse = yield call(
      API.get,
      `v1/sale/receipts/${payload.id}`
    );
    if (company.company.data.id.length === 0) {
      yield put(companyActions.getCompanyRequest());
      const companyRes:
        | ReturnType<typeof companyActions.getCompanyFailure>
        | ReturnType<typeof companyActions.getCompanySuccess> = yield take([
        companyActions.Types.getCompanyFailure,
        companyActions.Types.getCompanySuccess,
      ]);
      if (companyRes.type === companyActions.Types.getCompanyFailure) {
        yield put(actions.printFailure());
        return;
      }
    }
    const metadata: SaleTransaction[] = receipt.metadata.reduce((tns, tn) => {
      const transaction = transactions.find(
        (t) => t.vihicle.numberPlate === tn.vehicle
      );
      if (transaction) {
        return [...tns, transaction];
      }
      return tns;
    }, [] as SaleTransaction[]);
    yield put(
      actions.printSuccess({
        invoice: invoiceRes.data.invoice,
        transactions: metadata,
        transfer,
        receipt,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.printFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getReceipt({
  payload,
}: ReturnType<typeof actions.getReceiptRequest>) {
  try {
    const {
      data: { receipt },
    }: GetSaleReceiptResponse = yield call(
      API.get,
      `v1/sale/receipts/${payload}`
    );
    const {
      data: { invoice },
    }: GetSaleInvoiceResponse = yield call(
      API.get,
      `v1/sale/invoices/${receipt.invoiceId}`
    );
    const {
      data: { transfer },
    }: GetSaleTransferResponse = yield call(
      API.get,
      `/v1/sale/transfers/${receipt.transferId}`
    );
    const transactions: SaleTransaction[] = yield getAllDataWithCursorNextPage<
      SaleTransaction,
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/transactions${queries}&invoiceId=${receipt.invoiceId}`
        ),
      keyResponse: 'transactions',
    });
    const metadata: SaleTransaction[] = receipt.metadata.reduce((tns, tn) => {
      const transaction = transactions.find(
        (t) => t.vihicle.numberPlate === tn.vehicle
      );
      if (transaction) {
        return [...tns, transaction];
      }
      return tns;
    }, [] as SaleTransaction[]);

    yield put(
      actions.getReceiptSuccess({
        invoice: invoice,
        transfer: transfer,
        receipt: {
          ...receipt,
          invoiceId: invoice.number,
          transferId: transfer.referenceId,
        },
        transactions: metadata,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getReceiptFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* getDataForCreateReceiptRequest({
  payload,
}: ReturnType<typeof actions.getDataForCreateReceiptRequest>) {
  const {
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state: RootState) => state);

  try {
    const {
      data: { invoice },
    }: GetSaleInvoiceResponse = yield call(
      API.get,
      `v1/sale/invoices/${payload}`
    );
    const transactions: SaleTransaction[] = yield getAllDataWithCursorNextPage<
      SaleTransaction,
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/transactions${queries}&invoiceId=${payload}&tier=${
            tier?.name || ''
          }`
        ),
      keyResponse: 'transactions',
    });
    const {
      data: { purchaseOrder },
    }: GetOrderResponse = yield call(
      API.get,
      `/v1/sale/purchaseOrders/${invoice.purchaseOrderId}`
    );
    const transfers: SaleTransfer[] = yield getAllDataWithCursorNextPage<
      SaleTransfer[],
      'transfers'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/transfers${queries}&tier=${
            tier?.name || ''
          }&status=PENDING&currency=${purchaseOrder.currency}`
        ),
      keyResponse: 'transfers',
    });
    const transactionsSort = transactions.sort(
      (a, b) => Number.parseFloat(a.number) - Number.parseFloat(b.number)
    );
    yield put(
      actions.getDataForCreateReceiptSuccess({
        transactions: transactionsSort,
        invoice: invoice,
        order: purchaseOrder,
        transfers: transfers.filter(
          (t) => t.amount.currency === purchaseOrder.currency
        ),
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getDataForCreateReceiptFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield mappingParamsToPayload({
      values: {
        ...payload,
        tier: accept.tier?.name || '',
      },
      keysParams: [
        'paymentMethod',
        'startedAt',
        'transferId',
        'tier',
        'endedAt',
      ],
    });
    const { data } = yield call(
      API.post,
      `/v1/sale/receipts-exportCsv`,
      {},
      {
        params: queries,
      }
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `ລາຍການໃບຮັບເງິນ${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetReceiptsRequest() {
  yield takeLatest(actions.Types.getReceiptsRequest, getReceipts);
}
function* watchOnChangeReceiptsPage() {
  yield takeLatest(actions.Types.onChangeReceiptsPage, onChangeReceiptsPage);
}
function* watchOnChangePageSize() {
  yield takeLatest(
    actions.Types.onChangeReceiptsPageSize,
    onChangeReceiptsPageSize
  );
}
function* watchCreateReceipt() {
  yield takeLatest(actions.Types.createReceiptRequest, createReceipt);
}

// function* watchGetTransfers() {
//   yield takeLatest(actions.Types.getTransfersRequest, getTransfers);
// }
function* watchGetDataPrint() {
  yield takeLatest(actions.Types.printRequest, getDataPrint);
}
function* watchGetReceipt() {
  yield takeLatest(actions.Types.getReceiptRequest, getReceipt);
}

function* watchGetDataForCreateReceipt() {
  yield takeLatest(
    actions.Types.getDataForCreateReceiptRequest,
    getDataForCreateReceiptRequest
  );
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetReceiptsRequest),
    fork(watchOnChangeReceiptsPage),
    fork(watchOnChangePageSize),
    fork(watchCreateReceipt),
    // fork(watchGetTransfers),
    fork(watchGetDataPrint),
    fork(watchGetReceipt),
    fork(watchGetDataForCreateReceipt),
    fork(watchExportCsv),
  ]);
}
export default saga;
