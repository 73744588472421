import { all, fork } from 'redux-saga/effects';
import purchases from './purchases/saga';
import sales from './sales/saga';
import inventories from './inventories/saga';
import expenses from './expenses/saga';
import wallets from './wallets/saga';

export default function* sagas() {
  yield all([
    fork(purchases),
    fork(sales),
    fork(inventories),
    fork(expenses),
    fork(wallets),
  ]);
}
