import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetRolesResponse,
  CreteRole,
  GetRoleResponse,
  GetResourcesResponse,
} from 'models';
import {
  findErrorToData,
  mappingRoleToCreate,
  mappingAllowAccessResources,
} from 'utils';

import moment from 'moment';

let cancels: Canceler[] = [];

function* getRoles({ payload }: ReturnType<typeof actions.getRolesRequest>) {
  const { rolesList }: actions.InitialState = yield select(
    (state) => state.superAdmin.roles
  );
  let query = `pageSize=${
    payload.pageSize ? payload.pageSize : rolesList.pageSize
  }`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.createdAt) {
    query += `&createdAt=${encodeURIComponent(
      moment(payload.createdAt).format('YYYY-MM-DDTHH:mm:ssZ')
    )})}`;
  }
  let dataPayload: actions.GetRolesSuccess = {
    roles: [],
    pageTokens: [...rolesList.pageTokens],
  };
  try {
    const { data }: GetRolesResponse = yield call(
      API.get,
      `/v1/admin/tier/roles?${query}`
    );
    dataPayload.roles = data.roles;
    const pageToken = rolesList.pageTokens.find(
      (p) => p === data.nextPageToken
    );
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== rolesList.pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getRolesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getRolesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeRolesPage({
  payload,
}: ReturnType<typeof actions.onChangeRolesPage>) {
  const {
    rolesList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.roles);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getRolesRequest(cloneFilters));
}
function* createRole({
  payload,
}: ReturnType<typeof actions.createRoleRequest>) {
  try {
    const resources = mappingRoleToCreate(payload);
    const role: CreteRole = {
      role: payload.role,
      resources: resources,
    };
    yield call(API.post, '/v1/admin/tier/roles/resources/permissions', role);
    yield put(actions.createRoleSuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(push({ pathname: '/super-admin/roles' }));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createRoleFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetRoles() {
  yield takeLatest(actions.Types.getRolesRequest, getRoles);
}
function* watchOnChangeRolesPage() {
  yield takeLatest(actions.Types.onChangeRolesPage, onChangeRolesPage);
}
function* watchOnChangeRolesPageSize() {
  yield takeLatest(
    actions.Types.onChangeRolesPageSize,
    function* ({ payload }: ReturnType<typeof actions.onChangeRolesPageSize>) {
      yield put(actions.getRolesRequest(payload));
    }
  );
}
function* getRoleResources({
  payload,
}: ReturnType<typeof actions.getRoleResourcesRequest>) {
  try {
    const getRole: GetRoleResponse = yield call(
      API.get,
      `/v1/admin/tier/roles/${payload}`
    );
    const GetResources: GetResourcesResponse = yield call(
      API.get,
      `/v1/admin/tier/roles/${payload}/resources`
    );
    const resources = mappingAllowAccessResources(GetResources.data.resources);
    yield put(
      actions.getRoleResourcesSuccess({
        role: getRole.data.role,
        resources: resources,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getRoleResourcesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateRoleResources({
  payload,
}: ReturnType<typeof actions.updateRoleResourcesRequest>) {
  try {
    const resources = mappingRoleToCreate(payload);
    const role: CreteRole = {
      role: payload.role,
      resources: resources,
    };
    yield call(
      API.post,
      `/v1/admin/tier/roles/resources/permissions-update`,
      role
    );
    yield put(actions.updateRoleResourcesSuccess());
    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(push({ pathname: '/super-admin/roles' }));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateRoleResourcesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCreateRole() {
  yield takeLatest(actions.Types.createRoleRequest, createRole);
}
function* watchGetRoleResources() {
  yield takeLatest(actions.Types.getRoleResourcesRequest, getRoleResources);
}
function* watchUpdateRoleResources() {
  yield takeLatest(
    actions.Types.updateRoleResourcesRequest,
    updateRoleResources
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetRoles),
    fork(watchOnChangeRolesPage),
    fork(watchOnChangeRolesPageSize),
    fork(watchCreateRole),
    fork(watchGetRoleResources),
    fork(watchUpdateRoleResources),
  ]);
}
export default saga;
