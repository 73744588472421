import { MouseEvent, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Divider,
  MenuItem,
  Menu,
  Button,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Box,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTier } from 'store/auth/index';
import { Tier } from 'models/tier';

const SelectTiers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { tierName } = useParams();
  const {
    accept: { tiers, tier },
  } = useAppSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTier, setSearch] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (tiers.length === 1) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeTier = (tier: Tier) => {
    dispatch(setTier(tier));
    localStorage.setItem('tier', tier.name);
    if (tierName) {
      navigate({
        pathname: pathname.replace(tierName || '', tier.name),
        search: search,
      });
    }
  };
  return (
    <Box>
      <Button
        variant="text"
        color="secondary"
        size="small"
        endIcon={
          <ArrowDropDownIcon
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              top: 0,
            }}
          />
        }
        sx={{
          mt: 0.4,
          ml: 3,
        }}
        onClick={handleClick}
      >
        <Typography sx={{ color: 'white' }} fontWeight={700}>
          {tier?.title || ''}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="profile-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <FormControl sx={{ m: 1 }} variant="outlined">
          <OutlinedInput
            id="outlined-search-shop"
            type="search"
            value={searchTier}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="ຄົ້ນຫາລານ"
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle search" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label=""
          />
        </FormControl>
        <Divider sx={{ mb: 1, mx: 1 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            maxHeight: {
              md: '400px',
              xs: '70vh',
            },
          }}
        >
          {tiers
            .filter((t) => t.title.indexOf(searchTier) !== -1)
            .map((t) => (
              <MenuItem
                onClick={() => {
                  handleChangeTier(t);
                  setAnchorEl(null);
                }}
                id={t.id}
                key={t.id}
              >
                {t.title}
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </Box>
  );
};
export default SelectTiers;
