import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  delay,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData, setPageInfo, fileToBase64 } from 'utils';
import { RootState } from 'store';
import {
  SaleInvoicesQueries,
  GetSaleTransfersResponse,
  CreateSaleTransfer,
  GetWalletAccountsResponse,
  GetSaleTransferResponse,
  UpdateSaleTransfer,
  DefaultQueries,
  SaleTransfersQueries,
} from 'models';

let cancels: Canceler[] = [];

function* getTransfers({
  payload,
}: ReturnType<typeof actions.getTransfersRequest>) {
  const {
    saleTransfers: { transfersList },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);

  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: SaleTransfersQueries & DefaultQueries = {
      ...payloadParams,
      pageSize: pageSize || transfersList.pageSize,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetSaleTransfersResponse = yield call(
      API.get,
      `/v1/sale/transfers`,
      {
        cancelToken: cancelToken.token,
        params: params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: transfersList.page,
        pageSize: transfersList.pageSize,
        pageTokens: transfersList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetTransfersSuccess = {
      ...pageInfo,
      data: data.transfers,
    };
    yield put(actions.getTransfersSuccess(dataPayload));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTransfersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeTransfersListPage({
  payload,
}: ReturnType<typeof actions.onChangeTransfersListPage>) {
  const {
    transfersList: { page, pageTokens },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleTransfers
  );

  const { nextOrPrev, ...payloadParams } = payload;
  let params: SaleInvoicesQueries & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getTransfersRequest(params));
}
function* onChangeTransfersListPageSize({
  payload,
}: ReturnType<typeof actions.onChangeTransfersListPageSize>) {
  const {
    transfersList: { pageSize },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleTransfers
  );
  if (payload.pageSize !== pageSize) {
    yield put(actions.getTransfersRequest({ ...payload }));
  }
}

function* createTransfer({
  payload,
}: ReturnType<typeof actions.createTransferRequest>) {
  const {
    auth,
    saleTransfers: { walletAccounts },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `/v1/sale/transfers`;

  const account = walletAccounts.accounts.find(
    (account) => account.number === payload.to.number
  );
  const currency = account?.currency || 'LAK';
  const transfer: CreateSaleTransfer = {
    amount: {
      amount: payload.amount.amount,
      currency: currency,
    },
    referenceId: payload.referenceId,
    tier: auth.accept.tier?.name || '',
    to: {
      currency: currency,
      number: payload.to.number,
      title: account?.title || '',
    },
    from: {
      currency: currency,
      number: payload.from.number,
      title: payload.from.title,
      bankName: payload.from.bankName || '',
    },
    remark: payload.remark || '',
  };
  try {
    yield call(API.post, url, transfer, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.createTransferSuccess());
    cancels = [];

    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createTransferFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateTransfer({
  payload,
}: ReturnType<typeof actions.updateTransferRequest>) {
  const {
    saleTransfers: { walletAccounts, transferUpdate },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);

  const account = walletAccounts.accounts.find(
    (account) => account.number === payload.to.number
  );
  const currency = account?.currency || 'LAK';
  const transfer: UpdateSaleTransfer = {
    amount: {
      amount: payload.amount.amount,
      currency: currency,
    },
    referenceId: payload.referenceId,
    to: {
      currency: currency,
      number: payload.to.number,
      title: account?.title || '',
    },
    from: {
      currency: currency,
      number: payload.from.number,
      title: payload.from.title,
      bankName: payload.from.bankName,
    },
    id: transferUpdate.transfer?.id || '',
    remark: payload.remark || '',
  };
  try {
    yield call(API.post, `/v1/sale/transfers-update`, transfer, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.updateTransferSuccess(payload));
    cancels = [];

    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateTransferFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateStatusTransfer() {
  const {
    saleTransfers: {
      transferUpdate: { transfer },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);

  try {
    yield call(
      API.post,
      `/v1/sale/transfers-complete`,
      { id: transfer?.id || '' },
      {
        cancelToken: cancelToken.token,
      }
    );
    yield put(actions.updateStatusTransferSuccess());
    cancels = [];

    yield put(
      addAlert({
        message: 'ແກ້ໄຂສະຖານະສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateStatusTransferFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getWallets() {
  const { auth }: RootState = yield select((state: RootState) => state);
  if (!auth.accept.resourceAccess['wallets'].read) {
    yield put(actions.getWalletSuccess([]));
    return;
  }
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `/v1/tiers/${auth.accept.tier?.name || ''}/wallets?pageSize=250`;
  try {
    const { data }: GetWalletAccountsResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.getWalletSuccess(data.wallets));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getWalletsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* uploadImage({ payload }: ReturnType<typeof actions.uploadRequest>) {
  const { transfersList }: actions.InitialState = yield select(
    (state: RootState) => state.saleTransfers
  );
  const transfer = transfersList.data.find(
    (transfer) => transfer.referenceId === payload.referenceId
  );
  const formData = new FormData();
  formData.append('photos', payload.file);
  formData.append('transferId', transfer?.id || '');
  try {
    yield call(API.post, '/v1/sale/transfers-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const imageBase64: string = yield fileToBase64(payload.file);
    yield put(
      actions.uploadSuccess({
        id: transfer?.id || '',
        imageBase64: imageBase64,
      })
    );

    yield put(
      addAlert({
        message: 'ອັບໂຫລດຮູບສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.uploadFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getTransfer({
  payload,
}: ReturnType<typeof actions.getTransferRequest>) {
  try {
    const { data }: GetSaleTransferResponse = yield call(
      API.get,
      `/v1/sale/transfers/${payload.id}`
    );
    yield delay(500);
    yield put(actions.getTransferSuccess(data.transfer));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTransferFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: SaleTransfersQueries & DefaultQueries = {
      ...payloadParams,
      tier: accept.tier?.name || '',
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data } = yield call(
      API.post,
      `/v1/sale/transfers-exportCsv`,
      {},
      {
        params: params,
      }
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `ລາຍການໃບໂອນ${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openCreateTransferDialog() {
  const {
    saleTransfers: { walletAccounts },
    auth: {
      accept: { resourceAccess },
    },
  }: RootState = yield select((state) => state);
  if (resourceAccess['wallets'].read && walletAccounts.accounts.length === 0)
    yield put(actions.getWalletsRequest());
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetTransfers() {
  yield takeLatest(actions.Types.getTransfersRequest, getTransfers);
}
function* watchOnChangeTransfersListPage() {
  yield takeLatest(
    actions.Types.onChangeTransfersListPage,
    onChangeTransfersListPage
  );
}
function* watchOnChangeTransfersListPageSize() {
  yield takeLatest(
    actions.Types.onChangeTransfersListPageSize,
    onChangeTransfersListPageSize
  );
}

function* watchCreateTransfer() {
  yield takeLatest(actions.Types.createTransferRequest, createTransfer);
}
function* watchGetWallets() {
  yield takeLatest(actions.Types.getWalletsRequest, getWallets);
}
function* watchOpenCreateTransfer() {
  yield takeLatest(
    actions.Types.openCreateTransferDialog,
    openCreateTransferDialog
  );
}
function* watchUploadImage() {
  yield takeLatest(actions.Types.uploadRequest, uploadImage);
}
function* watchGetTransfer() {
  yield takeLatest(actions.Types.getTransferRequest, getTransfer);
}
function* watchSetTransfer() {
  yield takeLatest(actions.Types.setTransfer, openCreateTransferDialog);
}
function* watchUpdateTransfer() {
  yield takeLatest(actions.Types.updateTransferRequest, updateTransfer);
}
function* watchUpdateStatusTransfer() {
  yield takeLatest(
    actions.Types.updateStatusTransferRequest,
    updateStatusTransfer
  );
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetTransfers),
    fork(watchOnChangeTransfersListPage),
    fork(watchOnChangeTransfersListPageSize),
    fork(watchCreateTransfer),
    fork(watchGetWallets),
    fork(watchOpenCreateTransfer),
    fork(watchUploadImage),
    fork(watchGetTransfer),
    fork(watchSetTransfer),
    fork(watchUpdateTransfer),
    fork(watchUpdateStatusTransfer),
    fork(watchExportCsv),
  ]);
}
export default saga;
