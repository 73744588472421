import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { CreateSeason, GetSeasonsResponse } from 'models';
import { findErrorToData } from 'utils';

import moment from 'moment';

let cancels: Canceler[] = [];

function* getSeasons({
  payload,
}: ReturnType<typeof actions.getSeasonsRequest>) {
  const {
    seasonsList: { pageTokens, pageSize },
  }: actions.InitialState = yield select((state) => state.superAdmin.seasons);
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.startAt) {
    query += `&startAt=${encodeURIComponent(
      moment(payload.startAt).format('YYYY-MM-DDTHH:mm:ssZ')
    )})}`;
  }
  let dataPayload: actions.GetSeasonsSuccess = {
    seasons: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetSeasonsResponse = yield call(
      API.get,
      `/v1/admin/tier/seasons?${query}`
    );
    dataPayload.seasons = data.seasons;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getSeasonsSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSeasonsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeSeasonsPage({
  payload,
}: ReturnType<typeof actions.onChangeSeasonsPage>) {
  const {
    seasonsList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.seasons);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getSeasonsRequest(cloneFilters));
}
function* createSeason({
  payload,
}: ReturnType<typeof actions.createSeasonRequest>) {
  const season: CreateSeason = {
    endAt: payload.endAt,
    startAt: payload.startAt,
  };
  try {
    yield call(API.post, '/v1/admin/tier/seasons', season);
    yield put(actions.createSeasonSuccess());
    yield put(
      addAlert({
        message: 'ສ້າງລະດູການສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.getSeasonsRequest({}));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createSeasonFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateSeason({
  payload,
}: ReturnType<typeof actions.updateSeasonRequest>) {
  const { update }: actions.InitialState = yield select(
    (state) => state.superAdmin.seasons
  );
  const season = {
    endAt: payload.endAt,
    startAt: payload.startAt,
    id: update.season?.id || '',
  };
  try {
    yield call(API.post, `/v1/admin/tier/seasons-update`, season);
    yield put(actions.updateSeasonSuccess(payload));
    yield put(
      addAlert({
        message: 'ອັບເດດລະດູການສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateSeasonFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetSeasons() {
  yield takeLatest(actions.Types.getSeasonsRequest, getSeasons);
}
function* watchOnChangeSeasonsPage() {
  yield takeLatest(actions.Types.onChangeSeasonsPage, onChangeSeasonsPage);
}
function* watchOnChangeSeasonsPageSize() {
  yield takeLatest(
    actions.Types.onChangeSeasonsPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeSeasonsPageSize>) {
      yield put(actions.getSeasonsRequest(payload));
    }
  );
}
function* watchCreateSeason() {
  yield takeLatest(actions.Types.createSeasonRequest, createSeason);
}
function* watchUpdateSeason() {
  yield takeLatest(actions.Types.updateSeasonRequest, updateSeason);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetSeasons),
    fork(watchOnChangeSeasonsPage),
    fork(watchOnChangeSeasonsPageSize),
    fork(watchCreateSeason),
    fork(watchUpdateSeason),
  ]);
}
export default saga;
