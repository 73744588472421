import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  SelectOption,
  QualityCheckFilter,
  QualityCheckForm,
  QualityCheckList,
} from 'models';

export interface InitialState {
  qualityCheck: {
    data: QualityCheckList[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  form: {
    isCreating: boolean;
    isLoading: boolean;
    error: string | null;
    products: SelectOption[];
    isAfterSubmitSuccess: boolean;
  };
}
const initialState: InitialState = {
  qualityCheck: {
    error: null,
    isLoading: false,
    data: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  form: {
    isCreating: false,
    isLoading: false,
    error: null,
    products: [],
    isAfterSubmitSuccess: false,
  },
};

export enum Types {
  cancelRequestAPI = 'tier/purchases@cancelRequestAPI',
  getQualityCheckRequest = 'tier/purchases@getQualityCheckRequest',
  getQualityCheckSuccess = 'tier/purchases@getQualityCheckSuccess',
  getQualityCheckFailure = 'tier/qualityCheck@getQualityCheckFailure',
  qualityCheckOnChangePageSize = 'tier/qualityCheck@QualityCheckOnChangePageSize',
  qualityCheckOnChangePage = 'tier/qualityCheck@QualityCheckOnChangePage',
  createQualityCheckRequest = 'tier/qualityCheck@createPurchaseRequest',
  createQualityCheckSuccess = 'tier/qualityCheck@createQualityCheckSuccess',
  createQualityCheckFailure = 'tier/qualityCheck@createQualityCheckFailure',

  afterSubmitSuccess = 'tier/qualityCheck@afterSubmitSuccess',

  getProductsRequest = 'tier/qualityCheck@getProductsRequest',
  getProductsSuccess = 'tier/qualityCheck@getProductsSuccess',
  getProductsFailure = 'tier/qualityCheck@getProductsFailure',
}

export type GetQualityCheckSuccess = DefaultQueries & {
  data: QualityCheckList[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);

export const getQualityCheckRequest = createAction<QualityCheckFilter>(
  Types.getQualityCheckRequest
);
export const getQualityCheckSuccess = createAction<
  GetQualityCheckSuccess,
  Types.getQualityCheckSuccess
>(Types.getQualityCheckSuccess);
export const getQualityCheckFailure = createAction<
  string | null,
  Types.getQualityCheckFailure
>(Types.getQualityCheckFailure);
export const qualityCheckOnChangePageSize = createAction<
  QualityCheckFilter,
  Types.qualityCheckOnChangePageSize
>(Types.qualityCheckOnChangePageSize);
export const qualityCheckOnChangePage = createAction<
  QualityCheckFilter,
  Types.qualityCheckOnChangePage
>(Types.qualityCheckOnChangePage);
export const createQualityCheckRequest = createAction<QualityCheckForm>(
  Types.createQualityCheckRequest
);
export const createQualityCheckSuccess = createAction(
  Types.createQualityCheckSuccess
);
export const createQualityCheckFailure = createAction<string | null>(
  Types.createQualityCheckFailure
);

export const afterSubmitSuccess = createAction(Types.afterSubmitSuccess);
export const getProductsRequest = createAction(Types.getProductsRequest);
export const getProductsSuccess = createAction<SelectOption[]>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string | null>(
  Types.getProductsFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.form.isCreating = false;
    state.form.isLoading = false;
    state.qualityCheck.isLoading = false;
  });
  builder.addCase(getQualityCheckRequest, (state) => {
    state.qualityCheck.isLoading = true;
    state.qualityCheck.error = null;
  });
  builder.addCase(getQualityCheckSuccess, (state, action) => {
    return {
      ...state,
      qualityCheck: {
        ...state.qualityCheck,
        isLoading: false,
        error: null,
        ...action.payload,
      },
    };
  });
  builder.addCase(getQualityCheckFailure, (state, action) => {
    return {
      ...state,
      qualityCheck: {
        ...state.qualityCheck,
        isLoading: false,
        error: action.payload,
      },
    };
  });
  builder.addCase(createQualityCheckRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(createQualityCheckSuccess, (state) => {
    state.form.isCreating = false;
    state.form.isAfterSubmitSuccess = true;
  });
  builder.addCase(createQualityCheckFailure, (state, action) => {
    return {
      ...state,
      form: { ...state.form, isCreating: false, error: action.payload },
    };
  });
  builder.addCase(afterSubmitSuccess, (state) => {
    state.form.isAfterSubmitSuccess = false;
  });
  builder.addCase(getProductsRequest, (state) => {
    state.form.isLoading = true;
    state.form.error = null;
  });
  builder.addCase(getProductsSuccess, (state, action) => {
    state.form.isLoading = false;
    state.form.products = action.payload;
  });
  builder.addCase(getProductsFailure, (state, action) => {
    state.form.isLoading = false;
    state.form.error = action.payload;
  });
});

export default reducer;
