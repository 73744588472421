import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ButtonProps {
  to: string;
}
const ButtonLink: FC<Props> = ({
  children,
  to,
  sx,
  variant,
  disabled,
  color,
}) => {
  return (
    <Button
      component={Link}
      to={to}
      sx={sx}
      variant={variant}
      disabled={disabled}
      color={color}
    >
      {children}
    </Button>
  );
};

export default ButtonLink;
