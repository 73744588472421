import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  PurchasesQueries,
  PurchasesTransaction,
  DataPurchases,
  DataPurchasesTable,
  PurchasesSummaryColumn,
} from 'models/superAdminReport';
export interface InitialState {
  purchases: {
    entities: DataPurchasesTable<PurchasesSummaryColumn>;
    timelines: number[];
    isLoading: boolean;
    error: string | null;
    summaries: DataPurchases<PurchasesSummaryColumn>;
  };
  purchasesTransactions: {
    entities: PurchasesTransaction[];
    summary: PurchasesSummaryColumn;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    pageTokens: string;
  };
}
const initialState: InitialState = {
  purchases: {
    entities: {},
    timelines: [],
    error: null,
    isLoading: false,
    summaries: {
      impurity: '',
      moisturePercent: '',
      number: '',
      product: {
        name: '',
        title: '',
      },
      price: 0,
      tierPrice: 0,
      province: '',
      starchPercent: '',
      summaries: {
        amount: 0,
        count: 0,
        price: 0,
        weight: 0,
        tierPrice: 0,
      },
      tier: {
        name: '',
        title: '',
      },
      zone: {
        name: '',
        title: '',
      },
    },
  },
  purchasesTransactions: {
    entities: [],
    error: null,
    isLoading: false,
    pageTokens: '',
    isExporting: false,
    summary: {
      amount: 0,
      count: 0,
      price: 0,
      weight: 0,
      tierPrice: 0,
    },
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/report-purchases@cancelRequestAPI',
  getSummariesPurchasesRequest = 'super-admin/report-purchases@getSummariesPurchasesRequest',
  getSummariesPurchasesSuccess = 'super-admin/report-purchases@getSummariesPurchasesSuccess',
  getSummariesPurchasesFailure = 'super-admin/report-purchases@getSummariesPurchasesFailure',
  getPurchasesTransactionsRequest = 'super-admin/report-purchases@getPurchasesTransactionsRequest',
  getPurchasesTransactionsSuccess = 'super-admin/report-purchases@getPurchasesTransactionsSuccess',
  getPurchasesTransactionsFailure = 'super-admin/report-purchases@getPurchasesTransactionsFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getSummariesPurchasesRequest = createAction<PurchasesQueries>(
  Types.getSummariesPurchasesRequest
);
export const getSummariesPurchasesSuccess = createAction<{
  entities: DataPurchasesTable<PurchasesSummaryColumn>;
  timelines: number[];
  summaries: DataPurchases<PurchasesSummaryColumn>;
}>(Types.getSummariesPurchasesSuccess);
export const getSummariesPurchasesFailure = createAction(
  Types.getSummariesPurchasesFailure
);
export const getPurchasesTransactionsRequest = createAction<PurchasesQueries>(
  Types.getPurchasesTransactionsRequest
);
export const getPurchasesTransactionsSuccess = createAction<{
  entities: PurchasesTransaction[];
  summary: PurchasesSummaryColumn;
  pageTokens: string;
}>(Types.getPurchasesTransactionsSuccess);
export const getPurchasesTransactionsFailure = createAction(
  Types.getPurchasesTransactionsFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSummariesPurchasesRequest, (state) => {
      state.purchases.isLoading = true;
    })
    .addCase(getSummariesPurchasesSuccess, (state, { payload }) => {
      state.purchases.isLoading = false;
      state.purchases.entities = payload.entities;
      state.purchases.timelines = payload.timelines;
      state.purchases.summaries = payload.summaries;
    })
    .addCase(getSummariesPurchasesFailure, (state) => {
      state.purchases.isLoading = false;
    });
  builder
    .addCase(getPurchasesTransactionsRequest, (state) => {
      state.purchasesTransactions.isLoading = true;
    })
    .addCase(getPurchasesTransactionsSuccess, (state, { payload }) => {
      state.purchasesTransactions.isLoading = false;
      state.purchasesTransactions.entities = payload.entities;
      state.purchasesTransactions.pageTokens = payload.pageTokens;
      state.purchasesTransactions.summary = payload.summary;
    })
    .addCase(getPurchasesTransactionsFailure, (state) => {
      state.purchasesTransactions.isLoading = false;
    });
});

export default reducer;
