import { createReducer, createAction } from '@reduxjs/toolkit';
import { CompanyForm, CompanyProfile } from 'models/company';

type DialogID = null | 'update';

export interface InitialState {
  company: {
    isLoading: boolean;
    data: CompanyProfile;
  };
  dialogID: DialogID;
  form: {
    error: string | null;
    isUpdating: boolean;
    isSuccess: boolean;
  };
}
const initialState: InitialState = {
  company: {
    isLoading: false,
    data: {
      address: {
        district: '',
        province: '',
        village: '',
      },
      contact: {
        emailAddresses: [],
        phoneNumbers: [],
      },
      createdAt: '',
      defaultAccounts: [],
      id: '',
      name: '',
      title: 'AIDC',
      updatedAt: '',
    },
  },
  dialogID: null,
  form: {
    error: null,
    isUpdating: false,
    isSuccess: false,
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/company@cancelRequestAPI',
  onChangeDialogID = 'super-admin/company@onChangeDialogID',
  getCompanyRequest = 'super-admin/company@getCompanyRequest',
  getCompanySuccess = 'super-admin/company@getCompanySuccess',
  getCompanyFailure = 'super-admin/company@getCompanyFailure',
  updateCompanyRequest = 'super-admin/company@updateCompanyRequest',
  updateCompanySuccess = 'super-admin/company@updateCompanySuccess',
  updateCompanyFailure = 'super-admin/company@updateCompanyFailure',
  afterCreateSuccess = 'super-admin/company@afterCreateSuccess',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getCompanyRequest = createAction(Types.getCompanyRequest);
export const getCompanySuccess = createAction<
  CompanyProfile,
  Types.getCompanySuccess
>(Types.getCompanySuccess);
export const getCompanyFailure = createAction<string, Types.getCompanyFailure>(
  Types.getCompanyFailure
);
export const updateCompanyRequest = createAction<CompanyForm>(
  Types.updateCompanyRequest
);
export const updateCompanySuccess = createAction<CompanyForm>(
  Types.updateCompanySuccess
);
export const updateCompanyFailure = createAction<string>(
  Types.updateCompanyFailure
);
export const afterCreateSuccess = createAction(Types.afterCreateSuccess);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.company.isLoading = false;
    state.form.error = null;
    state.form.isUpdating = false;
    state.dialogID = null;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.dialogID = action.payload;
  });
  builder.addCase(getCompanyRequest, (state) => {
    state.company.isLoading = true;
  });
  builder.addCase(getCompanySuccess, (state, action) => {
    return {
      ...state,
      company: {
        ...state.company,
        data: {
          ...action.payload,
        },
        isLoading: false,
      },
    };
  });
  builder.addCase(getCompanyFailure, (state) => {
    state.company.isLoading = false;
  });
  builder.addCase(updateCompanyRequest, (state) => {
    state.form.error = null;
    state.form.isUpdating = true;
  });
  builder.addCase(updateCompanySuccess, (state, { payload }) => {
    state.form.isUpdating = false;
    state.form.isSuccess = true;
    state.dialogID = null;
    state.company.data = {
      ...state.company.data,
      ...payload,
    };
  });
  builder.addCase(updateCompanyFailure, (state, action) => {
    state.form.isUpdating = false;
    state.form.error = action.payload;
  });
  builder.addCase(afterCreateSuccess, (state) => {
    state.form.isSuccess = false;
  });
});

export default reducer;
