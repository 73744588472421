import { createSlice, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { PurchasesQueries, Purchase } from 'models/Repository';
export interface InitialState {
  entities: Purchase[];
  isLoading: boolean;
  isExporting: boolean;
  error: string | null;
  pageTokens: string[];
  page: number;
  pageSize: number;
}
const initialState: InitialState = {
  entities: [],
  error: null,
  isLoading: false,
  pageTokens: [],
  isExporting: false,
  page: 1,
  pageSize: 25,
};
export enum Types {
  cancelRequest = 'superAdmin/reportPurchases/transactions/cancelRequest',
  getPurchasesRequest = 'superAdmin/reportPurchases/transactions/getPurchasesRequest',
  getPurchasesSuccess = 'superAdmin/reportPurchases/transactions/getPurchasesSuccess',
  getPurchasesFailure = 'superAdmin/reportPurchases/transactions/getPurchasesFailure',
  onChangePage = 'superAdmin/reportPurchases/transactions/onChangePage',
  onChangePageSize = 'superAdmin/reportPurchases/transactions/onChangePageSize',
}
const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    cancelRequest(state) {
      state = {
        ...state,
        isLoading: false,
        isExporting: false,
        error: null,
      };
    },
    getPurchasesRequest(state, _: PayloadAction<PurchasesQueries>) {
      state.isLoading = true;
      state.error = null;
    },
    getPurchasesSuccess(
      state,
      { payload }: PayloadAction<OptionalOne<InitialState, keyof InitialState>>
    ) {
      return {
        ...state,
        isLoading: false,
        ...payload,
      };
    },
    getPurchasesFailure(state) {
      state.isLoading = false;
    },
  },
});
export const onChangePage = createAction<PurchasesQueries>(Types.onChangePage);
export const onChangePageSize = createAction<PurchasesQueries>(
  Types.onChangePageSize
);
export const {
  cancelRequest,
  getPurchasesFailure,
  getPurchasesRequest,
  getPurchasesSuccess,
} = slice.actions;
export default slice.reducer;
