import { all, fork } from 'redux-saga/effects';
import exchangeRates from './exchange-rate/saga';
import roles from './roles/saga';
import users from './users/saga';
import seasons from './seasons/saga';
import products from './products/saga';
import customers from './customers/saga';
import wallets from './wallets/saga';
import zones from './zones/saga';
import expenseTypes from './expense-types/saga';
import activities from './activities/saga';
import company from './company/saga';
import productPrices from './product-price/saga';
import report from './report/saga';
import dashboard from './dashboard/saga';
import predict from './predict/saga';
import reportPurchases from './report-purchases/saga';

export default function* sagas() {
  yield all([
    fork(exchangeRates),
    fork(roles),
    fork(users),
    fork(seasons),
    fork(products),
    fork(customers),
    fork(wallets),
    fork(zones),
    fork(expenseTypes),
    fork(activities),
    fork(company),
    fork(productPrices),
    fork(report),
    fork(dashboard),
    fork(predict),
    fork(reportPurchases),
  ]);
}
