import { createReducer, createAction } from '@reduxjs/toolkit';
import { LotsQueries, Lot, SummariesLots } from 'models/dashboard';

type SetLotsData = {
  entities: Lot[];
  summaries: SummariesLots;
};
export interface InitialState {
  lots: SetLotsData & {
    isLoading: boolean;
    error: string | null;
  };
}
const initialState: InitialState = {
  lots: {
    entities: [],
    error: null,
    isLoading: false,
    summaries: {
      summaries: {
        weight: '0',
        count: 0,
        title: 'ມັນທັງໝົດ',
        price: '0',
      },
      'dry-cassava': {
        weight: '0',
        count: 0,
        title: 'ມັນແຫ້ງ',
        price: '0',
      },
      'fresh-cassava': {
        weight: '0',
        count: 0,
        title: 'ມັນຫົວ',
        price: '0',
      },
      'transform': {
        title: 'ມັນແປຮູບ',
        weight: '0',
        count: 0,
        price: '0',
      },
    },
  },
};

export enum Types {
  cancelRequestAPI = 'super-admin/dashboard@cancelRequestAPI',
  getLotsRequest = 'super-admin/dashboard@getLotsRequest',
  getLotsSuccess = 'super-admin/dashboard@getLotsSuccess',
  getLotsFailure = 'super-admin/dashboard@getLotsFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getLotsRequest = createAction<LotsQueries>(Types.getLotsRequest);
export const getLotsSuccess = createAction<SetLotsData>(Types.getLotsSuccess);
export const getLotsFailure = createAction<string>(Types.getLotsFailure);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    return { ...state, isLoading: false, isCreating: false };
  });
  builder
    .addCase(getLotsRequest, (state) => {
      state.lots.isLoading = true;
      state.lots.error = null;
    })
    .addCase(getLotsSuccess, (state, { payload }) => {
      return {
        ...state,
        lots: {
          ...state.lots,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getLotsFailure, (state, { payload }) => {
      state.lots.isLoading = false;
      state.lots.error = payload;
    });
});

export default reducer;
