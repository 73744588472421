import { all, fork, put, call, takeLeading } from 'redux-saga/effects';
import API from 'services/defaultInstance';
import * as actions from './index';
import { GetProductsResponse } from 'models';
// import { findErrorToData } from 'utils';
const getProductsAPI = () => API.get('/v1/admin/products');

function* getProducts() {
  try {
    const { data }: GetProductsResponse = yield call(getProductsAPI);
    yield put(actions.getProductsSuccess(data.products));
  } catch (error: any) {
    yield put(actions.getProductsFailure('error'));
  }
}

function* watchGetProducts() {
  yield takeLeading(actions.Types.getProductsRequest, getProducts);
}
function* saga() {
  yield all([fork(watchGetProducts)]);
}
export default saga;
