import moment from 'moment';
import type {
  DataSalesTable,
  SummariesSaleQualityCheck,
  SaleSummaryColumn,
  APIPurchasesSummaries,
  SummaryStock,
  StockSummariesColumn,
  DataPurchasesTable,
  PurchasesSummaryColumn,
} from 'models/superAdminReport';
import type { Sale as SalesSummaries } from 'models/dashboard';
export type ExportDataTableProps = {
  data: SalesSummaries[];
  mode: 'company' | 'zone' | 'tier';
};
export const exportDataTable = ({
  data,
  mode,
}: ExportDataTableProps): {
  data: DataSalesTable<SaleSummaryColumn>;
  timelines: number[];
} => {
  const timelines: number[] = [];
  const sales = data.reduce(
    (entries, sale) => {
      let valueKey = '';
      switch (mode) {
        case 'company':
          valueKey = `${sale.zone.name}-${sale.tier.name}-${sale.product.name}`;
          break;
        case 'zone':
          valueKey = `${sale.zone.name}-${sale.tier.name}-${sale.product.name}`;
          break;
        default:
          valueKey = `${sale.tier.name}`;
      }
      timelines.push(sale.timeline.value);
      const getValue = entries[valueKey] ?? {
        zone: sale.zone,
        tier: sale.tier,
        province: sale.province,
        id: '',
        number: '',
        product: sale.product,
        summaries: {
          count: 0,
          amount: 0,
          weight: 0,
          price: 0,
        },
      };
      const getTimelineInValue = getValue[sale.timeline.value] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
      };
      const getSummaryValue = getValue['summaries'] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
      };
      const getRowSummaries = entries['summaries'];
      const getRowSummariesTimeline = getRowSummaries[sale.timeline.value] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
      };
      const weight = Number.parseFloat(sale.weight);
      const price = Number.parseFloat(sale.price);
      const amount = Number.parseFloat(sale.revenue.amount);
      return {
        ...entries,
        [valueKey]: {
          ...getValue,
          [sale.timeline.value]: {
            amount: getTimelineInValue.amount + amount,
            weight: getTimelineInValue.weight + weight,
            price: getTimelineInValue.price + price,
            count: getTimelineInValue.count + sale.count || 1,
          },
          summaries: {
            amount: getSummaryValue.amount + amount,
            weight: getSummaryValue.weight + weight,
            price: getSummaryValue.price + price,
            count: getSummaryValue.count + sale.count || 1,
          },
        },
        summaries: {
          ...getRowSummaries,
          [sale.timeline.value]: {
            amount: getRowSummariesTimeline.amount + amount,
            weight: getRowSummariesTimeline.weight + weight,
            price: getRowSummariesTimeline.price + price,
            count: getRowSummariesTimeline.count + sale.count || 1,
          },
          summaries: {
            amount: getRowSummaries.summaries.amount + amount,
            weight: getRowSummaries.summaries.weight + weight,
            price: getRowSummaries.summaries.price + price,
            count: getRowSummaries.summaries.count + sale.count || 1,
          },
        },
      };
    },
    {
      summaries: {
        impurity: '',
        moisturePercent: '',
        number: '',
        province: '',
        starchPercent: '',
        tier: {
          name: '',
          title: '',
        },
        zone: {
          name: '',
          title: '',
        },
        product: {
          name: '',
          title: '',
        },
        summaries: {
          amount: 0,
          count: 0,
          price: 0,
          weight: 0,
        },
      },
    } as DataSalesTable<SaleSummaryColumn>
  );
  const month = timelines.reduce((month, timeline) => {
    const value = month.find((m) => m === timeline);
    if (!value) {
      return [...month, timeline];
    }
    return month;
  }, [] as number[]);
  return {
    data: sales,
    timelines: month.sort((a, b) => a - b),
  };
};

//
export type ExportDataQualityCheckProps = {
  data: SummariesSaleQualityCheck[];
};
export const exportSaleQualityCheckTable = ({
  data,
}: ExportDataQualityCheckProps): {
  data: DataSalesTable<SaleSummaryColumn>;
  timelines: number[];
} => {
  const timelines: number[] = [];
  const sales = data.reduce(
    (entries, sale) => {
      let valueKey = `${sale.zone.name}-${sale.tier.name}-${sale.product.name}-${sale.moisturePercent}-${sale.starchPercent}`;
      timelines.push(sale.timeline.value);
      const getValue = entries[valueKey] ?? {
        zone: sale.zone,
        tier: sale.tier,
        province: sale.province,
        id: '',
        number: '',
        product: sale.product,
        summaries: {
          count: 0,
          amount: 0,
          weight: 0,
          price: 0,
        },
        impurity: '',
        moisturePercent: sale.moisturePercent,
        starchPercent: sale.moisturePercent,
      };
      const getTimelineInValue = getValue[sale.timeline.value] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
      };
      const getSummaryValue = getValue['summaries'] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
      };
      const getRowSummaries = entries['summaries'];
      const getRowSummariesTimeline = getRowSummaries[sale.timeline.value] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
      };
      const weight = Number.parseFloat(sale.dstWeight);
      const price = Number.parseFloat(sale.dstPrice.amount);
      const amount = Number.parseFloat(sale.dstPrice.amount);
      return {
        ...entries,
        [valueKey]: {
          ...getValue,
          [sale.timeline.value]: {
            amount: getTimelineInValue.amount + amount,
            weight: getTimelineInValue.weight + weight,
            price: getTimelineInValue.price + price,
            count: getTimelineInValue.count + sale.count || 1,
          },
          summaries: {
            amount: getSummaryValue.amount + amount,
            weight: getSummaryValue.weight + weight,
            price: getSummaryValue.price + price,
            count: getSummaryValue.count + sale.count || 1,
          },
        },
        summaries: {
          ...getRowSummaries,
          [sale.timeline.value]: {
            amount: getRowSummariesTimeline.amount + amount,
            weight: getRowSummariesTimeline.weight + weight,
            price: getRowSummariesTimeline.price + price,
            count: getRowSummariesTimeline.count + sale.count || 1,
          },
          summaries: {
            amount: getRowSummaries.summaries.amount + amount,
            weight: getRowSummaries.summaries.weight + weight,
            price: getRowSummaries.summaries.price + price,
            count: getRowSummaries.summaries.count + sale.count || 1,
          },
        },
      };
    },
    {
      summaries: {
        impurity: '',
        moisturePercent: '',
        number: '',
        province: '',
        starchPercent: '',
        tier: {
          name: '',
          title: '',
        },
        zone: {
          name: '',
          title: '',
        },
        product: {
          name: '',
          title: '',
        },
        summaries: {
          amount: 0,
          count: 0,
          price: 0,
          weight: 0,
        },
      },
    } as DataSalesTable<SaleSummaryColumn>
  );
  const month = timelines.reduce((month, timeline) => {
    const value = month.find((m) => m === timeline);
    if (!value) {
      return [...month, timeline];
    }
    return month;
  }, [] as number[]);
  return {
    data: sales,
    timelines: month.sort((a, b) => a - b),
  };
};

export const exportPurchasesTable = (
  data: APIPurchasesSummaries[]
): {
  data: DataPurchasesTable<PurchasesSummaryColumn>;
  timelines: number[];
} => {
  const timelines: number[] = [];
  const entitiesData = data.reduce(
    (entries, item) => {
      let valueKey = `${item.zone.name}-${item.tier.name}-${item.product.name}-${item.moisturePercent}-${item.starchPercent}`;
      const weight = Number.parseFloat(item.weight);
      const price = Number.parseFloat(item.price.amount);
      const amount = Number.parseFloat(item.amount || '0');
      const tierPrice = Number.parseFloat(item.tierPurchasePrice.amount || '0');
      timelines.push(item.timeline.value);
      const getValue = entries[valueKey] ?? {
        zone: item.zone,
        tier: item.tier,
        province: item.province,
        id: '',
        number: '',
        product: item.product,
        summaries: {
          count: 0,
          amount: 0,
          weight: 0,
          price: 0,
          tierPrice: 0,
        },
        impurity: '',
        moisturePercent: item.moisturePercent,
        starchPercent: item.starchPercent,
        tierPrice: tierPrice,
        price: price,
      };
      const getTimelineInValue = getValue[item.timeline.value] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
        tierPrice: 0,
      };
      const getSummaryValue = getValue['summaries'] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
        tierPrice: 0,
      };
      const getRowSummaries = entries['summaries'];
      const getRowSummariesTimeline = getRowSummaries[item.timeline.value] ?? {
        amount: 0,
        weight: 0,
        price: 0,
        count: 0,
        tierPrice: 0,
      };

      return {
        ...entries,
        [valueKey]: {
          ...getValue,
          [item.timeline.value]: {
            amount: getTimelineInValue.amount + amount,
            weight: getTimelineInValue.weight + weight,
            price: getTimelineInValue.price + price,
            count: getTimelineInValue.count + item.count || 1,
            tierPrice: getTimelineInValue.tierPrice + tierPrice,
          },
          summaries: {
            amount: getSummaryValue.amount + amount,
            weight: getSummaryValue.weight + weight,
            price: getSummaryValue.price + price,
            count: getSummaryValue.count + item.count || 1,
            tierPrice: getSummaryValue.tierPrice + tierPrice,
          },
        },
        summaries: {
          ...getRowSummaries,
          [item.timeline.value]: {
            amount: getRowSummariesTimeline.amount + amount,
            weight: getRowSummariesTimeline.weight + weight,
            price: getRowSummariesTimeline.price + price,
            count: getRowSummariesTimeline.count + item.count || 1,
            tierPrice: getRowSummariesTimeline.tierPrice + tierPrice,
          },
          summaries: {
            amount: getRowSummaries.summaries.amount + amount,
            weight: getRowSummaries.summaries.weight + weight,
            price: getRowSummaries.summaries.price + price,
            count: getRowSummaries.summaries.count + item.count || 1,
            tierPrice: getRowSummaries.summaries.tierPrice + tierPrice,
          },
        },
      };
    },
    {
      summaries: {
        impurity: '',
        moisturePercent: '',
        number: '',
        province: '',
        starchPercent: '',
        tier: {
          name: '',
          title: '',
        },
        price: 0,
        tierPrice: 0,
        zone: {
          name: '',
          title: '',
        },
        product: {
          name: '',
          title: '',
        },
        summaries: {
          amount: 0,
          count: 0,
          price: 0,
          weight: 0,
          tierPrice: 0,
        },
      },
    } as DataPurchasesTable<PurchasesSummaryColumn>
  );
  const month = timelines.reduce((month, timeline) => {
    const value = month.find((m) => m === timeline);
    if (!value) {
      return [...month, timeline];
    }
    return month;
  }, [] as number[]);
  return {
    data: entitiesData,
    timelines: month.sort((a, b) => a - b),
  };
};

export const exportStocksTable = (
  data: SummaryStock[]
): {
  data: StockSummariesColumn;
} => {
  const entitiesData = data.reduce(
    (entries, item) => {
      const timelineValue = moment(item.closeAt).format(`M`) || '1';
      let valueKey = `${item.zone.name}-${item.tier.name}-${item.product.name}-${timelineValue}`;

      const getValue = entries[valueKey] ?? {
        zone: item.zone,
        tier: item.tier,
        province: item.province,
        id: '',
        number: '',
        product: item.product,
        actualCost: 0,
        weight: 0,
        price: 0,
        count: 0,
        paidCost: 0,
        impurity: '',
        moisturePercent: '',
        starchPercent: '',
        closeAt: timelineValue,
      };

      const getRowSummaries = entries['summaries'];

      const weight = Number.parseFloat(item.allVolumeKg);
      const price = Number.parseFloat(item.price.amount);
      const actualCost = Number.parseFloat(item.actualCost.amount);
      const paidCost = Number.parseFloat(item.paidCost.amount);
      return {
        ...entries,
        [valueKey]: {
          ...getValue,
          actualCost: getValue.actualCost + actualCost,
          paidCost: getValue.paidCost + paidCost,
          weight: getValue.weight + weight,
          price: getValue.price + price,
          count: getValue.count + 1,
        },
        summaries: {
          ...getRowSummaries,
          actualCost: getRowSummaries.actualCost + actualCost,
          paidCost: getRowSummaries.paidCost + paidCost,
          weight: getRowSummaries.weight + weight,
          price: getRowSummaries.price + price,
          count: getRowSummaries.count + 1,
        },
      };
    },
    {
      summaries: {
        actualCost: 0,
        closeAt: '',
        count: 0,
        paidCost: 0,
        price: 0,
        product: {
          name: '',
          title: '',
        },
        province: '',
        tier: {
          name: '',
          title: '',
        },
        weight: 0,
        zone: {
          name: '',
          title: '',
        },
      },
    } as StockSummariesColumn
  );

  return {
    data: entitiesData,
  };
};
