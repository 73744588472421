import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';

import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData, setPageInfo } from 'utils';
import type { PurchasesQueries } from 'models/purchase/Repository';
import type { GetPurchasesReportResponse } from 'models/purchase/DataSource';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];

function* getPurchases({
  payload,
}: ReturnType<typeof actions.getPurchasesRequest>) {
  const { transactions } = yield select(
    (state: RootState) => state.superAdmin.reportPurchases
  );
  try {
    const {
      startedAt,
      endedAt,
      season,
      customer,
      page,
      pageSize,
      isFirstRender,
      ...payloadParams
    } = payload;
    const cancelToken = CancelToken.source();
    cancels.push(cancelToken.cancel);
    let params: any = {
      ...payloadParams,
      pageSize: pageSize || transactions.pageSize,
      product: 'dry-cassava',
    };

    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetPurchasesReportResponse = yield call(
      API.get,
      `/v1/purchase/report/items`,
      {
        cancelToken: cancelToken.token,
        params: params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: transactions.page,
        pageSize: transactions.pageSize,
        pageTokens: transactions.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    yield put(
      actions.getPurchasesSuccess({
        entities: data.purchase,
        ...(isFirstRender
          ? {
              pageSize: params.pageSize,
              page: 1,
              pageTokens:
                data.nextPageToken.length === 0 ? [] : [data.nextPageToken],
            }
          : pageInfo),
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getPurchasesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangePage({ payload }: ReturnType<typeof actions.onChangePage>) {
  const {
    transactions: { page, pageTokens },
  } = yield select((state: RootState) => state.superAdmin.reportPurchases);
  const { nextOrPrev, ...payloadParams } = payload;
  let params: PurchasesQueries = {
    ...payloadParams,
  };
  const prevPage = nextOrPrev && nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getPurchasesRequest(params));
}
function* onChangePageSize({
  payload,
}: ReturnType<typeof actions.onChangePageSize>) {
  const {
    transactions: { pageSize },
  } = yield select((state: RootState) => state.superAdmin.reportPurchases);
  if (payload.pageSize !== pageSize) {
    yield put(actions.getPurchasesRequest({ ...payload }));
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequest, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}

function* watchGetPurchases() {
  yield takeLatest(actions.getPurchasesRequest, getPurchases);
}
function* watchOnChangePage() {
  yield takeLatest(actions.Types.onChangePage, onChangePage);
}
function* watchOnChangePageSize() {
  yield takeLatest(actions.Types.onChangePageSize, onChangePageSize);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetPurchases),
    fork(watchOnChangePage),
    fork(watchOnChangePageSize),
  ]);
}
export default saga;
