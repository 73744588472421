import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  AccountsFilters,
  SummariesAccount,
  AccountTransactionsFilters,
  AccountTransaction,
} from 'models/superAdminReport';
import type { DefaultQueries, Tier, Zone } from 'models';

type AccountsQueries = AccountsFilters & {};
type AccountTransactionsQueries = DefaultQueries &
  AccountTransactionsFilters & {};
export interface InitialState {
  accounts: {
    entities: SummariesAccount[];
    isLoading: boolean;
    error: string | null;
  };
  transactions: {
    entities: AccountTransaction[];
    account?: SummariesAccount;
    isLoading: boolean;
    isExporting: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  tiers: {
    entities: Tier[];
    isLoading: boolean;
  };
  zones: {
    entities: Zone[];
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  accounts: {
    entities: [],
    isLoading: false,
    error: null,
  },
  transactions: {
    entities: [],
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    isExporting: false,
  },
  tiers: {
    entities: [],
    isLoading: false,
  },
  zones: {
    entities: [],
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/report-wallets@cancelRequestAPI',
  getAccountsRequest = 'super-admin/report-wallets@getAccountsRequest',
  getAccountsSuccess = 'super-admin/report-wallets@getAccountsSuccess',
  getAccountsFailure = 'super-admin/report-wallets@getAccountsFailure',
  getTransactionsRequest = 'super-admin/report-wallets@getTransactionsRequest',
  getTransactionsSuccess = 'super-admin/report-wallets@getTransactionsSuccess',
  getTransactionsFailure = 'super-admin/report-wallets@getTransactionsFailure',
  onChangeTransactionsPage = 'super-admin/report-wallets@onChangeTransactionsPage',
  onChangeTransactionsPageSize = 'super-admin/report-wallets@onChangeTransactionsPageSize',
  exportTransactionsCsvRequest = 'super-admin/report-wallets@exportTransactionsCsvRequest',
  exportTransactionsCsvSuccess = 'super-admin/report-wallets@exportTransactionsCsvSuccess',
  exportTransactionsCsvFailure = 'super-admin/report-wallets@exportTransactionsCsvFailure',
  getTiersRequest = 'super-admin/report-wallets@getTiersRequest',
  getTiersSuccess = 'super-admin/report-wallets@getTiersSuccess',
  getTiersFailure = 'super-admin/report-wallets@getTiersFailure',
  getZonesRequest = 'super-admin/report-wallets@getZonesRequest',
  getZonesSuccess = 'super-admin/report-wallets@getZonesSuccess',
  getZonesFailure = 'super-admin/report-wallets@getZonesFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getTiersRequest = createAction(Types.getTiersRequest);
export const getTiersSuccess = createAction<Tier[]>(Types.getTiersSuccess);
export const getTiersFailure = createAction(Types.getTiersFailure);
export const getZonesRequest = createAction(Types.getZonesRequest);
export const getZonesSuccess = createAction<Zone[]>(Types.getZonesSuccess);
export const getZonesFailure = createAction(Types.getZonesFailure);
export const getAccountsRequest = createAction<AccountsQueries>(
  Types.getAccountsRequest
);
export const getAccountsSuccess = createAction<SummariesAccount[]>(
  Types.getAccountsSuccess
);
export const getAccountsFailure = createAction<string>(
  Types.getAccountsFailure
);

export const getTransactionsRequest = createAction<AccountTransactionsQueries>(
  Types.getTransactionsRequest
);
export const getTransactionsSuccess = createAction<
  DefaultQueries & {
    entities: AccountTransaction[];
    account?: SummariesAccount;
  }
>(Types.getTransactionsSuccess);
export const getTransactionsFailure = createAction<string>(
  Types.getTransactionsFailure
);
export const onChangeTransactionsPage =
  createAction<AccountTransactionsQueries>(Types.onChangeTransactionsPage);
export const onChangeTransactionsPageSize =
  createAction<AccountTransactionsQueries>(Types.onChangeTransactionsPageSize);
export const exportTransactionsCsvRequest =
  createAction<AccountTransactionsQueries>(Types.exportTransactionsCsvRequest);
export const exportTransactionsCsvSuccess = createAction(
  Types.exportTransactionsCsvSuccess
);
export const exportTransactionsCsvFailure = createAction(
  Types.exportTransactionsCsvFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAccountsRequest, (state) => {
      state.accounts.isLoading = true;
      state.accounts.error = null;
    })
    .addCase(getAccountsSuccess, (state, { payload }) => {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          entities: payload,
          isLoading: false,
        },
      };
    })
    .addCase(getAccountsFailure, (state, action) => {
      state.accounts.isLoading = false;
      state.accounts.error = action.payload;
    });

  builder
    .addCase(getTransactionsRequest, (state) => {
      state.transactions.isLoading = true;
      state.transactions.error = null;
    })
    .addCase(getTransactionsSuccess, (state, { payload }) => {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
          ...payload,
        },
      };
    })
    .addCase(getTransactionsFailure, (state, action) => {
      state.transactions.isLoading = false;
      state.transactions.error = action.payload;
    });
  builder
    .addCase(exportTransactionsCsvRequest, (state) => {
      state.transactions.isExporting = true;
    })
    .addCase(exportTransactionsCsvSuccess, (state) => {
      state.transactions.isExporting = false;
    })
    .addCase(exportTransactionsCsvFailure, (state) => {
      state.transactions.isExporting = false;
    });
  builder
    .addCase(getTiersRequest, (state) => {
      state.tiers.isLoading = true;
    })
    .addCase(getTiersSuccess, (state, { payload }) => {
      state.tiers = {
        entities: payload,
        isLoading: false,
      };
    })
    .addCase(getTiersFailure, (state) => {
      state.zones.isLoading = false;
    });
  builder
    .addCase(getZonesRequest, (state) => {
      state.zones.isLoading = true;
    })
    .addCase(getZonesSuccess, (state, { payload }) => {
      state.zones = {
        entities: payload,
        isLoading: false,
      };
    })
    .addCase(getZonesFailure, (state) => {
      state.zones.isLoading = false;
    });
});

export default reducer;
