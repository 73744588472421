import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import {
  findErrorToData,
  joinParamsToStringHttpRequest,
  setPageInfo,
  prevOrNextCursorPage,
  getAllDataWithCursorNextPage,
} from 'utils';
import { APISummariesAccount, SummariesAccount } from 'models/superAdminReport';
import { Tier } from 'models/tier';
import { Zone } from 'models/zone';

let cancels: Canceler[] = [];

function* getAccounts({
  payload,
}: ReturnType<typeof actions.getAccountsRequest>) {
  try {
    const entries: APISummariesAccount[] = yield getAllDataWithCursorNextPage<
      APISummariesAccount[],
      'summaryTransactions'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/wallet/report/summary-transactions${queries}`, {
          params: { ...payload },
        }),
      keyResponse: 'summaryTransactions',
    });
    const data: SummariesAccount[] = entries.map((entry) => ({
      tier: entry.tier,
      title: entry.title,
      currency: entry.currency,
      accountNumber: entry.accountNumber,
      zone: entry.zone,
      province: entry.province,
      type: entry.type === 'CASH' ? 'ເງິນສົດ' : 'ເງິນໂອນ',
      balance: Number.parseFloat(entry.balance),
      income: Number.parseFloat(entry.income),
      expense: Number.parseFloat(entry.expense),
    }));
    yield put(actions.getAccountsSuccess(data));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getAccountsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* Transactions({
  payload,
}: ReturnType<typeof actions.getTransactionsRequest>) {
  const getAccount: APISummariesAccount = JSON.parse(payload.account || '');
  if (typeof getAccount !== 'object') {
    yield put(
      actions.getTransactionsFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
      })
    );
    return;
  }
  // TODO : skip verify account
  // if (Object.keys(getAccount).length !== 2) {
  //   yield put(
  //     actions.getTransactionsFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
  //   );
  //   yield put(
  //     addAlert({
  //       message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
  //       serviceType: 'snackbar',
  //       type: 'error',
  //     })
  //   );
  //   return;
  // }
  const { transactions }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.wallets
  );
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || transactions.pageSize,
      },
      keysParams: ['pageToken', 'pageSize', 'tier', 'startedAt', 'endedAt'],
    });
    const { data } = yield call(
      API.get,
      `/v1/wallet/report/transactions?${queries}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const page = setPageInfo(
      {
        page: transactions.page,
        pageSize: transactions.pageSize,
        pageTokens: transactions.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken || '',
      }
    );
    yield put(
      actions.getTransactionsSuccess({
        ...page,
        entities: data.transactions,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTransactionsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeTransactionsPage({
  payload,
}: ReturnType<typeof actions.onChangeTransactionsPage>) {
  const { transactions }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.wallets
  );

  const pageInfo = prevOrNextCursorPage({
    nextOrPrev: payload.nextOrPrev === 'prev' ? 'prev' : 'next',
    page: transactions.page,
    pageTokens: transactions.pageTokens,
  });

  yield put(actions.getTransactionsRequest({ ...payload, ...pageInfo }));
}
function* exportExpensesCsv({
  payload,
}: ReturnType<typeof actions.exportTransactionsCsvRequest>) {
  const { transactions }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.wallets
  );
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || transactions.pageSize,
      },
      keysParams: ['tier', 'startedAt', 'endedAt'],
    });
    const { data } = yield call(
      API.post,
      `/v1/sale/invoices-exportCsv`,
      queries
    );
    const blob = new Blob([data], {
      type: 'text/plain;charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍງານການອອກໃບຮຽກເກັບເງິນ ${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportTransactionsCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportTransactionsCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetAccountsRequest() {
  yield takeLatest(actions.Types.getAccountsRequest, getAccounts);
}
function* watchGetTransactions() {
  yield takeLatest(actions.Types.getTransactionsRequest, Transactions);
}
function* watchOnChangeTransactionsPage() {
  yield takeLatest(
    actions.Types.onChangeTransactionsPage,
    onChangeTransactionsPage
  );
}
function* watchOnChangeTransactionsPageSize() {
  yield takeLatest(
    actions.Types.onChangeTransactionsPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeTransactionsPageSize>) {
      yield put(actions.getTransactionsRequest({ ...payload }));
    }
  );
}
function* getTiers() {
  try {
    const tiers: Tier[] = yield getAllDataWithCursorNextPage<Tier[], 'tiers'>({
      httpRequest: (queries) => API.get(`/v1/admin/tier/tiers${queries}`),
      keyResponse: 'tiers',
    });
    yield put(actions.getTiersSuccess(tiers));
  } catch (error) {
    yield put(actions.getTiersFailure());
  }
}
function* getZones() {
  try {
    const zones: Zone[] = yield getAllDataWithCursorNextPage<Zone[], 'zones'>({
      httpRequest: (queries) => API.get(`/v1/admin/tier/zones${queries}`),
      keyResponse: 'zones',
    });
    yield put(actions.getZonesSuccess(zones));
  } catch (error) {
    yield put(actions.getZonesFailure());
  }
}
function* watchExportExpensesCsv() {
  yield takeLatest(
    actions.Types.exportTransactionsCsvRequest,
    exportExpensesCsv
  );
}
function* watchGetTiers() {
  yield takeLatest(actions.Types.getTiersRequest, getTiers);
}
function* watchGetZones() {
  yield takeLatest(actions.Types.getZonesRequest, getZones);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetAccountsRequest),
    fork(watchGetTransactions),
    fork(watchOnChangeTransactionsPage),
    fork(watchOnChangeTransactionsPageSize),
    fork(watchExportExpensesCsv),
    fork(watchGetTiers),
    fork(watchGetZones),
  ]);
}
export default saga;
