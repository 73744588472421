import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  takeLeading,
  delay,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import {
  CreateCustomer,
  GetCustomersResponse,
  GetCustomerResponse,
  UpdateCustomer,
} from 'models';
import { findErrorToData } from 'utils';
import moment from 'moment';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];
function* getCustomers({
  payload,
}: ReturnType<typeof actions.getCustomersRequest>) {
  const {
    auth,
    customers: {
      customersList: { pageTokens, pageSize },
    },
  }: RootState = yield select((state) => state);
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.createdAt) {
    query += `&startAt=${encodeURIComponent(
      moment(payload.createdAt).format('YYYY-MM-DDTHH:mm:ssZ')
    )})}`;
  }
  let dataPayload: actions.GetCustomersSuccess = {
    customers: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetCustomersResponse = yield call(
      API.get,
      `/v1/tiers/${auth.accept.tier?.name || ''}/customers?${query}`,
      {
        cancelToken: new CancelToken((c) => cancels.push(c)),
      }
    );
    dataPayload.customers = data.customers;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    cancels = [];
    yield put(actions.getCustomersSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getCustomersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeCustomersPage({
  payload,
}: ReturnType<typeof actions.onChangeCustomersPage>) {
  const {
    customersList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.customers);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getCustomersRequest(cloneFilters));
}
function* watchCreateCustomer() {
  yield takeLeading(
    actions.Types.createCustomerRequest,
    function* ({ payload }: ReturnType<typeof actions.createCustomerRequest>) {
      const {
        auth,
        router: { location },
      }: RootState = yield select((state: RootState) => state);
      try {
        const customer: CreateCustomer = {
          tier: auth.accept.tier?.name || '',
          contact: {
            displayName: payload.contactDisplayName || '',
            emailAddresses:
              (payload.emailAddresses[0].value || '').length === 0
                ? []
                : payload.emailAddresses.map((e) => ({
                    ...e,
                    value: e.value.toLowerCase(),
                  })),
            phoneNumbers: payload.phoneNumbers
              ? payload.phoneNumbers.split(',')
              : [],
          },
          address: {
            district: payload.address.district || '',
            province: payload.address.province || '',
            village: '',
          },
          displayName: payload.displayName,
          id: payload.id,
          national: payload.national || '',
        };
        yield call(API.post, `/v1/tiers/customers`, customer);
        yield delay(300);
        yield put(actions.createCustomerSuccess());
        yield put(
          addAlert({
            message: 'ເພີ່ມລູກຄ້າສຳເລັດ',
            serviceType: 'snackbar',
            type: 'success',
          })
        );
        yield put(
          push({
            pathname: (location?.pathname || '').replace('/create', ''),
          })
        );
      } catch (error: any) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.createCustomerFailure(errorData?.message || ''));
        if (errorData && errorData.serviceType === 'snackbar') {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* getCustomer({
  payload,
}: ReturnType<typeof actions.getCustomerRequest>) {
  const {
    auth,
    customers: {
      customersList: { customers },
    },
  }: RootState = yield select((state) => state);
  try {
    const customer = customers.find((customer) => customer.id === payload);
    if (customer) {
      cancels = [];
      yield put(actions.getCustomerSuccess(customer));
      return;
    }
    const { data }: GetCustomerResponse = yield call(
      API.get,
      `/v1/tiers/${auth.accept.tier?.name || ''}/customers/${payload}`,
      {
        cancelToken: new CancelToken((c) => cancels.push(c)),
      }
    );
    cancels = [];
    yield put(actions.getCustomerSuccess(data.customer));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getCustomerFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateCustomer({
  payload,
}: ReturnType<typeof actions.createCustomerRequest>) {
  const {
    router: { location },
  }: RootState = yield select((state: RootState) => state);
  try {
    const customer: UpdateCustomer = {
      contact: {
        displayName: payload.contactDisplayName || '',
        emailAddresses:
          (payload.emailAddresses[0].value || '').length === 0
            ? []
            : payload.emailAddresses.map((e) => ({
                ...e,
                value: e.value.toLowerCase(),
              })),
        phoneNumbers: payload.phoneNumbers
          ? payload.phoneNumbers.split(',')
          : [],
      },
      address: {
        district: payload.address.district || '',
        province: payload.address.province || '',
        village: '',
      },
      displayName: payload.displayName,
      id: payload.id,
      national: payload.national || '',
    };
    yield call(API.post, `/v1/tiers/customers-update`, customer);
    yield delay(300);
    yield put(actions.updateCustomerSuccess());
    yield put(
      addAlert({
        message: 'ແກ້ໄຂລູກຄ້າສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(
      push({
        pathname: (location?.pathname || '').replace(
          `/update/${customer.id}`,
          ''
        ),
        search: location?.search,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateCustomerFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetCustomers() {
  yield takeLatest(actions.Types.getCustomersRequest, getCustomers);
}
function* watchGetCustomer() {
  yield takeLatest(actions.Types.getCustomerRequest, getCustomer);
}
function* watchUpdateCustomer() {
  yield takeLeading(actions.Types.updateCustomerRequest, updateCustomer);
}
function* watchOnChangeCustomersPage() {
  yield takeLatest(actions.Types.onChangeCustomersPage, onChangeCustomersPage);
}
function* watchOnChangeCustomersPageSize() {
  yield takeLatest(
    actions.Types.onChangeCustomersPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeCustomersPageSize>) {
      yield put(actions.getCustomersRequest(payload));
    }
  );
}

function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetCustomers),
    fork(watchOnChangeCustomersPage),
    fork(watchOnChangeCustomersPageSize),
    fork(watchCreateCustomer),
    fork(watchGetCustomer),
    fork(watchUpdateCustomer),
  ]);
}
export default saga;
