import React from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { history } from 'store';

interface FirstRenderEffect {
  effect: () => void;
  destructor?: () => void;
}
export const useFirstRenderEffect = ({
  effect,
  destructor,
}: FirstRenderEffect) => {
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  React.useEffect(() => {
    setIsFirstRender(false);
    if (!isFirstRender) {
      effect();
    }
    return () => {
      setIsFirstRender(true);
      if (destructor) {
        destructor();
      }
    };
  }, [destructor, effect, isFirstRender]);
  return {
    isFirstRender,
  };
};
export const listenRouteLocationChange = history.listen;

export const blockRouteLocationChange = (fn: () => void) =>
  history.block(() => fn());
export const useHistoryListenEffect = (effect: React.EffectCallback) => {
  React.useEffect(() => {
    const unListen = history.listen(() => {
      effect();
    });
    return () => {
      unListen();
    };
  }, [effect]);
};
type HistoryBlockEffect = {
  loading: boolean;
  message?: string;
};
type Unblock = null | (() => void);
export const useHistoryBlockEffect = ({
  loading,
  message,
}: HistoryBlockEffect) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  React.useEffect(() => {
    let unblock: Unblock = null;
    if (loading) {
      unblock = history.block(() => {
        enqueueSnackbar(message || 'ກະລຸນາລໍຖ້າ.....', {
          variant: 'warning',
          action: (snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <CloseIcon color="inherit" sx={{ color: 'white' }} />
            </IconButton>
          ),
        });
      });
    }
    return () => {
      if (unblock && loading) {
        unblock();
        unblock = null;
      }
    };
  }, [closeSnackbar, enqueueSnackbar, loading, message]);
};
export const useIsFirstRender = (): boolean => {
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsFirstRender(true);
    return () => {
      setIsFirstRender(false);
    };
  }, []);

  return isFirstRender;
};
