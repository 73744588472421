import {
  all,
  fork,
  put,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { GetActivitiesResponse } from 'models';
import { findErrorToData } from 'utils';
import { addAlert } from 'store/notify';
let cancels: Canceler[] = [];

function* getActivities({
  payload,
}: ReturnType<typeof actions.getActivitiesRequest>) {
  const {
    activitiesList: { pageTokens, pageSize },
  }: actions.InitialState = yield select(
    (state) => state.superAdmin.activities
  );
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }

  let dataPayload: actions.GetActivitiesSuccess = {
    activities: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetActivitiesResponse = yield call(
      API.get,
      `/v1/admin/activity/activities?${query}`
    );
    dataPayload.activities = data.activities;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getActivitiesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getActivitiesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeActivitiesPage({
  payload,
}: ReturnType<typeof actions.onChangeActivitiesPage>) {
  const {
    activitiesList: { pageTokens, page },
  }: actions.InitialState = yield select(
    (state) => state.superAdmin.activities
  );
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getActivitiesRequest(cloneFilters));
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetActivities() {
  yield takeLeading(actions.Types.getActivitiesRequest, getActivities);
}
function* watchOnChangeActivitiesPage() {
  yield takeLatest(
    actions.Types.onChangeActivitiesPage,
    onChangeActivitiesPage
  );
}
function* watchOnChangeActivitiesPageSize() {
  yield takeLatest(
    actions.Types.onChangeActivitiesPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeActivitiesPageSize>) {
      yield put(actions.getActivitiesRequest(payload));
    }
  );
}

function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetActivities),
    fork(watchOnChangeActivitiesPage),
    fork(watchOnChangeActivitiesPageSize),
  ]);
}
export default saga;
