import {
  all,
  fork,
  put,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { GetZonesResponse, CreateZone, UpdateZone } from 'models';
import { findErrorToData, provinces } from 'utils';
import { addAlert } from 'store/notify';
let cancels: Canceler[] = [];

function* getZones({ payload }: ReturnType<typeof actions.getZonesRequest>) {
  const {
    zonesList: { pageTokens, pageSize },
  }: actions.InitialState = yield select((state) => state.superAdmin.zones);
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }

  let dataPayload: actions.GetZonesSuccess = {
    zones: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetZonesResponse = yield call(
      API.get,
      `/v1/admin/tier/zones?${query}`
    );
    dataPayload.zones = data.zones;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getZonesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getZonesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeZonesPage({
  payload,
}: ReturnType<typeof actions.onChangeZonesPage>) {
  const {
    zonesList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.zones);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getZonesRequest(cloneFilters));
}
function* createZone({
  payload,
}: ReturnType<typeof actions.createZoneRequest>) {
  const zone: CreateZone = {
    name: payload.name,
    id: payload.id,
    title: payload.title,
    provinces: payload.provinces.map((name) => {
      const province = provinces.find((p) => p.label === name);
      return {
        name: province?.nameEn || '',
        title: province?.label || '',
      };
    }),
  };
  try {
    yield call(API.post, `/v1/admin/tier/zones`, zone, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.createZoneSuccess());
    cancels = [];
    yield put(actions.getZonesRequest({}));
    yield put(
      addAlert({
        message: 'ສ້າງໂຊນສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createZoneFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateZone({
  payload,
}: ReturnType<typeof actions.updateZoneRequest>) {
  const zone: UpdateZone = {
    name: payload.name,
    title: payload.title,
    provinces: payload.provinces.map((name) => {
      const province = provinces.find((p) => p.label === name);
      return {
        name: province?.nameEn || '',
        title: province?.label || '',
      };
    }),
  };
  try {
    yield call(API.post, `/v1/admin/tier/zones-update`, zone, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.updateZoneSuccess());
    cancels = [];
    yield put(actions.getZonesRequest({}));
    yield put(
      addAlert({
        message: 'ແກ້ໄຂໂຊນສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateZoneFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetZones() {
  yield takeLeading(actions.Types.getZonesRequest, getZones);
}
function* watchOnChangeZonesPage() {
  yield takeLatest(actions.Types.onChangeZonesPage, onChangeZonesPage);
}
function* watchOnChangeZonesPageSize() {
  yield takeLatest(
    actions.Types.onChangeZonesPageSize,
    function* ({ payload }: ReturnType<typeof actions.onChangeZonesPageSize>) {
      yield put(actions.getZonesRequest(payload));
    }
  );
}
function* watchCreateZone() {
  yield takeLeading(actions.Types.createZoneRequest, createZone);
}
function* watchUpdateZone() {
  yield takeLeading(actions.Types.updateZoneRequest, updateZone);
}

function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetZones),
    fork(watchCreateZone),
    fork(watchOnChangeZonesPage),
    fork(watchOnChangeZonesPageSize),
    fork(watchUpdateZone),
  ]);
}
export default saga;
