import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  Season,
  CreateSeasonForm,
  SeasonsFilter,
  DefaultQueries,
} from 'models';
export type DialogId = 'createSeason' | null | 'editSeason';
export interface InitialState {
  seasonsList: {
    seasons: Season[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  create: {
    isCreating: boolean;
    error: string | null;
  };
  dialogId: DialogId;
  update: {
    isUpdating: boolean;
    error: string | null;
    season?: Season;
  };
}
const initialState: InitialState = {
  seasonsList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    seasons: [],
  },
  create: {
    isCreating: false,
    error: null,
  },
  dialogId: null,
  update: {
    isUpdating: false,
    error: null,
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/seasons@cancelRequestAPI',
  getSeasonsRequest = 'super-admin/seasons@getSeasonsRequest',
  getSeasonsSuccess = 'super-admin/seasons@getSeasonsSuccess',
  getSeasonsFailure = 'super-admin/seasons@getSeasonsFailure',
  onChangeSeasonsPage = 'super-admin/seasons@onChangeSeasonsPage',
  onChangeSeasonsPageSize = 'super-admin/seasons@onChangeSeasonsPageSize',
  createSeasonRequest = 'super-admin/seasons@createSeasonRequest',
  createSeasonSuccess = 'super-admin/seasons@createSeasonSuccess',
  createSeasonFailure = 'super-admin/seasons@createSeasonFailure',
  onChangeDialogId = 'super-admin/seasons@onChangeDialogId',
  setSeasonToUpdate = 'super-admin/seasons@setSeasonToUpdate',
  updateSeasonRequest = 'super-admin/seasons@updateSeasonRequest',
  updateSeasonSuccess = 'super-admin/seasons@updateSeasonSuccess',
  updateSeasonFailure = 'super-admin/seasons@updateSeasonFailure',
}
export type GetSeasonsSuccess = DefaultQueries & {
  seasons: Season[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getSeasonsRequest = createAction<SeasonsFilter>(
  Types.getSeasonsRequest
);
export const getSeasonsSuccess = createAction<GetSeasonsSuccess>(
  Types.getSeasonsSuccess
);
export const getSeasonsFailure = createAction<string>(Types.getSeasonsFailure);
export const onChangeSeasonsPage = createAction<SeasonsFilter>(
  Types.onChangeSeasonsPage
);
export const onChangeSeasonsPageSize = createAction<SeasonsFilter>(
  Types.onChangeSeasonsPageSize
);
export const createSeasonRequest = createAction<CreateSeasonForm>(
  Types.createSeasonRequest
);
export const createSeasonSuccess = createAction(Types.createSeasonSuccess);
export const createSeasonFailure = createAction<string>(
  Types.createSeasonFailure
);
export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);
export const setSeasonToUpdate = createAction<Season>(Types.setSeasonToUpdate);
export const updateSeasonRequest = createAction<CreateSeasonForm>(
  Types.updateSeasonRequest
);
export const updateSeasonSuccess = createAction<CreateSeasonForm>(
  Types.updateSeasonSuccess
);
export const updateSeasonFailure = createAction<string>(
  Types.updateSeasonFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(getSeasonsRequest, (state) => {
      state.seasonsList.isLoading = true;
      state.seasonsList.error = null;
    })
    .addCase(getSeasonsSuccess, (state, { payload }) => {
      return {
        ...state,
        seasonsList: {
          ...state.seasonsList,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getSeasonsFailure, (state, { payload }) => {
      state.seasonsList.isLoading = false;
      state.seasonsList.error = payload;
    })
    .addCase(createSeasonRequest, (state) => {
      state.create.isCreating = true;
      state.create.error = null;
    })
    .addCase(createSeasonSuccess, (state) => {
      state.create.isCreating = false;
      state.dialogId = null;
    })
    .addCase(createSeasonFailure, (state, { payload }) => {
      state.create.isCreating = false;
      state.create.error = payload;
    })
    .addCase(onChangeDialogId, (state, { payload }) => {
      state.dialogId = payload;
    })
    .addCase(setSeasonToUpdate, (state, { payload }) => {
      state.update.season = payload;
      state.dialogId = 'editSeason';
    })
    .addCase(updateSeasonRequest, (state) => {
      state.update.isUpdating = true;
      state.update.error = null;
    })
    .addCase(updateSeasonSuccess, (state, { payload }) => {
      state.update.isUpdating = false;
      state.dialogId = null;
      state.seasonsList.seasons = state.seasonsList.seasons.map((season) => {
        if (season.id === state.update.season?.id) {
          return {
            ...state.update.season,
            endAt: payload.endAt,
            startAt: payload.startAt,
          };
        }
        return season;
      });
    })
    .addCase(updateSeasonFailure, (state, { payload }) => {
      state.update.isUpdating = false;
      state.update.error = payload;
    });
});

export default reducer;
