import { Dialog, DialogContent, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
});

const SuspenseLoading = () => {
  return (
    <Dialog fullScreen open={true}>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: {
              md: '80px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Img src="/company.png" alt="logo192" />
          <CircularProgress sx={{ mt: 3 }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SuspenseLoading;
