import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetSummariesPurchasesResponse,
  SummariesPurchasesQueries,
  GetSummaryPurchasesResponse,
  Purchase,
  GetPurchasesResponse,
  SummaryPurchase,
  DefaultQueries,
} from 'models';
import {
  findErrorToData,
  joinParamsToStringHttpRequest,
  mappingParamsToPayload,
  setPageInfo,
} from 'utils';
import { RootState } from 'store/index';
import moment from 'moment';

let cancels: Canceler[] = [];

const getSummariesPurchasesAPI = (query: string) =>
  API.get(`/v1/purchase/transactions-summaries?${query}`);
const getSummaryPurchasesAPI = (transactionId: string) =>
  API.get(`/v1/purchase/transactions-summaries/${transactionId}`);
const getPurchasesAPI = (query: string) =>
  API.get(`/v1/purchase/transactions?${query}`);

type GetSummariesPurchasesRequest = ReturnType<
  typeof actions.getSummariesPurchasesRequest
>;
function* getSummariesPurchases({ payload }: GetSummariesPurchasesRequest) {
  const {
    auth: { accept },
    tierPurchasesSummaries: { summariesPurchases },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || summariesPurchases.pageSize,
        tier: accept.tier?.name || '',
      },
      keysParams: ['pageToken', 'purchasedAt', 'pageSize', 'tier'],
    });
    const { data }: GetSummariesPurchasesResponse = yield call(
      getSummariesPurchasesAPI,
      queries
    );
    const page = setPageInfo(
      {
        page: summariesPurchases.page,
        pageSize: summariesPurchases.pageSize,
        pageTokens: summariesPurchases.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetSummariesPurchasesSuccess = {
      ...page,
      summaries: data.summaries,
    };
    yield put(actions.getSummariesPurchasesSuccess(dataPayload));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.getSummariesPurchasesFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type SummariesPurchasesOnChangePage = ReturnType<
  typeof actions.summariesPurchasesOnChangePage
>;
function* summariesPurchasesOnChangePage({
  payload,
}: SummariesPurchasesOnChangePage) {
  const {
    summariesPurchases: { pageTokens, page },
  }: actions.InitialState = yield select(
    (state) => state.tierPurchasesSummaries
  );
  let filter: SummariesPurchasesQueries & DefaultQueries = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  filter.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    filter.pageToken = pageToken;
  }
  yield put(actions.getSummariesPurchasesRequest(filter));
}
type SummariesPurchasesOnChangePageSize = ReturnType<
  typeof actions.summariesPurchasesOnChangePageSize
>;
function* summariesPurchasesOnChangePageSize({
  payload,
}: SummariesPurchasesOnChangePageSize) {
  yield put(actions.getSummariesPurchasesRequest(payload));
}
function* getSummaryPurchases(transactionId: string) {
  try {
    const { data }: GetSummaryPurchasesResponse = yield call(
      getSummaryPurchasesAPI,
      transactionId
    );
    yield put(actions.getSummaryPurchaseSuccess(data.summary));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.viewSummaryPurchaseFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
const getPurchasesAll = async (query: string) => {
  let purchases: Purchase[] = [];
  let nextPageToken = '';

  let error: null | Error | string = 'ERROR';
  do {
    try {
      let queries = query;
      if (nextPageToken !== '') {
        queries = queries + `&pageToken=${nextPageToken}`;
      }
      const { data }: GetPurchasesResponse = await getPurchasesAPI(queries);
      purchases = purchases.concat(data.transactions);
      nextPageToken = data.nextPageToken;
      queries = query;
    } catch (err: any) {
      error = err;
      nextPageToken = '';
    }
  } while (nextPageToken !== '');
  if (typeof error === 'string' && error === 'ERROR') {
    return purchases;
  }
  return error;
};
function* getPurchases(purchases: SummaryPurchase) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  let query = `tier=${
    accept.tier?.name || ''
  }&pageSize=250&createdAt=${encodeURIComponent(purchases.createdAt)}&status=${
    purchases.status
  }&product=${purchases.product.name}`;
  try {
    const purchases: Purchase[] = yield call(getPurchasesAll, query);
    yield put(actions.getPurchasesSuccess(purchases));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.viewSummaryPurchaseFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
    tierPurchasesSummaries: { summariesPurchases },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield mappingParamsToPayload({
      values: {
        ...payload,
        pageSize: payload.pageSize || summariesPurchases.pageSize,
        tier: accept.tier?.name || '',
      },
      keysParams: ['purchasedAt', 'tier'],
    });
    const { data } = yield call(
      API.post,
      `/v1/purchase/transactions-summaries-exportCsv`,
      queries
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍການຍອດສັ່ງຊື້ສິນຄ້າ_${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type ViewSummaryPurchaseRequest = ReturnType<
  typeof actions.viewSummaryPurchaseRequest
>;
type GetSummaryPurchaseSuccess = ReturnType<
  typeof actions.getSummaryPurchaseSuccess
>;
function* getDataViewSummaryPurchases({ payload }: ViewSummaryPurchaseRequest) {
  yield fork(getSummaryPurchases, payload);
  const setSummaryPurchase: GetSummaryPurchaseSuccess = yield take(
    actions.Types.getSummaryPurchaseSuccess
  );
  yield fork(getPurchases, setSummaryPurchase.payload);
  yield take(actions.Types.getPurchasesSuccess);
  yield put(actions.viewSummaryPurchaseSuccess());
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetSummariesPurchasesRequest() {
  yield takeLatest(
    actions.Types.getSummariesPurchasesRequest,
    getSummariesPurchases
  );
}
function* watchSummariesPurchasesOnChangePage() {
  yield takeLatest(
    actions.Types.summariesPurchasesOnChangePage,
    summariesPurchasesOnChangePage
  );
}
function* watchSummariesPurchasesOnChangePageSize() {
  yield takeLatest(
    actions.Types.summariesPurchasesOnChangePageSize,
    summariesPurchasesOnChangePageSize
  );
}
function* watchGetDataViewSummaryPurchases() {
  yield takeLatest(
    actions.Types.viewSummaryPurchaseRequest,
    getDataViewSummaryPurchases
  );
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetSummariesPurchasesRequest),
    fork(watchSummariesPurchasesOnChangePage),
    fork(watchSummariesPurchasesOnChangePageSize),
    fork(watchGetDataViewSummaryPurchases),
    fork(watchExportCsv),
  ]);
}
export default saga;
