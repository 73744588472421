import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { GetProductPricesResponse } from 'models';
import { findErrorToData, setPageInfo } from 'utils';
import { RootState } from 'store/index';
let cancels: Canceler[] = [];

function* getProductPricesToday() {
  const { auth }: RootState = yield select((state) => state);
  try {
    const { data }: GetProductPricesResponse = yield call(
      API.get,
      `/v1/zones/${
        auth.accept.profile?.zone || ''
      }/purchasePrices?status=PENDING&pageSize=250&createdAt=${encodeURIComponent(
        moment().format('YYYY-MM-DDTHH:mm:ssZ')
      )}`
    );
    yield put(actions.getProductPricesToDaySuccess(data.purchasePrices));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductPricesToDayFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type GetProductPricesRequest = ReturnType<
  typeof actions.getProductPricesRequest
>;
function* getProductPrices({ payload }: GetProductPricesRequest) {
  const {
    auth,
    admin: { productPrice },
  }: RootState = yield select((state) => state);
  const { prices } = productPrice;
  let query = `?pageSize=${
    payload.pageSize ? payload.pageSize : prices.pageSize
  }`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.createdAt) {
    query += `&createdAt=${encodeURIComponent(payload.createdAt)}`;
  }
  if (payload.status) {
    query += `&status=${payload.status}`;
  }

  try {
    const { data }: GetProductPricesResponse = yield call(
      API.get,
      `/v1/zones/${auth.accept.profile?.zone || ''}/purchasePrices${query}`
    );
    const page = setPageInfo(
      {
        page: productPrice.prices.page,
        pageSize: productPrice.prices.pageSize,
        pageTokens: productPrice.prices.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetProductPricesSuccess = {
      ...page,
      prices: data.purchasePrices,
    };
    yield put(actions.getProductPricesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductPricesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type ProductPricesOnChangePage = ReturnType<
  typeof actions.productPricesOnChangePage
>;
function* productPricesOnChangePage({ payload }: ProductPricesOnChangePage) {
  const {
    prices: { page, pageTokens },
  }: actions.InitialState = yield select((state) => state.admin.productPrice);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getProductPricesRequest(cloneFilters));
}
type StatementOnChangePageSize = ReturnType<
  typeof actions.productPricesOnChangePageSize
>;
function* productPricesOnChangePageSize({
  payload,
}: StatementOnChangePageSize) {
  yield put(actions.getProductPricesRequest(payload));
}

function* approveProductPrice() {
  const { form }: actions.InitialState = yield select(
    (state) => state.admin.productPrice
  );
  try {
    yield call(API.post, `/v1/zones/purchasePrices-approve`, {
      id: form.price?.id || '',
    });
    yield put(actions.approveProductPriceSuccess());
    yield put(
      addAlert({
        message: 'ອະນຸມັດລາຄາສິນຄ້າສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.getProductPricesToDayRequest());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.approveProductPriceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* rejectProductPrice({
  payload,
}: ReturnType<typeof actions.rejectProductPriceRequest>) {
  const { form }: actions.InitialState = yield select(
    (state) => state.admin.productPrice
  );
  try {
    yield call(API.post, `/v1/zones/purchasePrices-reject`, {
      id: form.price?.id || '',
      description: payload.description,
    });
    yield put(actions.rejectProductPriceSuccess());
    yield put(
      addAlert({
        message: 'ອະນຸມັດລາຄາສິນຄ້າສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.getProductPricesToDayRequest());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.rejectProductPriceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetProductPricesToday() {
  yield takeLatest(
    actions.Types.getProductPricesToDayRequest,
    getProductPricesToday
  );
}
function* watchGetProductPrices() {
  yield takeLatest(actions.Types.getProductPricesRequest, getProductPrices);
}
function* watchProductPricesOnChangePage() {
  yield takeLatest(
    actions.Types.productPricesOnChangePage,
    productPricesOnChangePage
  );
}
function* watchProductPricesOnChangePageSize() {
  yield takeLatest(
    actions.Types.productPricesOnChangePageSize,
    productPricesOnChangePageSize
  );
}
function* watchApproveProductPrice() {
  yield takeLatest(
    actions.Types.approveProductPriceRequest,
    approveProductPrice
  );
}
function* watchRejectProductPrice() {
  yield takeLatest(actions.Types.rejectProductPriceRequest, rejectProductPrice);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetProductPricesToday),
    fork(watchGetProductPrices),
    fork(watchProductPricesOnChangePage),
    fork(watchProductPricesOnChangePageSize),
    fork(watchApproveProductPrice),
    fork(watchRejectProductPrice),
  ]);
}
export default saga;
