import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  CreateSaleTransferForm,
  SaleTransfer,
  SaleTransfersQueries,
  WalletAccount,
  SaleTransferUploadForm,
} from 'models';

export type DialogId =
  | null
  | 'createTransfer'
  | 'viewTransfer'
  | 'updateTransfer'
  | 'updateStatus';
type Queries = DefaultQueries & SaleTransfersQueries & {};

export interface InitialState {
  dialogId: DialogId;
  transfersList: {
    data: SaleTransfer[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  transferDetail: {
    isLoading: boolean;
    error: string | null;
    transfer: SaleTransfer | null;
  };
  transferCreate: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
    isSuccessful: boolean;
  };
  walletAccounts: {
    isLoading: boolean;
    error: string | null;
    accounts: WalletAccount[];
  };
  upload: {
    isCreating: boolean;
    isSubmitError: boolean;
    error: string | null;
    referenceId: string;
  };
  transferUpdate: {
    isUpdating: boolean;
    isSubmitted: boolean;
    error: string | null;
    transfer: SaleTransfer | null;
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  dialogId: null,
  transfersList: {
    data: [],
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  transferDetail: {
    isLoading: false,
    error: null,
    transfer: null,
  },
  transferCreate: {
    error: null,
    isCreating: false,
    isLoading: false,
    isSuccessful: false,
  },
  walletAccounts: {
    isLoading: false,
    error: null,
    accounts: [],
  },
  upload: {
    isCreating: false,
    isSubmitError: false,
    error: null,
    referenceId: '',
  },
  transferUpdate: {
    error: null,
    isSubmitted: false,
    isUpdating: false,
    transfer: null,
  },
  exportData: {
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/sale-transfers@cancelRequestAPI',
  onChangeDialog = 'tier/sale-transfers@onChangeDialog',
  getTransfersRequest = 'tier/sale-transfers@getTransfersRequest',
  getTransfersSuccess = 'tier/sale-transfers@getTransfersSuccess',
  getTransfersFailure = 'tier/sale-transfers@getTransfersFailure',
  onChangeTransfersListPage = 'tier/sale-transfers@onChangeTransfersListPage',
  onChangeTransfersListPageSize = 'tier/sale-transfers@onChangeTransfersListPageSize',
  createTransferRequest = 'tier/sale-transfers@createTransferRequest',
  createTransferSuccess = 'tier/sale-transfers@createTransferSuccess',
  createTransferFailure = 'tier/sale-transfers@createTransferFailure',
  getWalletsRequest = 'tier/sale-transfers@getWalletsRequest',
  getWalletSuccess = 'tier/sale-transfers@getWalletSuccess',
  getWalletsFailure = 'tier/sale-transfers@getWalletsFailure',
  openCreateTransferDialog = 'tier/sale-transfers@openCreateTransferDialog',
  afterIsSuccessful = 'tier/sale-transfers@afterIsSuccessful',
  uploadRequest = 'tier/sale-transfers@uploadRequest',
  uploadSuccess = 'tier/sale-transfers@uploadSuccess',
  uploadFailure = 'tier/sale-transfers@uploadFailure',
  afterUploadFailure = 'tier/sale-transfers@afterUploadFailure',
  getTransferRequest = 'tier/sale-transfers@getTransferRequest',
  getTransferSuccess = 'tier/sale-transfers@getTransferSuccess',
  getTransferFailure = 'tier/sale-transfers@getTransferFailure',
  updateTransferRequest = 'tier/sale-transfers@updateTransferRequest',
  updateTransferSuccess = 'tier/sale-transfers@updateTransferSuccess',
  updateTransferFailure = 'tier/sale-transfers@updateTransferFailure',
  afterUpdateSuccessful = 'tier/sale-transfers@afterUpdateSuccessful',
  setTransfer = 'tier/sale-transfers@setTransfer',
  updateStatusTransferRequest = 'tier/sale-transfers@updateStatusTransferRequest',
  updateStatusTransferSuccess = 'tier/sale-transfers@updateStatusTransferSuccess',
  updateStatusTransferFailure = 'tier/sale-transfers@updateStatusTransferFailure',
  setTransferUpdateStatus = 'tier/sale-transfers@setTransferUpdateStatus',
  exportCsvRequest = 'tier/sale-transfers@exportCsvRequest',
  exportCsvSuccess = 'tier/sale-transfers@exportCsvSuccess',
  exportCsvFailure = 'tier/sale-transfers@exportCsvFailure',
}
export interface GetTransfersSuccess extends Queries {
  data: SaleTransfer[];
}
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialog = createAction<DialogId>(Types.onChangeDialog);
export const getTransfersRequest = createAction<Queries>(
  Types.getTransfersRequest
);
export const getTransfersSuccess = createAction<GetTransfersSuccess>(
  Types.getTransfersSuccess
);
export const getTransfersFailure = createAction<string>(
  Types.getTransfersFailure
);
export const onChangeTransfersListPage = createAction<Queries>(
  Types.onChangeTransfersListPage
);
export const onChangeTransfersListPageSize = createAction<Queries>(
  Types.onChangeTransfersListPageSize
);
export const createTransferRequest = createAction<CreateSaleTransferForm>(
  Types.createTransferRequest
);
export const createTransferSuccess = createAction(Types.createTransferSuccess);
export const createTransferFailure = createAction<string>(
  Types.createTransferFailure
);
export const getWalletsRequest = createAction(Types.getWalletsRequest);
export const getWalletSuccess = createAction<WalletAccount[]>(
  Types.getWalletSuccess
);
export const getWalletsFailure = createAction<string>(Types.getWalletsFailure);
export const openCreateTransferDialog = createAction(
  Types.openCreateTransferDialog
);
export const afterIsSuccessful = createAction(Types.afterIsSuccessful);
export const uploadRequest = createAction<SaleTransferUploadForm>(
  Types.uploadRequest
);
export const uploadSuccess = createAction<{
  id: string;
  imageBase64: string;
}>(Types.uploadSuccess);
export const uploadFailure = createAction<string>(Types.uploadFailure);
export const afterUploadFailure = createAction(Types.afterUploadFailure);
export const getTransferRequest = createAction<SaleTransfer>(
  Types.getTransferRequest
);
export const getTransferSuccess = createAction<SaleTransfer>(
  Types.getTransferSuccess
);
export const getTransferFailure = createAction<string>(
  Types.getTransferFailure
);
export const updateTransferRequest = createAction<CreateSaleTransferForm>(
  Types.updateTransferRequest
);
export const updateTransferSuccess = createAction<CreateSaleTransferForm>(
  Types.updateTransferSuccess
);
export const updateTransferFailure = createAction<string>(
  Types.updateTransferFailure
);
export const setTransfer = createAction<SaleTransfer>(Types.setTransfer);
export const afterUpdateSuccessful = createAction(Types.afterUpdateSuccessful);
export const updateStatusTransferRequest = createAction(
  Types.updateStatusTransferRequest
);
export const updateStatusTransferSuccess = createAction(
  Types.updateStatusTransferSuccess
);
export const updateStatusTransferFailure = createAction<string>(
  Types.updateStatusTransferFailure
);
export const setTransferUpdateStatus = createAction<SaleTransfer>(
  Types.setTransferUpdateStatus
);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(onChangeDialog, (state, action) => {
      return { ...state, dialogId: action.payload };
    })
    .addCase(getTransfersRequest, (state) => {
      state.transfersList.isLoading = true;
      state.transfersList.error = null;
    })
    .addCase(getTransfersSuccess, (state, action) => {
      return {
        ...state,
        transfersList: {
          ...state.transfersList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getTransfersFailure, (state, action) => {
      state.transfersList.isLoading = false;
      state.transfersList.error = action.payload;
    })
    .addCase(createTransferRequest, (state) => {
      state.transferCreate.isCreating = true;
      state.transferCreate.error = null;
    })
    .addCase(createTransferSuccess, (state) => {
      state.transferCreate.isCreating = false;
      state.transferCreate.isSuccessful = true;
      state.dialogId = null;
    })
    .addCase(createTransferFailure, (state, action) => {
      state.transferCreate.isCreating = false;
      state.transferCreate.error = action.payload;
    })
    .addCase(getWalletsRequest, (state) => {
      state.walletAccounts.isLoading = true;
      state.walletAccounts.error = null;
    })
    .addCase(getWalletSuccess, (state, action) => {
      state.walletAccounts.isLoading = false;
      state.walletAccounts.accounts = action.payload;
    })
    .addCase(getWalletsFailure, (state, action) => {
      state.walletAccounts.isLoading = false;
      state.walletAccounts.error = action.payload;
    })
    .addCase(openCreateTransferDialog, (state) => {
      state.dialogId = 'createTransfer';
    })
    .addCase(afterIsSuccessful, (state) => {
      state.transferCreate.isSuccessful = false;
    })
    .addCase(uploadRequest, (state, { payload }) => {
      state.upload.isCreating = true;
      state.upload.error = null;
      state.upload.referenceId = payload.referenceId;
    })
    .addCase(uploadSuccess, (state, { payload }) => {
      state.upload.isCreating = false;
      state.upload.referenceId = '';
      state.transfersList.data = state.transfersList.data.map((data) => {
        if (data.id === payload.id) {
          return {
            ...data,
            photoUrl: payload.imageBase64,
          };
        }
        return data;
      });
    })
    .addCase(uploadFailure, (state, action) => {
      state.upload.isCreating = false;
      state.upload.error = action.payload;
      state.upload.isSubmitError = true;
    })
    .addCase(afterUploadFailure, (state) => {
      state.upload.isSubmitError = false;
    })
    .addCase(getTransferRequest, (state, { payload }) => {
      state.transferDetail.isLoading = true;
      state.transferDetail.error = null;
      state.transferDetail.transfer = payload;
    })
    .addCase(getTransferSuccess, (state, { payload }) => {
      state.transferDetail.isLoading = false;
      state.transferDetail.transfer = payload;
      state.dialogId = 'viewTransfer';
    })
    .addCase(getTransferFailure, (state, { payload }) => {
      state.transferDetail.isLoading = false;
      state.transferDetail.error = payload;
    })
    .addCase(updateTransferRequest, (state) => {
      state.transferUpdate.isUpdating = true;
      state.transferUpdate.error = null;
      state.transferUpdate.isSubmitted = false;
    })
    .addCase(updateTransferSuccess, (state, { payload }) => {
      state.transferUpdate.isUpdating = false;
      state.transferUpdate.isSubmitted = true;
      state.dialogId = null;
      if (state.transferUpdate.transfer) {
        state.transfersList.data = state.transfersList.data.map((transfer) => {
          if (transfer.id === state.transferUpdate.transfer?.id) {
            return {
              ...transfer,
              ...payload,
              status: 'PENDING',
              amount: {
                amount: payload.amount.amount,
                currency: state.transferUpdate.transfer.amount.currency,
              },
              balance: {
                amount: payload.amount.amount,
                currency: state.transferUpdate.transfer.amount.currency,
              },
            };
          }
          return transfer;
        });
      }
    })
    .addCase(updateTransferFailure, (state, action) => {
      state.transferUpdate.isUpdating = false;
      state.transferUpdate.error = action.payload;
    })
    .addCase(setTransfer, (state, { payload }) => {
      state.transferUpdate.transfer = payload;
      state.dialogId = 'updateTransfer';
    })
    .addCase(afterUpdateSuccessful, (state) => {
      state.transferUpdate.transfer = null;
      state.transferUpdate.isSubmitted = false;
    })
    .addCase(updateStatusTransferRequest, (state) => {
      state.transferUpdate.isUpdating = true;
      state.transferUpdate.error = null;
    })
    .addCase(updateStatusTransferSuccess, (state) => {
      state.transferUpdate.isUpdating = false;
      state.dialogId = null;
      if (state.transferUpdate.transfer) {
        state.transfersList.data = state.transfersList.data.map((transfer) => {
          if (transfer.id === state.transferUpdate.transfer?.id) {
            return {
              ...transfer,
              status: 'COMPLETE',
            };
          }
          return transfer;
        });
      }
    })
    .addCase(updateStatusTransferFailure, (state, action) => {
      state.transferUpdate.isUpdating = false;
      state.transferUpdate.error = action.payload;
    })
    .addCase(setTransferUpdateStatus, (state, { payload }) => {
      state.transferUpdate.transfer = payload;
      state.dialogId = 'updateStatus';
    });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
