import { MouseEvent, FC, useState } from 'react';
import {
  Button,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Stack,
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/system';

interface Props {
  onChangePage: (e: MouseEvent<HTMLButtonElement>) => void;
  page: number;
  onChangePageSize?: (newRowsPerPage: number) => void;
  pageSize: number;
  pageTokenLength: number;
}

const rowsPerPageOptions = [5, 25, 50, 100, 250];

const Pagination: FC<Props> = ({
  onChangePage,
  page,
  onChangePageSize,
  pageSize,
  pageTokenLength,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeRowsPerPage = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (onChangePageSize) {
      onChangePageSize(parseInt(e.currentTarget.innerText) || 25);
    }
  };
  return (
    <Stack
      direction="row"
      justifyContent={{
        md: 'flex-end',
        sx: 'flex-end',
        xs: 'space-between',
      }}
      spacing={2}
    >
      {onChangePageSize && (
        <Stack
          direction={{
            md: 'row',
            sx: 'row',
            xs: 'column',
          }}
          spacing={3}
          alignItems={{
            md: 'center',
            sm: 'center',
            xs: 'flex-start',
          }}
        >
          <Typography>ລາຍການຕໍ່ຫນ້າ</Typography>
          <Button
            sx={{ color: 'black', minWidth: '0', padding: '0' }}
            endIcon={<ArrowDropDown />}
            onClick={handleClick}
            aria-label="row-per-page"
          >
            {pageSize}
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: 0,
                  right: 16,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem
                sx={{
                  color: 'black',
                }}
                key={'row-page' + option.toString()}
                onClick={handleChangeRowsPerPage}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      )}
      <Stack
        direction={{
          md: 'row',
          sx: 'row',
          xs: 'column',
        }}
        spacing={3}
        justifyContent={{
          md: 'center',
          sm: 'center',
        }}
        alignItems="center"
      >
        <Typography>ຫນ້າ:{page}</Typography>
        <Box>
          <IconButton
            onClick={onChangePage}
            color="primary"
            id="prev"
            name="prev"
            disabled={page === 1} // disable prev button if page is first page
            aria-label="prev-page"
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={onChangePage}
            color="primary"
            id="next"
            name="next"
            aria-label="next-page"
            disabled={page - 1 === pageTokenLength} // disable next button if page is last page
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};
export default Pagination;
