import { combineReducers } from 'redux';
import users from './users';
import tiers from './tiers';
import exchangeRate from './exchange-rate';
import roles from './roles';
import seasons from './seasons';
import products from './products';
import customers from './customers';
import wallets from './wallets';
import zones from './zones';
import expenseTypes from './expense-types';
import activities from './activities';
import company from './company';
import productPrices from './product-price';
import report from './report';
import reportPurchases from './report-purchases';
import dashboard from './dashboard';
import predict from './predict';

export default combineReducers({
  products,
  users,
  tiers,
  exchangeRate,
  roles,
  seasons,
  customers,
  wallets,
  zones,
  expenseTypes,
  activities,
  company,
  productPrices,
  report,
  dashboard,
  predict,
  reportPurchases,
});
