import {
  all,
  fork,
  put,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetExpenseTypesResponse,
  CreateExpenseType,
  UpdateExpenseType,
  ExpensesTypeFilter,
  DefaultQueries,
} from 'models';
import { findErrorToData, setPageInfo } from 'utils';
import { addAlert } from 'store/notify';
let cancels: Canceler[] = [];

function* getExpenseTypes({
  payload,
}: ReturnType<typeof actions.getExpenseTypesRequest>) {
  const { expenseTypesList }: actions.InitialState = yield select(
    (state) => state.superAdmin.expenseTypes
  );

  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: ExpensesTypeFilter & DefaultQueries = {
      ...payloadParams,
      pageSize: pageSize || expenseTypesList.pageSize,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetExpenseTypesResponse = yield call(
      API.get,
      `/v1/finance/expenseTypes`,
      {
        params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: expenseTypesList.page,
        pageSize: expenseTypesList.pageSize,
        pageTokens: expenseTypesList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetExpenseTypesSuccess = {
      ...pageInfo,
      expenseTypes: data.expenseTypes,
    };
    yield put(actions.getExpenseTypesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getExpenseTypesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeExpenseTypesPage({
  payload,
}: ReturnType<typeof actions.onChangeExpenseTypesPage>) {
  const {
    expenseTypesList: { pageTokens, page },
  }: actions.InitialState = yield select(
    (state) => state.superAdmin.expenseTypes
  );
  const { nextOrPrev, ...payloadParams } = payload;
  let params: ExpensesTypeFilter & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getExpenseTypesRequest(params));
}
function* createExpenseType({
  payload,
}: ReturnType<typeof actions.createExpenseTypeRequest>) {
  const expenseType: CreateExpenseType = {
    name: payload.name,
    title: payload.title,
  };
  try {
    yield call(API.post, `/v1/admin/finance/expenseTypes`, expenseType, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.createExpenseTypeSuccess());
    cancels = [];
    yield put(actions.getExpenseTypesRequest({}));
    yield put(
      addAlert({
        message: 'ສ້າງສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createExpenseTypeFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateExpenseType({
  payload,
}: ReturnType<typeof actions.updateExpenseTypeRequest>) {
  const expenseType: UpdateExpenseType = {
    name: payload.name,
    title: payload.title,
  };
  try {
    yield call(API.post, `/v1/admin/finance/expenseTypes-update`, expenseType, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.updateExpenseTypeSuccess());
    cancels = [];
    yield put(actions.getExpenseTypesRequest({}));
    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateExpenseTypeFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetExpenseTypes() {
  yield takeLeading(actions.Types.getExpenseTypesRequest, getExpenseTypes);
}
function* watchOnChangeExpenseTypesPage() {
  yield takeLatest(
    actions.Types.onChangeExpenseTypesPage,
    onChangeExpenseTypesPage
  );
}
function* watchOnChangeExpenseTypesPageSize() {
  yield takeLatest(
    actions.Types.onChangeExpenseTypesPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeExpenseTypesPageSize>) {
      yield put(actions.getExpenseTypesRequest(payload));
    }
  );
}
function* watchCreateExpenseType() {
  yield takeLeading(actions.Types.createExpenseTypeRequest, createExpenseType);
}
function* watchUpdateExpenseType() {
  yield takeLeading(actions.Types.updateExpenseTypeRequest, updateExpenseType);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetExpenseTypes),
    fork(watchCreateExpenseType),
    fork(watchOnChangeExpenseTypesPage),
    fork(watchOnChangeExpenseTypesPageSize),
    fork(watchUpdateExpenseType),
  ]);
}
export default saga;
