import { createReducer, createAction } from '@reduxjs/toolkit';
import { Zone, ZonesFilter, CreateZoneForm, DefaultQueries } from 'models';

type DialogId = 'addZone' | 'updateZone' | null;
export interface InitialState {
  dialogId: DialogId;
  zonesList: {
    zones: Zone[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageTokens: string[];
    pageSize: number;
  };
  createZone: {
    isCreating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
  };
  updateZone: {
    isUpdating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
    zone: Zone | null;
  };
}
const initialState: InitialState = {
  dialogId: null,
  createZone: {
    error: null,
    isCreating: false,
    isAfterSubmitted: false,
  },
  zonesList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    zones: [],
  },
  updateZone: {
    error: null,
    isAfterSubmitted: false,
    isUpdating: false,
    zone: null,
  },
};
export enum Types {
  onChangeDialogId = 'super-admin/zone@onChangeDialogId',
  cancelRequestAPI = 'super-admin/zone@cancelRequestAPI',
  getZonesRequest = 'super-admin/zone@getZonesRequest',
  getZonesSuccess = 'super-admin/zone@getZonesSuccess',
  getZonesFailure = 'super-admin/zone@getZonesFailure',
  onChangeZonesPage = 'super-admin/zone@onChangeZonesPage',
  onChangeZonesPageSize = 'super-admin/zone@onChangeZonesPageSize',
  createZoneRequest = 'super-admin/zone@createZoneRequest',
  createZoneSuccess = 'super-admin/zone@createZoneSuccess',
  createZoneFailure = 'super-admin/zone@createZoneFailure',
  afterCreateZoneSuccess = 'super-admin/zone@afterCreateZoneSuccess',
  updateZoneRequest = 'super-admin/zone@updateZoneRequest',
  updateZoneSuccess = 'super-admin/zone@updateZoneSuccess',
  updateZoneFailure = 'super-admin/zone@updateZoneFailure',
  afterUpdateZoneSuccess = 'super-admin/zone@afterUpdateZoneSuccess',
  setZone = 'super-admin/zone@setZone',
}
export type GetZonesSuccess = DefaultQueries & {
  zones: Zone[];
};

export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);
export const getZonesRequest = createAction<ZonesFilter>(Types.getZonesRequest);
export const getZonesSuccess = createAction<GetZonesSuccess>(
  Types.getZonesSuccess
);
export const getZonesFailure = createAction<string>(Types.getZonesFailure);
export const onChangeZonesPage = createAction<ZonesFilter>(
  Types.onChangeZonesPage
);
export const onChangeZonesPageSize = createAction<ZonesFilter>(
  Types.onChangeZonesPageSize
);
export const createZoneRequest = createAction<CreateZoneForm>(
  Types.createZoneRequest
);
export const createZoneSuccess = createAction(Types.createZoneSuccess);
export const createZoneFailure = createAction<string>(Types.createZoneFailure);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const afterCreateZoneSuccess = createAction(
  Types.afterCreateZoneSuccess
);
export const updateZoneRequest = createAction<CreateZoneForm>(
  Types.updateZoneRequest
);
export const updateZoneSuccess = createAction(Types.updateZoneSuccess);
export const updateZoneFailure = createAction<string>(Types.updateZoneFailure);
export const afterUpdateZoneSuccess = createAction(
  Types.afterUpdateZoneSuccess
);
export const setZone = createAction<Zone>(Types.setZone);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(onChangeDialogId, (state, action) => {
      state.dialogId = action.payload;
      state.updateZone.zone = null;
    })
    .addCase(getZonesRequest, (state) => {
      state.zonesList.isLoading = true;
      state.zonesList.error = null;
    })
    .addCase(getZonesSuccess, (state, action) => {
      return {
        ...state,
        zonesList: { ...state.zonesList, ...action.payload, isLoading: false },
      };
    })
    .addCase(getZonesFailure, (state, action) => {
      state.zonesList.isLoading = false;
      state.zonesList.error = action.payload;
    })
    .addCase(createZoneRequest, (state) => {
      state.createZone.error = null;
      state.createZone.isCreating = true;
    })
    .addCase(createZoneSuccess, (state) => {
      state.createZone.isCreating = false;
      state.createZone.isAfterSubmitted = true;
      state.dialogId = null;
    })
    .addCase(createZoneFailure, (state, action) => {
      state.createZone.error = action.payload;
      state.createZone.isCreating = false;
    })
    .addCase(cancelRequestAPI, (state) => {
      state.zonesList.isLoading = false;
      state.createZone.isCreating = false;
    })
    .addCase(afterCreateZoneSuccess, (state) => {
      state.createZone.isAfterSubmitted = false;
    })
    .addCase(updateZoneRequest, (state) => {
      state.updateZone.error = null;
      state.updateZone.isUpdating = true;
    })
    .addCase(updateZoneSuccess, (state) => {
      state.updateZone.isUpdating = false;
      state.updateZone.isAfterSubmitted = true;
      state.dialogId = null;
      state.updateZone.zone = null;
    })
    .addCase(updateZoneFailure, (state, action) => {
      state.updateZone.error = action.payload;
      state.updateZone.isUpdating = false;
    })
    .addCase(afterUpdateZoneSuccess, (state) => {
      state.updateZone.isAfterSubmitted = false;
    })
    .addCase(setZone, (state, { payload }) => {
      state.updateZone.zone = payload;
      state.dialogId = 'updateZone';
    });
});

export default reducer;
