import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData } from 'utils';
import { summariesLots } from 'utils/dashboard';
import type { GetSummariesLotsResponse, LotsQueries } from 'models/dashboard';
let cancels: Canceler[] = [];

function* getLots({ payload }: ReturnType<typeof actions.getLotsRequest>) {
  const { startedAt, endedAt, status } = payload;
  let params: LotsQueries = {
    status,
  };
  if (startedAt && endedAt) {
    params = {
      startedAt,
      endedAt,
    };
  }
  try {
    const { data }: GetSummariesLotsResponse = yield call(
      API.get,
      `/v1/inventory/report/summary-lots`,
      {
        params: params,
      }
    );
    yield put(
      actions.getLotsSuccess({
        summaries: summariesLots(data.summaryLots),
        entities: data.summaryLots,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getLotsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetLots() {
  yield takeLatest(actions.Types.getLotsRequest, getLots);
}
function* saga() {
  yield all([fork(watchCancelRequestAPI), fork(watchGetLots)]);
}
export default saga;
