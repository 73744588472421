import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  ProductPrice,
  DefaultQueries,
  SelectOption,
  ProductPricesQueries,
  RejectProductPriceForm,
} from 'models';

type DialogID = 'confirmApprove' | 'rejectProductPrice' | null;

export interface InitialState {
  today: {
    prices: ProductPrice[];
    isLoading: boolean;
    error: string | null;
  };
  prices: {
    prices: ProductPrice[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  form: {
    isCreating: boolean;
    isUpdating: boolean;
    isLoading: boolean;
    dialogID: DialogID;
    price: ProductPrice | null;
    error: string | null;
    products: SelectOption[];
    isAfterSubmitSuccess: boolean;
  };
}
const initialState: InitialState = {
  today: {
    error: null,
    isLoading: false,
    prices: [],
  },
  prices: {
    error: null,
    isLoading: false,
    prices: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  form: {
    isCreating: false,
    isUpdating: false,
    isLoading: false,
    price: null,
    error: null,
    dialogID: null,
    products: [],
    isAfterSubmitSuccess: false,
  },
};

export enum Types {
  cancelRequestAPI = 'admin/product-prices@cancelRequestAPI',
  onChangeDialogID = 'admin/product-prices@onChangeDialogID',
  getProductPricesToDayRequest = 'admin/product-prices@getProductPricesToDayRequest',
  getProductPricesToDaySuccess = 'admin/product-prices@getProductPricesToDaySuccess',
  getProductPricesToDayFailure = 'admin/product-prices@getProductPricesToDayFailure',
  getProductPricesRequest = 'admin/product-prices@getProductPricesRequest',
  getProductPricesSuccess = 'admin/product-prices@getProductPricesSuccess',
  getProductPricesFailure = 'admin/product-prices@getProductPricesFailure',
  productPricesOnChangePageSize = 'admin/product-prices@productPricesOnChangePageSize',
  productPricesOnChangePage = 'admin/product-prices@productPricesOnChangePage',
  approveProductPriceRequest = 'admin/product-prices@approveProductPriceRequest',
  approveProductPriceSuccess = 'admin/product-prices@approveProductPriceSuccess',
  approveProductPriceFailure = 'admin/product-prices@approveProductPriceFailure',

  afterSubmitSuccess = 'admin/product-prices@afterSubmitSuccess',
  setProductPrice = 'admin/product-prices@setProductPrice',
  openRejectProductPriceDialog = 'admin/product-prices@openRejectProductPriceDialog',
  rejectProductPriceRequest = 'admin/product-prices@rejectProductPriceRequest',
  rejectProductPriceSuccess = 'admin/product-prices@rejectProductPriceSuccess',
  rejectProductPriceFailure = 'admin/product-prices@rejectProductPriceFailure',
}

export type GetProductPricesSuccess = DefaultQueries & {
  prices: ProductPrice[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getProductPricesToDayRequest = createAction(
  Types.getProductPricesToDayRequest
);
export const getProductPricesToDaySuccess = createAction<ProductPrice[]>(
  Types.getProductPricesToDaySuccess
);
export const getProductPricesToDayFailure = createAction<string>(
  Types.getProductPricesToDayFailure
);
export const getProductPricesRequest = createAction<
  ProductPricesQueries,
  Types.getProductPricesRequest
>(Types.getProductPricesRequest);
export const getProductPricesSuccess = createAction<
  GetProductPricesSuccess,
  Types.getProductPricesSuccess
>(Types.getProductPricesSuccess);
export const getProductPricesFailure = createAction<
  string | null,
  Types.getProductPricesFailure
>(Types.getProductPricesFailure);
export const productPricesOnChangePageSize = createAction<
  ProductPricesQueries,
  Types.productPricesOnChangePageSize
>(Types.productPricesOnChangePageSize);
export const productPricesOnChangePage = createAction<
  ProductPricesQueries,
  Types.productPricesOnChangePage
>(Types.productPricesOnChangePage);
export const approveProductPriceRequest = createAction(
  Types.approveProductPriceRequest
);
export const approveProductPriceSuccess = createAction(
  Types.approveProductPriceSuccess
);
export const approveProductPriceFailure = createAction<string | null>(
  Types.approveProductPriceFailure
);
export const afterSubmitSuccess = createAction(Types.afterSubmitSuccess);
export const setProductPrice = createAction<ProductPrice>(
  Types.setProductPrice
);
export const rejectProductPriceRequest = createAction<RejectProductPriceForm>(
  Types.rejectProductPriceRequest
);
export const rejectProductPriceSuccess = createAction(
  Types.rejectProductPriceSuccess
);
export const rejectProductPriceFailure = createAction<string | null>(
  Types.rejectProductPriceFailure
);
export const openRejectProductPriceDialog = createAction<ProductPrice>(
  Types.openRejectProductPriceDialog
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.form.isCreating = false;
    state.form.isUpdating = false;
    state.form.isLoading = false;
    state.prices.isLoading = false;
    state.today.isLoading = false;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.form.dialogID = action.payload;
  });
  builder.addCase(getProductPricesToDayRequest, (state, action) => {
    state.today.isLoading = true;
    state.today.error = null;
  });
  builder.addCase(getProductPricesToDaySuccess, (state, action) => {
    return {
      ...state,
      today: { isLoading: false, error: null, prices: action.payload },
    };
  });
  builder.addCase(getProductPricesToDayFailure, (state, action) => {
    return {
      ...state,
      today: { ...state.today, isLoading: false, error: action.payload },
    };
  });
  builder.addCase(getProductPricesRequest, (state, action) => {
    state.prices.isLoading = true;
    state.prices.error = null;
  });
  builder.addCase(getProductPricesSuccess, (state, action) => {
    return {
      ...state,
      prices: {
        ...state.prices,
        isLoading: false,
        error: null,
        ...action.payload,
      },
    };
  });
  builder.addCase(getProductPricesFailure, (state, action) => {
    return {
      ...state,
      prices: {
        ...state.prices,
        isLoading: false,
        error: action.payload,
      },
    };
  });
  builder.addCase(approveProductPriceRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(approveProductPriceSuccess, (state) => {
    state.form.isCreating = false;
    state.form.dialogID = null;
    state.form.isAfterSubmitSuccess = true;
  });
  builder.addCase(approveProductPriceFailure, (state, action) => {
    return {
      ...state,
      form: { ...state.form, isCreating: false, error: action.payload },
    };
  });
  builder.addCase(setProductPrice, (state, action) => {
    state.form = {
      ...state.form,
      dialogID: 'confirmApprove',
      price: action.payload,
    };
  });
  builder.addCase(afterSubmitSuccess, (state) => {
    state.form.isAfterSubmitSuccess = false;
  });
  builder.addCase(rejectProductPriceRequest, (state) => {
    state.form.isUpdating = true;
    state.form.error = null;
  });
  builder.addCase(rejectProductPriceSuccess, (state) => {
    state.form.isUpdating = false;
    state.form.dialogID = null;
    state.form.isAfterSubmitSuccess = true;
  });
  builder.addCase(rejectProductPriceFailure, (state, action) => {
    return {
      ...state,
      form: { ...state.form, isUpdating: false, error: action.payload },
    };
  });
  builder.addCase(openRejectProductPriceDialog, (state, action) => {
    state.form = {
      ...state.form,
      dialogID: 'rejectProductPrice',
      price: action.payload,
    };
  });
});

export default reducer;
