import { createReducer, createAction } from '@reduxjs/toolkit';
import { NotifyAlert } from 'models';
export interface InitialState {
  alerts: NotifyAlert[] | null;
}
const initialState: InitialState = {
  alerts: null,
};
export enum Types {
  addAlert = 'addAlert',
  removeAlert = 'removeAlert',
}
export const addAlert = createAction<NotifyAlert | NotifyAlert[]>(
  Types.addAlert
);
export const removeAlert = createAction(Types.removeAlert);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addAlert, (state, action) => {
      if (Array.isArray(action.payload)) {
        return { alerts: action.payload };
      }
      return { alerts: [action.payload] };
    })
    .addCase(removeAlert, (state) => {
      return { ...state, alerts: null };
    });
});
export default reducer;
