import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData } from 'utils';
import { summariesPurchasesProducts } from 'utils/dashboard';
import type {
  GetPurchasesResponse,
  ReportDefaultQueries,
} from 'models/dashboard';
let cancels: Canceler[] = [];

function* getPurchasesWeight({
  payload,
}: ReturnType<typeof actions.getPurchasesWeightRequest>) {
  const { timeline, endedAt, ...props } = payload;
  let params: ReportDefaultQueries = {
    ...props,
  };
  if (timeline !== 'DATETIME') {
    params.timeline = timeline;
  }
  if (timeline === 'DATETIME') {
    params.endedAt = endedAt;
  }
  try {
    const { data }: GetPurchasesResponse = yield call(
      API.get,
      `/v1/dashboard/purchase`,
      {
        params: params,
      }
    );
    yield put(
      actions.getPurchasesWeightSuccess({
        summaries: summariesPurchasesProducts(data.purchase),
        entities: data.purchase,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getPurchasesWeightFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetPurchasesWeight() {
  yield takeLatest(actions.Types.getPurchasesWeightRequest, getPurchasesWeight);
}
function* saga() {
  yield all([fork(watchCancelRequestAPI), fork(watchGetPurchasesWeight)]);
}
export default saga;
