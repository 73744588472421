import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';

import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData } from 'utils';
import {
  PurchasesTransaction,
  GetPurchasesTransactionsResponse,
  PurchasesSummaryColumn,
} from 'models/superAdminReport';
import { exportPurchasesTable } from 'utils/report';

let cancels: Canceler[] = [];

type Summary = {
  price: number;
  tierPrice: number;
  summaryTierPrice: number;
  summaryPrice: number;
};
function* getSummariesPurchases({
  payload,
}: ReturnType<typeof actions.getSummariesPurchasesRequest>) {
  const { endedAt, startedAt, timeline, keyName, ...props } = payload;
  try {
    let params: any = {
      timeline,
      startedAt,
      ...props,
    };
    if (timeline === 'WEEK') {
      delete params.endedAt;
    }
    if (timeline === 'MONTH') {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data } = yield call(API.get, `/v1/dashboard/summary-purchase`, {
      params,
    });
    const { timelines, data: purchases } = exportPurchasesTable(data.sale);
    const summary: Summary = Object.values(purchases).reduce(
      (values, item) => {
        return {
          price:
            values.price +
            (item.summaries.price / item.summaries.count) *
              item.summaries.weight,
          tierPrice:
            values.price +
            (item.summaries.tierPrice / item.summaries.count) *
              item.summaries.weight,
          summaryPrice: values.price + item.summaries.price,
          summaryTierPrice: values.tierPrice + item.summaries.tierPrice,
        };
      },
      {
        tierPrice: 0,
        price: 0,
        summaryTierPrice: 0,
        summaryPrice: 0,
      } as Summary
    );
    const { summaries, ...entities } = purchases;
    yield put(
      actions.getSummariesPurchasesSuccess({
        entities,
        timelines,
        summaries: {
          ...summaries,
          price: summary.price / summary.summaryPrice,
          tierPrice: summary.tierPrice / summary.summaryTierPrice,
        },
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSummariesPurchasesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getPurchasesTransactions({
  payload,
}: ReturnType<typeof actions.getPurchasesTransactionsRequest>) {
  try {
    const {
      summary: summaryPayload,
      startedAt,
      endedAt,
      season,
      customer,
      isFirstRender,
    } = payload;
    const getSummary = JSON.parse(summaryPayload || '{}');
    if (typeof getSummary !== 'object') {
      yield put(actions.getPurchasesTransactionsFailure());
      yield put(
        addAlert({
          message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
          serviceType: 'snackbar',
        })
      );
      return;
    }
    //TODO : ກວດຂໍ້ມູນໃຫມ່
    const {
      purchasesTransactions: { summary },
    }: actions.InitialState = yield select(
      (state) => state.superAdmin.report.purchases
    );
    const cancelToken = CancelToken.source();
    cancels.push(cancelToken.cancel);
    const queries: any = {
      pageSize: 250,
      zone: getSummary.zone.name,
      tier: getSummary.tier.name,
      product: getSummary.product.name,
      startedAt,
      endedAt,
    };
    if (season) {
      queries.season = season;
    }
    if (customer) {
      queries.customer = customer;
    }

    const { data }: GetPurchasesTransactionsResponse = yield call(
      API.get,
      `/v1/purchase/report/items`,
      {
        cancelToken: cancelToken.token,
        params: queries,
      }
    );

    const transactions: PurchasesTransaction[] = data.purchase.map((sale) => {
      const {
        createdAt,
        amount,
        localPrice,
        tierPurchasePrice,
        weight,
        price,
        ...props
      } = sale;

      const weightNumber = Number.parseFloat(weight);
      const priceNumber = Number.parseFloat(price.amount);
      const amountNumber = Number.parseFloat(amount.amount);
      const localPriceNumber = Number.parseFloat(localPrice.amount);
      const tierPurchasePriceNumber = Number.parseFloat(
        tierPurchasePrice.amount
      );

      return {
        ...props,
        createdAt,
        weight: weightNumber,
        price: {
          amount: priceNumber,
          currency: price.currency,
        },
        amount: {
          amount: amountNumber,
          currency: amount.currency,
        },
        localPrice: {
          amount: localPriceNumber,
          currency: localPrice.currency,
        },
        tierPurchasePrice: {
          amount: tierPurchasePriceNumber,
          currency: tierPurchasePrice.currency,
        },
      };
    });
    const summaries = data.purchase.reduce(
      (summaries, item) => {
        const weightNumber = Number.parseFloat(item.weight);
        const priceNumber = Number.parseFloat(item.price.amount);
        const amountNumber = Number.parseFloat(item.amount.amount);
        const localPriceNumber = Number.parseFloat(
          item.tierPurchasePrice.amount
        );
        return {
          count: summaries.count + 1,
          amount: summaries.amount + amountNumber,
          price: summaries.price + priceNumber,
          tierPrice: summaries.tierPrice + localPriceNumber,
          weight: summaries.weight + weightNumber,
        };
      },
      {
        count: 0,
        amount: 0,
        price: 0,
        tierPrice: 0,
        weight: 0,
      } as PurchasesSummaryColumn
    );
    const newSummaries: PurchasesSummaryColumn = {
      count: summaries.count + summary.count,
      amount: summaries.amount + summary.amount,
      price: summaries.price + summary.price,
      tierPrice: summaries.tierPrice + summary.tierPrice,
      weight: summaries.weight + summary.weight,
    };
    yield put(
      actions.getPurchasesTransactionsSuccess({
        entities: transactions,
        summary: isFirstRender ? summaries : newSummaries,
        pageTokens: data.nextPageToken,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getPurchasesTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetSummariesPurchasesRequest() {
  yield takeLatest(actions.getSummariesPurchasesRequest, getSummariesPurchases);
}
function* watchGetPurchasesTransactions() {
  yield takeLatest(
    actions.getPurchasesTransactionsRequest,
    getPurchasesTransactions
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetSummariesPurchasesRequest),
    fork(watchGetPurchasesTransactions),
  ]);
}
export default saga;
