import React from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { removeAlert } from 'store/notify';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface Props {
  children: React.ReactNode;
}
let isFirst = false;
const SnackbarHook: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { alerts } = useAppSelector((state) => state.notify);

  const enqueueSnackbarCallback = React.useCallback(() => {
    if (alerts && alerts.length !== 0 && !isFirst) {
      alerts
        .filter((a) => a.serviceType === 'snackbar')
        .forEach((alert) => {
          enqueueSnackbar(alert.message, {
            variant: alert.type,
            action: (snackbarId) => (
              <IconButton onClick={() => closeSnackbar(snackbarId)}>
                <CloseIcon color="inherit" sx={{ color: 'white' }} />
              </IconButton>
            ),
          });
        });
      dispatch(removeAlert());
    }
  }, [alerts, dispatch, enqueueSnackbar, closeSnackbar]);

  React.useEffect(() => {
    enqueueSnackbarCallback();
    return () => {
      if (!alerts && isFirst) {
        isFirst = false;
      }
      if (alerts && alerts.length !== 0 && !isFirst) {
        isFirst = true;
      }
    };
  }, [alerts, enqueueSnackbarCallback]);

  return <>{children}</>;
};

export default React.memo(SnackbarHook);
