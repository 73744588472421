import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  ProductPricesQueries,
  RangeProductPriceForm,
  MoistureProductPrices,
  MoistureProductPrice,
  RejectProductPrice,
} from 'models';

type DialogID = 'create' | 'update' | 'approve' | 'reject' | null;
export interface InitialState {
  today: {
    prices: MoistureProductPrices;
    isLoading: boolean;
    error: string | null;
  };
  statement: {
    prices: MoistureProductPrices;
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  form: {
    isCreating: boolean;
    isUpdating: boolean;
    isLoading: boolean;
    dialogID: DialogID;
    error: string | null;
    isAfterSubmitSuccess: boolean;
    price: MoistureProductPrice | null;
  };
}
const initialState: InitialState = {
  today: {
    error: null,
    isLoading: false,
    prices: {},
  },
  statement: {
    error: null,
    isLoading: false,
    prices: {},
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  form: {
    isCreating: false,
    isUpdating: false,
    isLoading: false,
    error: null,
    dialogID: null,
    isAfterSubmitSuccess: false,
    price: null,
  },
};

export enum Types {
  cancelRequestAPI = 'tier/product-price@cancelRequestAPI',
  onChangeDialogID = 'tier/product-price@onChangeDialogID',
  getProductPricesToDayRequest = 'tier/product-price@getProductPricesToDayRequest',
  getProductPricesToDaySuccess = 'tier/product-price@getProductPricesToDaySuccess',
  getProductPricesToDayFailure = 'tier/product-price@getProductPricesToDayFailure',
  getProductPricesRequest = 'tier/product-price@getProductPricesRequest',
  getProductPricesSuccess = 'tier/product-price@getProductPricesSuccess',
  getProductPricesFailure = 'tier/product-price@getProductPricesFailure',
  productPricesOnChangePageSize = 'tier/product-price@productPricesOnChangePageSize',
  productPricesOnChangePage = 'tier/product-price@productPricesOnChangePage',
  createProductPriceRequest = 'tier/product-price@createProductPriceRequest',
  createProductPriceSuccess = 'tier/product-price@createProductPriceSuccess',
  createProductPriceFailure = 'tier/product-price@createProductPriceFailure',
  afterSubmitSuccess = 'tier/product-price@afterSubmitSuccess',
  selectProductPrice = 'tier/product-price@selectProductPrice',
  rejectProductPriceRequest = 'tier/product-price@rejectProductPriceRequest',
  rejectProductPriceSuccess = 'tier/product-price@rejectProductPriceSuccess',
  rejectProductPriceFailure = 'tier/product-price@rejectProductPriceFailure',
}

export type GetProductPricesSuccess = DefaultQueries & {
  prices: MoistureProductPrices;
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getProductPricesToDayRequest = createAction<DefaultQueries>(
  Types.getProductPricesToDayRequest
);
export const getProductPricesToDaySuccess = createAction<MoistureProductPrices>(
  Types.getProductPricesToDaySuccess
);
export const getProductPricesToDayFailure = createAction<string>(
  Types.getProductPricesToDayFailure
);
export const getProductPricesRequest = createAction<
  ProductPricesQueries,
  Types.getProductPricesRequest
>(Types.getProductPricesRequest);
export const getProductPricesSuccess = createAction<
  GetProductPricesSuccess,
  Types.getProductPricesSuccess
>(Types.getProductPricesSuccess);
export const getProductPricesFailure = createAction<
  string | null,
  Types.getProductPricesFailure
>(Types.getProductPricesFailure);
export const productPricesOnChangePageSize = createAction<
  ProductPricesQueries,
  Types.productPricesOnChangePageSize
>(Types.productPricesOnChangePageSize);
export const productPricesOnChangePage = createAction<
  ProductPricesQueries,
  Types.productPricesOnChangePage
>(Types.productPricesOnChangePage);
export const createProductPriceRequest = createAction<RangeProductPriceForm>(
  Types.createProductPriceRequest
);
export const createProductPriceSuccess = createAction<MoistureProductPrices>(
  Types.createProductPriceSuccess
);
export const createProductPriceFailure = createAction<string | null>(
  Types.createProductPriceFailure
);
export const selectProductPrice = createAction<MoistureProductPrice | null>(
  Types.selectProductPrice
);
export const afterSubmitSuccess = createAction(Types.afterSubmitSuccess);
export const rejectProductPriceRequest = createAction<RejectProductPrice>(
  Types.rejectProductPriceRequest
);
export const rejectProductPriceSuccess = createAction<RejectProductPrice>(
  Types.rejectProductPriceSuccess
);
export const rejectProductPriceFailure = createAction<string | null>(
  Types.rejectProductPriceFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.form.isCreating = false;
    state.form.isUpdating = false;
    state.form.isLoading = false;
    state.statement.isLoading = false;
    state.today.isLoading = false;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.form.dialogID = action.payload;
  });
  builder.addCase(getProductPricesToDayRequest, (state, action) => {
    state.today.isLoading = true;
    state.today.error = null;
  });
  builder.addCase(getProductPricesToDaySuccess, (state, action) => {
    return {
      ...state,
      today: { isLoading: false, error: null, prices: action.payload },
    };
  });
  builder.addCase(getProductPricesToDayFailure, (state, action) => {
    return {
      ...state,
      today: { ...state.today, isLoading: false, error: action.payload },
    };
  });
  builder.addCase(getProductPricesRequest, (state, action) => {
    state.statement.isLoading = true;
    state.statement.error = null;
  });
  builder.addCase(getProductPricesSuccess, (state, action) => {
    return {
      ...state,
      statement: {
        ...state.statement,
        isLoading: false,
        error: null,
        ...action.payload,
      },
    };
  });
  builder.addCase(getProductPricesFailure, (state, action) => {
    return {
      ...state,
      statement: {
        ...state.statement,
        isLoading: false,
        error: action.payload,
      },
    };
  });
  builder.addCase(createProductPriceRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(createProductPriceSuccess, (state, { payload }) => {
    state.form.isCreating = false;
    state.form.dialogID = null;
    state.form.isAfterSubmitSuccess = true;
    state.today.prices = { ...payload, ...state.today.prices };
  });
  builder.addCase(createProductPriceFailure, (state, action) => {
    return {
      ...state,
      form: { ...state.form, isCreating: false, error: action.payload },
    };
  });
  builder.addCase(afterSubmitSuccess, (state) => {
    state.form.isAfterSubmitSuccess = false;
  });
  builder.addCase(selectProductPrice, (state, { payload }) => {
    state.form.price = payload;
  });
  builder
    .addCase(rejectProductPriceRequest, (state) => {
      state.form.isUpdating = true;
      state.form.error = null;
    })
    .addCase(rejectProductPriceSuccess, (state, { payload }) => {
      state.form.isUpdating = false;
      state.form.dialogID = null;
      state.form.isAfterSubmitSuccess = true;
      const prices = state.today.prices;
      delete prices[payload.id];
      state.today.prices = prices;
    })
    .addCase(rejectProductPriceFailure, (state, action) => {
      return {
        ...state,
        form: { ...state.form, isUpdating: false, error: action.payload },
      };
    });
});

export default reducer;
