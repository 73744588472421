import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData } from 'utils';
import { summariesSales } from 'utils/dashboard';
import type {
  GetSalesResponse,
  DefaultQueries,
  GetSalesDocCountSummariesResponse,
} from 'models/dashboard';
let cancels: Canceler[] = [];

function* getSales({
  payload,
}: ReturnType<typeof actions.getSalesWeightRequest>) {
  const { endedAt, timeline, ...props } = payload;
  try {
    let params: DefaultQueries = {
      ...props,
    };
    if (timeline !== 'DATETIME') {
      params.timeline = timeline;
    }
    if (timeline === 'DATETIME') {
      params.endedAt = endedAt;
    }
    const { data }: GetSalesResponse = yield call(
      API.get,
      `/v1/dashboard/sale`,
      {
        params,
      }
    );

    yield put(
      actions.getSalesWeightSuccess({
        entities: data.sale,
        summaries: summariesSales(data.sale),
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSalesWeightFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getSalesDoc() {
  try {
    let params: DefaultQueries = {};

    const { data }: GetSalesDocCountSummariesResponse = yield call(
      API.get,
      `/v1/dashboard/sale-count`,
      {
        params,
      }
    );

    yield put(actions.getSalesDocSuccess(data.sale));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSalesDocFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetSales() {
  yield takeLatest(actions.Types.getSalesWeightRequest, getSales);
}
function* watchGetSalesDoc() {
  yield takeLatest(actions.Types.getSalesDocRequest, getSalesDoc);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetSales),
    fork(watchGetSalesDoc),
  ]);
}
export default saga;
