import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetProductsResponse,
  GetQualityCheckResponse,
  CreateQualityCheck,
} from 'models';
import { findErrorToData } from 'utils';
import { RootState } from 'store/index';
import moment from 'moment';

let cancels: Canceler[] = [];

const getQualityChecksAPI = (query: string) =>
  API.get(`/v1/purchase/qualitycheck?${query}`, {
    cancelToken: new CancelToken((c) => cancels.push(c)),
  });
const getProductsAPI = () =>
  API.get(`/v1/products`, {
    cancelToken: new CancelToken((c) => cancels.push(c)),
  });

const createPurchaseAPI = (qualityCheck: CreateQualityCheck) =>
  API.post(`/v1/purchase/qualitycheck`, qualityCheck);

function* getProducts() {
  try {
    const { data }: GetProductsResponse = yield call(getProductsAPI);
    yield put(
      actions.getProductsSuccess(
        data.products.map((p) => ({ label: p.title, value: p.name }))
      )
    );
    cancels = [];
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(actions.getProductsFailure(errorData.message));
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type GetQualityCheckAction = ReturnType<typeof actions.getQualityCheckRequest>;
function* getQualityChecks({ payload }: GetQualityCheckAction) {
  const { auth, qualityCheck }: RootState = yield select((state) => state);
  const {
    qualityCheck: { pageSize, pageTokens },
  } = qualityCheck;
  let query = `tier=${auth.accept.tier?.name || ''}&pageSize=${
    payload.pageSize ? payload.pageSize : pageSize
  }&updatedAt=${encodeURIComponent(moment().format('YYYY-MM-DDTHH:mm:ssZ'))}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }

  let dataPayload: actions.GetQualityCheckSuccess = {
    data: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetQualityCheckResponse = yield call(
      getQualityChecksAPI,
      query
    );
    dataPayload.data = data.qualityChecks;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getQualityCheckSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.getQualityCheckFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type QualityChecksOnChangePageAction = ReturnType<
  typeof actions.qualityCheckOnChangePage
>;
function* qualityChecksOnChangePage({
  payload,
}: QualityChecksOnChangePageAction) {
  const {
    qualityCheck: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.qualityCheck);
  let cloneFilters: any = {};
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getQualityCheckRequest(cloneFilters));
}
type QualityChecksOnChangePageSize = ReturnType<
  typeof actions.qualityCheckOnChangePageSize
>;
function* qualityChecksOnChangePageSize({
  payload,
}: QualityChecksOnChangePageSize) {
  yield put(actions.getQualityCheckRequest(payload));
}
type CreateQualityCheckRequest = ReturnType<
  typeof actions.createQualityCheckRequest
>;
function* createQualityCheck({ payload }: CreateQualityCheckRequest) {
  const {
    qualityCheck: { form },
    auth: { accept },
  }: RootState = yield select((state) => state);
  const product = form.products.find((p) => p.value === payload.productName);
  const price: CreateQualityCheck = {
    product: {
      name: product?.value || '',
      title: product?.label || '',
    },
    externalCondition: payload.externalCondition,
    impurity: payload.impurity,
    moisturePercent: payload.moisturePercent,
    starchPercent: payload.starchPercent,
    vihicleCode: payload.vihicleCode,
    tier: accept.tier?.name || '',
  };
  try {
    yield call(createPurchaseAPI, price);
    yield put(actions.createQualityCheckSuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມການຊື້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.createQualityCheckFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetProducts() {
  yield takeLatest(actions.Types.getProductsRequest, getProducts);
}
function* watchGetQualityChecks() {
  yield takeLatest(actions.Types.getQualityCheckRequest, getQualityChecks);
}
function* watchQualityChecksOnChangePage() {
  yield takeLatest(
    actions.Types.qualityCheckOnChangePage,
    qualityChecksOnChangePage
  );
}
function* watchQualityChecksOnChangePageSize() {
  yield takeLatest(
    actions.Types.qualityCheckOnChangePageSize,
    qualityChecksOnChangePageSize
  );
}
function* watchCreateQualityCheck() {
  yield takeLatest(actions.Types.createQualityCheckRequest, createQualityCheck);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetProducts),
    fork(watchGetQualityChecks),
    fork(watchQualityChecksOnChangePage),
    fork(watchQualityChecksOnChangePageSize),
    fork(watchCreateQualityCheck),
  ]);
}
export default saga;
