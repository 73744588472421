import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';

const Box = styled(MuiBox)({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.1em',
    },
    '*::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F5F5',
    },
    '*::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 0 20px rgba(0, 0, 0, 0.24)`,
      backgroundColor: '#06427a',
      outline: '1px solid slategrey',
      borderRadius: 8,
    },
    '& ::-webkit-scrollbar-thumb:active': {
      boxShadow: `inset 0 0 0 20px rgba(0, 0, 0, 0.37)`,
    },
  },
});

export default Box;
