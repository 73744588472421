import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  Sale,
  ReportDefaultQueries,
  SummariesGroupProduct,
  SalesDocCountSummaries,
} from 'models/dashboard';

type SetWeight = {
  entities: Sale[];
  summaries: SummariesGroupProduct;
};

export interface InitialState {
  weight: SetWeight & {
    isLoading: boolean;
    error: string | null;
  };
  salesDoc: {
    entities: SalesDocCountSummaries;
    isLoading: boolean;
    error: null | string;
  };
}
const initialState: InitialState = {
  weight: {
    entities: [],
    error: null,
    isLoading: false,
    summaries: {
      'dry-cassava': {
        price: 0,
        weight: 0,
        amount: 0,
        count: 0,
        title: 'ມັນແຫ້ງ',
      },
      'fresh-cassava': {
        price: 0,
        weight: 0,
        amount: 0,
        count: 0,
        title: 'ມັນຫົວ',
      },
    },
  },
  salesDoc: {
    entities: {
      po: {
        amount: '0',
        count: 0,
      },
      invoice: {
        amount: '0',
        count: 0,
      },
      receipt: {
        amount: '0',
        count: 0,
      },
      transfer: {
        amount: '0',
        count: 0,
      },
    },
    isLoading: false,
    error: null,
  },
};

export enum Types {
  cancelRequestAPI = 'super-admin/dashboard@cancelRequestAPI',
  getSalesWeightRequest = 'super-admin/dashboard@getSalesWeightRequest',
  getSalesWeightSuccess = 'super-admin/dashboard@getSalesWeightSuccess',
  getSalesWeightFailure = 'super-admin/dashboard@getSalesWeightFailure',
  getSalesDocRequest = 'super-admin/dashboard@getSalesDocRequest',
  getSalesDocSuccess = 'super-admin/dashboard@getSalesDocSuccess',
  getSalesDocFailure = 'super-admin/dashboard@getSalesDocFailure',
}

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getSalesWeightRequest = createAction<ReportDefaultQueries>(
  Types.getSalesWeightRequest
);
export const getSalesWeightSuccess = createAction<SetWeight>(
  Types.getSalesWeightSuccess
);
export const getSalesWeightFailure = createAction<string>(
  Types.getSalesWeightFailure
);
export const getSalesDocRequest = createAction<ReportDefaultQueries>(
  Types.getSalesDocRequest
);
export const getSalesDocSuccess = createAction<SalesDocCountSummaries>(
  Types.getSalesDocSuccess
);
export const getSalesDocFailure = createAction<string>(
  Types.getSalesDocFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    return { ...state, isLoading: false, isCreating: false };
  });
  builder
    .addCase(getSalesWeightRequest, (state) => {
      state.weight.isLoading = true;
      state.weight.error = null;
    })
    .addCase(getSalesWeightSuccess, (state, { payload }) => {
      return {
        ...state,
        weight: {
          ...state.weight,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getSalesWeightFailure, (state, { payload }) => {
      state.weight.isLoading = false;
      state.weight.error = payload;
    });
  builder
    .addCase(getSalesDocRequest, (state) => {
      state.salesDoc.isLoading = true;
      state.salesDoc.error = null;
    })
    .addCase(getSalesDocSuccess, (state, { payload }) => {
      state.salesDoc.isLoading = false;
      state.salesDoc.entities = payload;
    })
    .addCase(getSalesDocFailure, (state, { payload }) => {
      state.weight.isLoading = false;
      state.weight.error = payload;
    });
});

export default reducer;
