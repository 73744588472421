import { createReducer, createAction } from '@reduxjs/toolkit';
import { UpdateProfileForm, UpdateMyPasswordForm } from 'models';

type dialogId = null | 'updatePassword' | 'updateProfile';
export type InitialState = {
  isLoading: boolean;
  isAfterUpdate: boolean;
  isUpdating: boolean;
  dialogId: dialogId;
  error: string | null;
};

const initialState: InitialState = {
  isLoading: false,
  isAfterUpdate: false,
  isUpdating: false,
  dialogId: null,
  error: null,
};

export const enum Types {
  cancelRequestAPI = 'auth@cancelRequestAPI',
  updatePasswordRequest = 'auth@updatePasswordRequest',
  updatePasswordSuccess = 'auth@updatePasswordSuccess',
  updatePasswordFailure = 'auth@updatePasswordFailure',
  updateProfileRequest = 'auth@updateProfileRequest',
  updateProfileSuccess = 'auth@updateProfileSuccess',
  updateProfileFailure = 'auth@updateProfileFailure',
  setIsAfterUpdate = 'auth@setIsAfterUpdate',
  onChangeDialogId = 'auth@onChangeDialogId',
}
type Error = string | null;
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);

export const updatePasswordRequest = createAction<UpdateMyPasswordForm>(
  Types.updatePasswordRequest
);
export const updatePasswordSuccess = createAction(Types.updatePasswordSuccess);
export const updatePasswordFailure = createAction<Error>(
  Types.updatePasswordFailure
);
export const updateProfileRequest = createAction<UpdateProfileForm>(
  Types.updateProfileRequest
);
export const updateProfileSuccess = createAction<UpdateProfileForm>(
  Types.updateProfileSuccess
);
export const updateProfileFailure = createAction<Error>(
  Types.updateProfileFailure
);
export const setIsAfterUpdate = createAction<boolean, Types.setIsAfterUpdate>(
  Types.setIsAfterUpdate
);
export const onChangeDialogId = createAction<dialogId>(Types.onChangeDialogId);

// export const actions = { loginRequest };
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    return { ...state, isAuthenticated: false };
  });
  builder.addCase(updatePasswordRequest, (state) => {
    state.isUpdating = true;
    state.error = null;
  });
  builder.addCase(updatePasswordSuccess, (state) => {
    state.isUpdating = false;
    state.isAfterUpdate = true;
    state.dialogId = null;
  });
  builder.addCase(updatePasswordFailure, (state, { payload }) => {
    state.isUpdating = false;
    state.error = payload;
  });
  builder.addCase(updateProfileRequest, (state) => {
    state.isUpdating = true;
    state.error = null;
  });
  builder.addCase(updateProfileSuccess, (state) => {
    state.isUpdating = false;
    state.dialogId = null;
  });
  builder.addCase(updateProfileFailure, (state, { payload }) => {
    state.isUpdating = false;
    state.error = payload;
  });
  builder.addCase(setIsAfterUpdate, (state, { payload }) => {
    state.isAfterUpdate = payload;
  });
  builder.addCase(onChangeDialogId, (state, { payload }) => {
    state.dialogId = payload;
  });
});

export default reducer;
