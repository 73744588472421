import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  ExpenseType,
  ExpensesTypeFilter,
  CreateExpenseTypeForm,
  DefaultQueries,
  UpdateExpenseTypeForm,
} from 'models';

type DialogId = 'addExpenseType' | 'editExpenseType' | null;
export interface InitialState {
  dialogId: DialogId;
  expenseTypesList: {
    expenseTypes: ExpenseType[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageTokens: string[];
    pageSize: number;
  };
  createExpenseType: {
    isCreating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
  };
  updateExpenseType: {
    isUpdating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
    expenseType: ExpenseType | null;
  };
}
const initialState: InitialState = {
  dialogId: null,
  createExpenseType: {
    error: null,
    isCreating: false,
    isAfterSubmitted: false,
  },
  expenseTypesList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    expenseTypes: [],
  },
  updateExpenseType: {
    error: null,
    isAfterSubmitted: false,
    expenseType: null,
    isUpdating: false,
  },
};
export enum Types {
  onChangeDialogId = 'super-admin/expense-types@onChangeDialogId',
  cancelRequestAPI = 'super-admin/expense-types@cancelRequestAPI',
  getExpenseTypesRequest = 'super-admin/expense-types@getExpenseTypesRequest',
  getExpenseTypesSuccess = 'super-admin/expense-types@getExpenseTypesSuccess',
  getExpenseTypesFailure = 'super-admin/expense-types@getExpenseTypesFailure',
  onChangeExpenseTypesPage = 'super-admin/expense-types@onChangeExpenseTypesPage',
  onChangeExpenseTypesPageSize = 'super-admin/expense-types@onChangeExpenseTypesPageSize',
  createExpenseTypeRequest = 'super-admin/expense-types@createExpenseTypeRequest',
  createExpenseTypeSuccess = 'super-admin/expense-types@createExpenseTypeSuccess',
  createExpenseTypeFailure = 'super-admin/expense-types@createExpenseTypeFailure',
  afterCreateExpenseTypeSuccess = 'super-admin/expense-types@afterCreateExpenseTypeSuccess',
  updateExpenseTypeRequest = 'super-admin/expense-types@updateExpenseTypeRequest',
  updateExpenseTypeSuccess = 'super-admin/expense-types@updateExpenseTypeSuccess',
  updateExpenseTypeFailure = 'super-admin/expense-types@updateExpenseTypeFailure',
  afterUpdateExpenseTypeSuccess = 'super-admin/expense-types@afterUpdateExpenseTypeSuccess',
  setExpenseType = 'super-admin/expense-types@setExpenseType',
}
export type GetExpenseTypesSuccess = DefaultQueries & {
  expenseTypes: ExpenseType[];
};
type ExpensesTypesQueries = DefaultQueries & ExpensesTypeFilter;

export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);
export const getExpenseTypesRequest = createAction<ExpensesTypesQueries>(
  Types.getExpenseTypesRequest
);
export const getExpenseTypesSuccess = createAction<GetExpenseTypesSuccess>(
  Types.getExpenseTypesSuccess
);
export const getExpenseTypesFailure = createAction<string>(
  Types.getExpenseTypesFailure
);
export const onChangeExpenseTypesPage = createAction<ExpensesTypesQueries>(
  Types.onChangeExpenseTypesPage
);
export const onChangeExpenseTypesPageSize = createAction<ExpensesTypesQueries>(
  Types.onChangeExpenseTypesPageSize
);
export const createExpenseTypeRequest = createAction<CreateExpenseTypeForm>(
  Types.createExpenseTypeRequest
);
export const createExpenseTypeSuccess = createAction(
  Types.createExpenseTypeSuccess
);
export const createExpenseTypeFailure = createAction<string>(
  Types.createExpenseTypeFailure
);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const afterCreateExpenseTypeSuccess = createAction(
  Types.afterCreateExpenseTypeSuccess
);
export const updateExpenseTypeRequest = createAction<UpdateExpenseTypeForm>(
  Types.updateExpenseTypeRequest
);
export const updateExpenseTypeSuccess = createAction(
  Types.updateExpenseTypeSuccess
);
export const updateExpenseTypeFailure = createAction<string>(
  Types.updateExpenseTypeFailure
);
export const afterUpdateExpenseTypeSuccess = createAction(
  Types.afterUpdateExpenseTypeSuccess
);
export const setExpenseType = createAction<ExpenseType>(Types.setExpenseType);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(onChangeDialogId, (state, action) => {
      state.dialogId = action.payload;
    })
    .addCase(getExpenseTypesRequest, (state) => {
      state.expenseTypesList.isLoading = true;
      state.expenseTypesList.error = null;
    })
    .addCase(getExpenseTypesSuccess, (state, action) => {
      return {
        ...state,
        expenseTypesList: {
          ...state.expenseTypesList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getExpenseTypesFailure, (state, action) => {
      state.expenseTypesList.isLoading = false;
      state.expenseTypesList.error = action.payload;
    })
    .addCase(createExpenseTypeRequest, (state) => {
      state.createExpenseType.error = null;
      state.createExpenseType.isCreating = true;
    })
    .addCase(createExpenseTypeSuccess, (state) => {
      state.createExpenseType.isCreating = false;
      state.createExpenseType.isAfterSubmitted = true;
      state.dialogId = null;
    })
    .addCase(createExpenseTypeFailure, (state, action) => {
      state.createExpenseType.error = action.payload;
      state.createExpenseType.isCreating = false;
    })
    .addCase(cancelRequestAPI, (state) => {
      state.expenseTypesList.isLoading = false;
      state.createExpenseType.isCreating = false;
    })
    .addCase(afterCreateExpenseTypeSuccess, (state) => {
      state.createExpenseType.isAfterSubmitted = false;
    })
    .addCase(updateExpenseTypeRequest, (state) => {
      state.updateExpenseType.error = null;
      state.updateExpenseType.isUpdating = true;
    })
    .addCase(updateExpenseTypeSuccess, (state) => {
      state.updateExpenseType.isUpdating = false;
      state.updateExpenseType.isAfterSubmitted = true;
      state.dialogId = null;
      state.updateExpenseType.expenseType = null;
    })
    .addCase(updateExpenseTypeFailure, (state, action) => {
      state.updateExpenseType.error = action.payload;
      state.updateExpenseType.isUpdating = false;
    })
    .addCase(afterUpdateExpenseTypeSuccess, (state) => {
      state.updateExpenseType.isAfterSubmitted = false;
    })
    .addCase(setExpenseType, (state, { payload }) => {
      state.updateExpenseType.expenseType = payload;
      state.dialogId = 'editExpenseType';
    });
});

export default reducer;
