import { Button, Paper } from '@mui/material';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      id="auth-layout"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#E5E5E5',
      }}
    >
      <Paper
        sx={{
          width: {
            md: '450px',
            sm: '100vh',
            xs: '100vh',
          },
          height: {
            md: '583px',
            sm: '100vh',
            xs: '100vh',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography align="center" variant="h1" sx={{ mb: 1 }}>
          404
        </Typography>
        <Typography align="center" variant="h4" sx={{ mb: 6 }}>
          ບໍ່ພົບໜ້າທີ່ຕ້ອງການ.
        </Typography>
        <Button color="error" variant="outlined" onClick={() => navigate('/')}>
          ກັບຄືນ
        </Button>
      </Paper>
    </Box>
  );
};

export default PageNotFound;
