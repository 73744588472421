import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import {
  findErrorToData,
  joinParamsToStringHttpRequest,
  setPageInfo,
  prevOrNextCursorPage,
  getAllDataWithCursorNextPage,
} from 'utils';
import { SummaryExpenses } from 'models/superAdminReport';
import { Tier } from 'models/tier';

let cancels: Canceler[] = [];

function* getSummaryExpenses({
  payload,
}: ReturnType<typeof actions.getSummaryExpensesRequest>) {
  try {
    const entries: SummaryExpenses[] = yield getAllDataWithCursorNextPage<
      SummaryExpenses[],
      'expenseTypes'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/finance/report/summary-expense-type${queries}`, {
          params: { ...payload },
        }),
      keyResponse: 'expenseTypes',
    });
    yield put(
      actions.getSummaryExpensesSuccess(
        entries.sort((a, b) => {
          const nameA = a.type.title.toUpperCase(); // ignore upper and lowercase
          const nameB = b.type.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })
      )
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSummaryExpensesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getExpenses({
  payload,
}: ReturnType<typeof actions.getExpensesRequest>) {
  const getSummary: SummaryExpenses = JSON.parse(payload.summary || '');
  if (typeof getSummary !== 'object') {
    yield put(
      actions.getExpensesFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
      })
    );
    return;
  }
  if (Object.keys(getSummary).length !== 2) {
    yield put(
      actions.getExpensesFailure('ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.')
    );
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ,ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.',
        serviceType: 'snackbar',
        type: 'error',
      })
    );
    return;
  }
  const { expenses }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.expenses
  );
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || expenses.pageSize,
      },
      keysParams: ['pageToken', 'pageSize', 'tier', 'startedAt', 'endedAt'],
    });
    const { data } = yield call(
      API.get,
      `/v1/finance/report/expenses?${queries}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const page = setPageInfo(
      {
        page: expenses.page,
        pageSize: expenses.pageSize,
        pageTokens: expenses.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    yield put(
      actions.getExpensesSuccess({
        ...page,
        entities: data.expenses,
        summary: getSummary,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getExpensesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeExpensesPage({
  payload,
}: ReturnType<typeof actions.onChangeExpensesPage>) {
  const { expenses }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.expenses
  );

  const pageInfo = prevOrNextCursorPage({
    nextOrPrev: payload.nextOrPrev === 'prev' ? 'prev' : 'next',
    page: expenses.page,
    pageTokens: expenses.pageTokens,
  });

  yield put(actions.getExpensesRequest({ ...payload, ...pageInfo }));
}
function* exportExpensesCsv({
  payload,
}: ReturnType<typeof actions.exportExpensesCsvRequest>) {
  const { expenses }: actions.InitialState = yield select(
    (state) => state.superAdmin.report.expenses
  );
  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || expenses.pageSize,
      },
      keysParams: ['tier', 'startedAt', 'endedAt'],
    });
    const { data } = yield call(
      API.post,
      `/v1/sale/invoices-exportCsv`,
      queries
    );
    const blob = new Blob([data], {
      type: 'text/plain;charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍງານການອອກໃບຮຽກເກັບເງິນ ${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportExpensesCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportExpensesCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetSummaryExpensesRequest() {
  yield takeLatest(actions.Types.getSummaryExpensesRequest, getSummaryExpenses);
}
function* watchGetExpenses() {
  yield takeLatest(actions.Types.getExpensesRequest, getExpenses);
}
function* watchOnChangeExpensesPage() {
  yield takeLatest(actions.Types.onChangeExpensesPage, onChangeExpensesPage);
}
function* watchOnChangeExpensesPageSize() {
  yield takeLatest(
    actions.Types.onChangeExpensesPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeExpensesPageSize>) {
      yield put(actions.getExpensesRequest({ ...payload }));
    }
  );
}
function* getTiers() {
  try {
    const tiers: Tier[] = yield getAllDataWithCursorNextPage<Tier[], 'tiers'>({
      httpRequest: (queries) => API.get(`/v1/admin/tier/tiers${queries}`),
      keyResponse: 'tiers',
    });
    yield put(actions.getTiersSuccess(tiers));
  } catch (error) {
    yield put(actions.getTiersFailure());
  }
}
function* watchExportExpensesCsv() {
  yield takeLatest(actions.Types.exportExpensesCsvRequest, exportExpensesCsv);
}
function* watchGetTiers() {
  yield takeLatest(actions.Types.getTiersRequest, getTiers);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetSummaryExpensesRequest),
    fork(watchGetExpenses),
    fork(watchOnChangeExpensesPage),
    fork(watchOnChangeExpensesPageSize),
    fork(watchExportExpensesCsv),
    fork(watchGetTiers),
  ]);
}
export default saga;
