import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
} from 'redux-saga/effects';
import { nanoid } from '@reduxjs/toolkit';
// import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData } from 'utils';
import { RootState } from 'store';
import {
  GetWalletAccountsResponse,
  GetWalletStatementsResponse,
  WalletAccount,
  WalletDepositCreate,
  WalletTransferCreate,
  GetWalletAccountsAdminResponse,
} from 'models';

let cancels: Canceler[] = [];

const getAccountsAPI = (tierName: string) =>
  API.get(`/v1/tiers/${tierName}/wallets`, {
    cancelToken: new CancelToken((c) => cancels.push(c)),
  });
const getStatementsAPI = (accountId: string, query: string) =>
  API.get(`/v1/wallet/accounts/${accountId}/transactions?${query}`, {
    cancelToken: new CancelToken((c) => cancels.push(c)),
  });
const createTransactionDepositAPI = (data: WalletDepositCreate) =>
  API.post(`/v1/wallet/accounts-deposit`, data);
const createTransactionTransferAPI = (data: WalletTransferCreate) =>
  API.post(`/v1/transfer/transactions`, data);

type AccountsAction =
  | ReturnType<typeof actions.getWalletAccountsOfTierSuccess>
  | ReturnType<typeof actions.getWalletAccountsOfTierFailure>;
type StatementsActions =
  | ReturnType<typeof actions.getWalletStatementsSuccess>
  | ReturnType<typeof actions.getWalletStatementsFailure>;
function* firstRenderPage() {
  yield put(actions.getWalletAccountsOfTierRequest());
  const accountsAction: AccountsAction = yield take([
    actions.Types.getWalletAccountsOfTierSuccess,
    actions.Types.getWalletAccountsOfTierFailure,
  ]);
  if (accountsAction.type === actions.Types.getWalletAccountsOfTierFailure) {
    yield put(actions.firstRenderPageFailure(accountsAction.payload));
    return;
  }
  if (accountsAction.payload.length === 0) {
    yield put(actions.firstRenderPageSuccess());
    return;
  }
  yield put(
    actions.getWalletStatementsRequest({
      accountNumber: accountsAction.payload[0].number,
    })
  );
  const statementsActions: StatementsActions = yield take([
    actions.Types.getWalletStatementsSuccess,
    actions.Types.getWalletStatementsFailure,
  ]);
  if (statementsActions.type === actions.Types.getWalletStatementsFailure) {
    yield put(actions.firstRenderPageFailure(statementsActions.payload));
    return;
  }
  yield put(actions.firstRenderPageSuccess());
}
function* getWalletAccountsOfTier() {
  const { auth }: RootState = yield select((state) => state);
  try {
    const { data }: GetWalletAccountsResponse = yield call(
      getAccountsAPI,
      auth.accept.tier?.name || ''
    );
    yield put(actions.getWalletAccountsOfTierSuccess(data.wallets));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getWalletAccountsOfTierFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getWalletStatements({
  payload,
}: ReturnType<typeof actions.getWalletStatementsRequest>) {
  const {
    list: { statement },
  }: actions.InitialState = yield select((state) => state.tierWallet);
  const accountId = payload.accountNumber
    ? payload.accountNumber
    : statement.accountNumber;
  let query = `pageSize=${
    payload.pageSize ? payload.pageSize : statement.pageSize
  }`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  let statementData: actions.GetWalletStatementsSuccess = {
    pageTokens: [...statement.pageTokens],
  };
  try {
    const { data }: GetWalletStatementsResponse = yield call(
      getStatementsAPI,
      accountId,
      query
    );
    const pageToken = statement.pageTokens.find(
      (p) => p === data.nextPageToken
    );
    statementData.data = data.transactions;
    if (
      !pageToken &&
      statementData.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      statementData.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      statementData.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== statement.pageSize) {
      statementData.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      statementData.pageSize = payload.pageSize;
      statementData.page = 1;
    }
    if (payload.accountNumber) {
      statementData.accountNumber = payload.accountNumber;
    }
    yield put(actions.getWalletStatementsSuccess(statementData));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(
        actions.getWalletStatementsFailure(
          errorData.serviceType === 'pageComponent' ? errorData.message : null
        )
      );
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type StatementOnChangePage = ReturnType<typeof actions.statementOnChangePage>;
function* statementOnChangePage({ payload }: StatementOnChangePage) {
  const {
    list: {
      statement: { pageTokens, page },
    },
  }: actions.InitialState = yield select((state) => state.tierWallet);
  let cloneFilters: any = {};
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getWalletStatementsRequest(cloneFilters));
}
type StatementOnChangePageSize = ReturnType<
  typeof actions.statementOnChangePageSize
>;
function* statementOnChangePageSize({ payload }: StatementOnChangePageSize) {
  yield put(actions.getWalletStatementsRequest(payload));
}
function* onChangeTabAccount(
  action: ReturnType<typeof actions.onChangeTabAccount>
) {
  yield put(
    actions.getWalletStatementsRequest({
      accountNumber: action.payload,
      pageSize: 25,
    })
  );
}
function* createTransactionDeposit(data: WalletDepositCreate) {
  try {
    yield call(createTransactionDepositAPI, data);
    yield put(actions.createTransactionSuccess(''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(
        actions.createTransactionFailure(
          errorData.serviceType === 'pageComponent' ? errorData.message : null
        )
      );
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createTransactionTransfer(data: WalletTransferCreate) {
  try {
    yield call(createTransactionTransferAPI, data);
    yield put(actions.createTransactionSuccess(''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(
        actions.createTransactionFailure(
          errorData.serviceType === 'pageComponent' ? errorData.message : null
        )
      );
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type CreateTransactionActions =
  | ReturnType<typeof actions.createTransactionSuccess>
  | ReturnType<typeof actions.createTransactionFailure>;
function* createTransaction({
  payload,
}: ReturnType<typeof actions.createTransactionRequest>) {
  const {
    list: { accounts },
  }: actions.InitialState = yield select((state) => state.tierWallet);
  const { transaction } = payload;
  const account = accounts.find(
    (account) => account.number === transaction.accountNumber
  );
  let data: WalletDepositCreate | WalletTransferCreate = {
    accountNumber: transaction.accountNumber,
    description: transaction.description,
    money: {
      amount: transaction.amount,
      currency: account?.currency || 'LAK',
    },
    referenceId: nanoid(18),
  };
  if (transaction.type === 'transfer') {
    data = {
      fromAccountNumber: transaction.accountNumber,
      toAccountNumber: transaction.toAccountNumber,
      description: transaction.description,
      money: {
        amount: transaction.amount,
        currency: account?.currency || 'LAK',
      },
      referenceId: nanoid(18),
    };
  }

  if (transaction.type === 'transfer' && 'toAccountNumber' in data) {
    yield fork(createTransactionTransfer, data);
  }
  if (transaction.type === 'deposit' && 'accountNumber' in data) {
    yield fork(createTransactionDeposit, data);
  }
  const { type }: CreateTransactionActions = yield take([
    actions.createTransactionSuccess,
    actions.createTransactionRequest,
  ]);
  if (type === actions.Types.createTransactionSuccess) {
    yield put(
      addAlert({
        message:
          transaction.type === 'deposit'
            ? 'ເຕີມເງິນສຳເລັດແລ້ວ'
            : 'ໂອນສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.firstRenderPageRequest({}));
  }
}
const getWalletAccounts = async (query?: string) => {
  let accounts: WalletAccount[] = [];
  let nextPageToken = '';
  let error: boolean = false;
  do {
    try {
      let queries = query || '';
      if (nextPageToken.length !== 0) {
        queries = queries + `&pageToken=${nextPageToken}`;
      }
      const { data }: GetWalletAccountsAdminResponse = await API.get(
        `/v1/admin/wallet/accounts?pageSize=250${queries}`
      );
      accounts = accounts.concat(data.accounts);
      nextPageToken = data.nextPageToken;
      queries = query || '';
    } catch (err: any) {
      error = true;
      nextPageToken = '';
    }
  } while (nextPageToken.length !== 0);
  if (!error) {
    return accounts;
  }
  throw new Error('error');
};
function* openDialogFormCreate() {
  const {
    tierWallet: { create },
    auth: {
      accept: { resourceAccess },
    },
  }: RootState = yield select((state) => state);
  if (create.accounts.length !== 0) {
    yield actions.getWalletAccountsFailure(null);
    return;
  }
  if (!resourceAccess['admin-wallets'].read || create.accounts.length !== 0) {
    yield put(actions.setWalletAccounts(create.accounts));
    return;
  }
  try {
    const accounts: WalletAccount[] = yield call(getWalletAccounts);
    yield put(actions.setWalletAccounts(accounts));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getWalletAccountsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCreateTransaction() {
  yield takeLatest(actions.Types.createTransactionRequest, createTransaction);
}
function* watchOpenDialogFormCreate() {
  yield takeLatest(actions.Types.openDialogFormCreate, openDialogFormCreate);
}
function* watchOnChangeTabAccount() {
  yield takeLatest(actions.Types.onChangeTabAccount, onChangeTabAccount);
}
function* watchStatementOnChangePage() {
  yield takeLatest(actions.Types.statementOnChangePage, statementOnChangePage);
}
function* watchStatementOnChangePageSize() {
  yield takeLatest(
    actions.Types.statementOnChangePageSize,
    statementOnChangePageSize
  );
}
function* watchGetWalletStatements() {
  yield takeLatest(
    actions.Types.getWalletStatementsRequest,
    getWalletStatements
  );
}
function* watchFirstRenderPage() {
  yield takeLatest(actions.Types.firstRenderPageRequest, firstRenderPage);
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetWalletsOfTier() {
  yield takeLatest(
    actions.Types.getWalletAccountsOfTierRequest,
    getWalletAccountsOfTier
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchFirstRenderPage),
    fork(watchGetWalletStatements),
    fork(watchStatementOnChangePage),
    fork(watchStatementOnChangePageSize),
    fork(watchOnChangeTabAccount),
    fork(watchCreateTransaction),
    fork(watchOpenDialogFormCreate),
    fork(watchGetWalletsOfTier),
  ]);
}
export default saga;
