import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  Customer,
  CustomersFilter,
  DefaultQueries,
  CreateCustomerForm,
} from 'models';
export interface InitialState {
  customersList: {
    customers: Customer[];
    isLoading: boolean;
    error: string | null;
    pageTokens: string[];
    pageSize: number;
    page: number;
  };
  createCustomer: {
    error: string | null;
    isCreating: boolean;
  };
  updateCustomer: {
    error: string | null;
    isUpdating: boolean;
    customer: Customer | null;
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  customersList: {
    error: null,
    isLoading: false,
    customers: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  createCustomer: {
    error: null,
    isCreating: false,
  },
  updateCustomer: {
    error: null,
    customer: null,
    isLoading: false,
    isUpdating: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/customers@cancelRequestAPI',
  getCustomersRequest = 'tier/customers@getCustomersRequest',
  getCustomersSuccess = 'tier/customers@getCustomersSuccess',
  getCustomersFailure = 'tier/customers@getCustomersFailure',
  onChangeCustomersPage = 'tier/customers@onChangeCustomersPage',
  onChangeCustomersPageSize = 'tier/customers@onChangeCustomersPageSize',
  createCustomerRequest = 'tier/customers@createCustomerRequest',
  createCustomerSuccess = 'tier/customers@createCustomerSuccess',
  createCustomerFailure = 'tier/customers@createCustomerFailure',
  getCustomerRequest = 'tier/customers@getCustomerRequest',
  getCustomerSuccess = 'tier/customers@getCustomerSuccess',
  getCustomerFailure = 'tier/customers@getCustomerFailure',
  updateCustomerRequest = 'tier/customers@updateCustomerRequest',
  updateCustomerSuccess = 'tier/customers@updateCustomerSuccess',
  updateCustomerFailure = 'tier/customers@updateCustomerFailure',
}
export type GetCustomersSuccess = DefaultQueries & {
  customers: Customer[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getCustomersRequest = createAction<CustomersFilter>(
  Types.getCustomersRequest
);
export const getCustomersSuccess = createAction<GetCustomersSuccess>(
  Types.getCustomersSuccess
);
export const getCustomersFailure = createAction<string>(
  Types.getCustomersFailure
);
export const onChangeCustomersPage = createAction<CustomersFilter>(
  Types.onChangeCustomersPage
);
export const onChangeCustomersPageSize = createAction<CustomersFilter>(
  Types.onChangeCustomersPageSize
);
export const createCustomerRequest = createAction<CreateCustomerForm>(
  Types.createCustomerRequest
);
export const createCustomerSuccess = createAction(Types.createCustomerSuccess);
export const createCustomerFailure = createAction<string>(
  Types.createCustomerFailure
);
export const getCustomerRequest = createAction<string>(
  Types.getCustomerRequest
);
export const getCustomerSuccess = createAction<Customer>(
  Types.getCustomerSuccess
);
export const getCustomerFailure = createAction<string>(
  Types.getCustomerFailure
);
export const updateCustomerRequest = createAction<CreateCustomerForm>(
  Types.updateCustomerRequest
);
export const updateCustomerSuccess = createAction(Types.updateCustomerSuccess);
export const updateCustomerFailure = createAction<string>(
  Types.updateCustomerFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      state.customersList.isLoading = false;
      state.customersList.error = null;
      state.createCustomer.isCreating = false;
    })
    .addCase(getCustomersRequest, (state) => {
      state.customersList.isLoading = true;
      state.customersList.error = null;
    })
    .addCase(getCustomersSuccess, (state, { payload }) => {
      return {
        ...state,
        customersList: {
          ...state.customersList,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getCustomersFailure, (state, { payload }) => {
      state.customersList.isLoading = false;
      state.customersList.error = payload;
    })
    .addCase(createCustomerRequest, (state) => {
      state.createCustomer.isCreating = true;
      state.createCustomer.error = null;
    })
    .addCase(createCustomerSuccess, (state) => {
      state.createCustomer.isCreating = false;
    })
    .addCase(createCustomerFailure, (state, { payload }) => {
      state.createCustomer.isCreating = false;
      state.createCustomer.error = payload;
    })
    .addCase(getCustomerRequest, (state) => {
      state.updateCustomer.error = null;
      state.updateCustomer.customer = null;
      state.updateCustomer.isLoading = true;
    })
    .addCase(getCustomerSuccess, (state, { payload }) => {
      state.updateCustomer.customer = payload;
      state.updateCustomer.isLoading = false;
    })
    .addCase(getCustomerFailure, (state, { payload }) => {
      state.updateCustomer.error = payload;
      state.updateCustomer.isLoading = false;
    })
    .addCase(updateCustomerRequest, (state) => {
      state.updateCustomer.error = null;
      state.updateCustomer.isUpdating = true;
    })
    .addCase(updateCustomerSuccess, (state) => {
      state.updateCustomer.isUpdating = false;
      state.updateCustomer.customer = null;
    })
    .addCase(updateCustomerFailure, (state, { payload }) => {
      state.updateCustomer.error = payload;
      state.updateCustomer.isLoading = false;
    });
});

export default reducer;
