import { FC, NamedExoticComponent, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import Admin from 'components/layouts/admin';
import Auth from 'components/layouts/auth';
import routers, { Router, LayoutKey } from 'config/routes';
import { useAppSelector } from 'store/hooks';
import { mappingAcceptRoutes } from 'utils';
import PageNotFound from 'pages/404';
import SuspenseLoading from 'components/PageLoading';
export type Layout = {
  [key in LayoutKey]: NamedExoticComponent;
};
const layout: Layout = {
  ADMIN: Admin,
  AUTH: Auth,
};
const Layouts: FC = () => {
  const {
    accept: { resourceAccess },
  } = useAppSelector((state) => state.auth);
  return (
    <>
      <Routes>
        {Object.keys(layout).map((key) => {
          const Layout = layout[key as LayoutKey];
          return (
            <Route path="/" element={<Layout />} key={key}>
              {mapRoutePath(
                mappingAcceptRoutes(resourceAccess, routers[key as LayoutKey])
              )}
            </Route>
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

const mapRoutePath = (routes: Array<Router>) => {
  return routes.map((route) => {
    if (route.children) {
      return (
        <Route
          index={route.index}
          path={route.path}
          element={
            <Suspense fallback={<SuspenseLoading />}>
              {route.component}
            </Suspense>
          }
          key={route.id}
        >
          {mapRoutePath(route.children)}
        </Route>
      );
    }
    return (
      <Route
        index={route.index}
        key={route.id}
        path={route.path}
        element={
          <Suspense fallback={<SuspenseLoading />}>{route.component}</Suspense>
        }
      />
    );
  });
};

export default Layouts;
