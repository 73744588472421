import {
  all,
  fork,
  put,
  call,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { GetCompanyResponse, CompanyUpdate } from 'models/company';
import { findErrorToData } from 'utils';

let cancels: Canceler[] = [];

function* getCompany() {
  try {
    const { data }: GetCompanyResponse = yield call(
      API.get,
      `/v1/companies/aidc`,
      {
        cancelToken: new CancelToken((c) => cancels.push(c)),
      }
    );
    cancels = [];
    yield put(
      actions.getCompanySuccess({
        ...data.company,
        defaultAccounts: data.company.defaultAccounts.map((account) => {
          const nameAndTitle = account.title.split('||');
          return {
            name: nameAndTitle[0] || '',
            title: nameAndTitle[1] || '',
            currency: account.currency,
            number: account.number,
          };
        }),
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getCompanyFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateCompany({
  payload,
}: ReturnType<typeof actions.updateCompanyRequest>) {
  try {
    const accounts = payload.defaultAccounts
      .filter((account) => (account.number || '').length > 0)
      .map((account) => ({
        currency: account.currency,
        number: account.number,
        title: account.name + `||` + account.title,
      }));
    const company: CompanyUpdate = {
      name: 'aidc',
      title: payload.title,
      address: {
        province: payload.address.province || '',
        village: payload.address.village || '',
        district: payload.address.district || '',
      },
      contact: {
        emailAddresses: payload.emailAddresses
          ? [
              {
                name: '',
                value: payload.emailAddresses,
              },
            ]
          : [],
        phoneNumbers: payload.phoneNumbers ? [payload.phoneNumbers] : [],
      },
      defaultAccounts: accounts,
    };
    yield call(API.post, `/v1/admin/companies-update`, company);
    yield put(actions.updateCompanySuccess(payload));
    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateCompanyFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetCompany() {
  yield takeLatest(actions.Types.getCompanyRequest, getCompany);
}
function* watchUpdateCompany() {
  yield takeLeading(actions.Types.updateCompanyRequest, updateCompany);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetCompany),
    fork(watchUpdateCompany),
  ]);
}
export default saga;
