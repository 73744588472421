import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  RivalPurchasePrice,
  CreateRivalPurchasePriceForm,
  Product,
  RivalPurchasePriceFilters,
} from 'models';

type DialogId = 'addPrice' | 'updatePrice' | null;
type Queries = DefaultQueries & RivalPurchasePriceFilters & {};

export interface InitialState {
  dialogId: DialogId;
  pricesList: {
    prices: RivalPurchasePrice[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageTokens: string[];
    pageSize: number;
  };
  createPrice: {
    isCreating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
  };
  updatePrice: {
    isUpdating: boolean;
    error: string | null;
    isAfterSubmitted: boolean;
    price: RivalPurchasePrice | null;
  };
  productsList: {
    isLoading: boolean;
    products: Product[];
    error: string | null;
  };
}
const initialState: InitialState = {
  dialogId: null,
  createPrice: {
    error: null,
    isCreating: false,
    isAfterSubmitted: false,
  },
  pricesList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    prices: [],
  },
  productsList: {
    isLoading: false,
    products: [],
    error: null,
  },
  updatePrice: {
    error: null,
    isAfterSubmitted: false,
    isUpdating: false,
    price: null,
  },
};
export enum Types {
  onChangeDialogId = 'tier/rivalPurchasePrices@onChangeDialogId',
  cancelRequestAPI = 'tier/rivalPurchasePrices@cancelRequestAPI',
  getPricesRequest = 'tier/rivalPurchasePrices@getPricesRequest',
  getPricesSuccess = 'tier/rivalPurchasePrices@getPricesSuccess',
  getPricesFailure = 'tier/rivalPurchasePrices@getPricesFailure',
  onChangePricesPage = 'tier/rivalPurchasePrices@onChangePricesPage',
  onChangePricesPageSize = 'tier/rivalPurchasePrices@onChangePricesPageSize',
  createPriceRequest = 'tier/rivalPurchasePrices@createPriceRequest',
  createPriceSuccess = 'tier/rivalPurchasePrices@createPriceSuccess',
  createPriceFailure = 'tier/rivalPurchasePrices@createPriceFailure',
  afterCreatePriceSuccess = 'tier/rivalPurchasePrices@afterCreatePriceSuccess',
  updatePriceRequest = 'tier/rivalPurchasePrices@updatePriceRequest',
  updatePriceSuccess = 'tier/rivalPurchasePrices@updatePriceSuccess',
  updatePriceFailure = 'tier/rivalPurchasePrices@updatePriceFailure',
  afterUpdatePriceSuccess = 'tier/rivalPurchasePrices@afterUpdatePriceSuccess',
  getProductsRequest = 'tier/rivalPurchasePrices@getProductsRequest',
  getProductsSuccess = 'tier/rivalPurchasePrices@getProductsSuccess',
  getProductsFailure = 'tier/rivalPurchasePrices@getProductsFailure',
  setPrice = 'tier/rivalPurchasePrices@setPrice',
}
export type GetPricesSuccess = DefaultQueries & {
  prices: RivalPurchasePrice[];
};

export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);
export const getPricesRequest = createAction<Queries>(Types.getPricesRequest);
export const getPricesSuccess = createAction<GetPricesSuccess>(
  Types.getPricesSuccess
);
export const getPricesFailure = createAction<string>(Types.getPricesFailure);
export const onChangePricesPage = createAction<Queries>(
  Types.onChangePricesPage
);
export const onChangePricesPageSize = createAction<Queries>(
  Types.onChangePricesPageSize
);
export const getProductsRequest = createAction(Types.getProductsRequest);
export const getProductsSuccess = createAction<Product[]>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);

export const createPriceRequest = createAction<CreateRivalPurchasePriceForm>(
  Types.createPriceRequest
);
export const createPriceSuccess = createAction(Types.createPriceSuccess);
export const createPriceFailure = createAction<string>(
  Types.createPriceFailure
);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const afterCreatePriceSuccess = createAction(
  Types.afterCreatePriceSuccess
);
export const updatePriceRequest = createAction<CreateRivalPurchasePriceForm>(
  Types.updatePriceRequest
);
export const updatePriceSuccess = createAction(Types.updatePriceSuccess);
export const updatePriceFailure = createAction<string>(
  Types.updatePriceFailure
);
export const afterUpdatePriceSuccess = createAction(
  Types.afterUpdatePriceSuccess
);
export const setPrice = createAction<RivalPurchasePrice>(Types.setPrice);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(onChangeDialogId, (state, action) => {
      state.dialogId = action.payload;
      state.updatePrice.price = null;
    })
    .addCase(getPricesRequest, (state) => {
      state.pricesList.isLoading = true;
      state.pricesList.error = null;
    })
    .addCase(getPricesSuccess, (state, action) => {
      return {
        ...state,
        pricesList: {
          ...state.pricesList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getPricesFailure, (state, action) => {
      state.pricesList.isLoading = false;
      state.pricesList.error = action.payload;
    })
    .addCase(getProductsRequest, (state) => {
      state.productsList.error = null;
      state.productsList.isLoading = true;
    })
    .addCase(getProductsSuccess, (state, { payload }) => {
      state.productsList.isLoading = false;
      state.productsList.products = payload;
    })
    .addCase(getProductsFailure, (state, action) => {
      state.productsList.error = action.payload;
      state.productsList.isLoading = false;
    })
    .addCase(createPriceRequest, (state) => {
      state.createPrice.error = null;
      state.createPrice.isCreating = true;
    })
    .addCase(createPriceSuccess, (state) => {
      state.createPrice.isCreating = false;
      state.createPrice.isAfterSubmitted = true;
      state.dialogId = null;
    })
    .addCase(createPriceFailure, (state, action) => {
      state.createPrice.error = action.payload;
      state.createPrice.isCreating = false;
    })
    .addCase(cancelRequestAPI, (state) => {
      state.pricesList.isLoading = false;
      state.createPrice.isCreating = false;
    })
    .addCase(afterCreatePriceSuccess, (state) => {
      state.createPrice.isAfterSubmitted = false;
    })
    .addCase(updatePriceRequest, (state) => {
      state.updatePrice.error = null;
      state.updatePrice.isUpdating = true;
    })
    .addCase(updatePriceSuccess, (state) => {
      state.updatePrice.isUpdating = false;
      state.updatePrice.isAfterSubmitted = true;
      state.dialogId = null;
      state.updatePrice.price = null;
    })
    .addCase(updatePriceFailure, (state, action) => {
      state.updatePrice.error = action.payload;
      state.updatePrice.isUpdating = false;
    })
    .addCase(afterUpdatePriceSuccess, (state) => {
      state.updatePrice.isAfterSubmitted = false;
    })
    .addCase(setPrice, (state, { payload }) => {
      state.updatePrice.price = payload;
    });
});

export default reducer;
