import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';
import { GetPredictResponse, PostPredictResponse } from 'models';
import { findErrorToData } from 'utils';
function* getPredict() {
  try {
    const { data }: GetPredictResponse = yield call(
      API.get,
      '/v1/admin/cassava-setting-predict'
    );
    yield put(actions.getPredictSuccess(data));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getPredictFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createPredict({
  payload,
}: ReturnType<typeof actions.createPredictRequest>) {
  try {
    const { data }: PostPredictResponse = yield call(
      API.post,
      '/v1/admin/cassava-setting-predict',
      payload.predict.reverse()
    );
    yield put(actions.createPredictSuccess(data));
    yield put(
      addAlert({
        message: 'ເພີ່ມລາຍການສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createPredictFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* watchGetPredictRequest() {
  yield takeLatest(actions.Types.getPredictRequest, getPredict);
}
function* watchCreatePredict() {
  yield takeLatest(actions.Types.createPredictRequest, createPredict);
}
function* saga() {
  yield all([fork(watchGetPredictRequest), fork(watchCreatePredict)]);
}
export default saga;
