import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  takeLeading,
  delay,
  take,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  findErrorToData,
  mappingParamsToPayload,
  getAllDataWithCursorNextPage,
  setPageInfo,
} from 'utils';
import { RootState } from 'store';
import {
  GetSaleInvoicesResponse,
  CreateInvoice,
  SaleInvoicesQueries,
  GetOrdersResponse,
  GetSaleInvoiceResponse,
  GetSaleTransactionsResponse,
  UpdateInvoice,
  GetOrderResponse,
  DefaultQueries,
  SaleTransaction,
} from 'models';
import * as companyActions from 'store/super-admin/company';

let cancels: Canceler[] = [];

function* getInvoices({
  payload,
}: ReturnType<typeof actions.getInvoicesRequest>) {
  const {
    saleInvoices: { invoicesList },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let dataPayload: actions.GetTransactionsSuccess = {
    data: [],
  };
  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: SaleInvoicesQueries & DefaultQueries = {
      ...payloadParams,
      pageSize: pageSize || invoicesList.pageSize,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetSaleInvoicesResponse = yield call(
      API.get,
      `/v1/sale/invoices`,
      {
        cancelToken: cancelToken.token,
        params: params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: invoicesList.page,
        pageSize: invoicesList.pageSize,
        pageTokens: invoicesList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    yield put(
      actions.getInvoicesSuccess({
        ...dataPayload,
        ...pageInfo,
        data: data.invoices,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getInvoicesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeInvoicesListPage({
  payload,
}: ReturnType<typeof actions.onChangeInvoicesListPage>) {
  const {
    invoicesList: { page, pageTokens },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleInvoices
  );
  const { nextOrPrev, ...payloadParams } = payload;
  let params: SaleInvoicesQueries & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getInvoicesRequest(params));
}
function* onChangeInvoicesListPageSize({
  payload,
}: ReturnType<typeof actions.onChangeInvoicesListPageSize>) {
  const {
    invoicesList: { pageSize },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleInvoices
  );
  if (payload.pageSize !== pageSize) {
    yield put(actions.getInvoicesRequest({ ...payload }));
  }
}
function* createInvoice({
  payload,
}: ReturnType<typeof actions.createInvoiceRequest>) {
  const {
    auth,
    saleInvoices: { ordersList },
    router: { location },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/sale/invoices`;

  const order = ordersList.orders.find((o) => o.id === payload.purchaseOrderId);
  if (!order) return;
  const transaction: CreateInvoice = {
    customer: {
      displayName: order.customer.displayName,
      id: order.customer.id,
    },
    description: payload.description || '',
    duedAt: payload.duedAt,
    location: payload.location,
    paymentMethod: order.paymentMethod,
    purchaseOrderId: payload.purchaseOrderId,
    remark: payload.remark || '',
    tier: auth.accept.tier?.name || '',
    type: order.type,
  };
  try {
    yield call(API.post, url, transaction, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.createInvoiceSuccess());
    cancels = [];
    yield put(
      push({
        pathname: (location?.pathname || '').replace('/create', ''),
        search: location?.search,
      })
    );
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createInvoiceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getOrders() {
  const { auth }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/sale/purchaseOrders?tier=${
    auth.accept.tier?.name || ''
  }&pageSize=250&status=PENDING`;
  try {
    const { data }: GetOrdersResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
    });

    yield put(actions.getOrdersSuccess(data.purchaseOrders));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getOrdersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getInvoice({
  payload,
}: ReturnType<typeof actions.getInvoiceRequest>) {
  const { auth }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const invoiceRes: GetSaleInvoiceResponse = yield call(
      API.get,
      `v1/sale/invoices/${payload}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const transactionsRes: GetSaleTransactionsResponse = yield call(
      API.get,
      `/v1/sale/transactions?invoiceId=${payload}&pageSize=250&tier=${
        auth.accept.tier?.name || ''
      }`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const sortTransactions = transactionsRes.data.transactions.sort(
      (a, b) => Number.parseFloat(a.number) - Number.parseFloat(b.number)
    );
    yield put(
      actions.getInvoiceSuccess({
        invoice: invoiceRes.data.invoice,
        transactions: sortTransactions,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getInvoiceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateSummaryInvoice() {
  const {
    saleInvoices: {
      invoiceDetail: { invoice },
    },
  }: RootState = yield select((state: RootState) => state);
  try {
    yield call(API.post, `/v1/sale/invoices-summary`, {
      invoiceId: invoice?.id || '',
    });
    yield put(
      addAlert({
        message: 'ອັບເດດໃບຮຽກເກັບເງິນສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.updateSummaryInvoiceSuccess());
    yield put(actions.getInvoiceRequest(invoice?.id || ''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateSummaryInvoiceFailure('updateInvoiceFailure'));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateInvoice({
  payload,
}: ReturnType<typeof actions.updateInvoiceRequest>) {
  const {
    saleInvoices: {
      updateInvoice: { invoice },
    },
    router: { location },
    auth,
  }: RootState = yield select((state: RootState) => state);
  try {
    const invoiceUpdate: UpdateInvoice = {
      description: payload.description || '',
      duedAt: moment(payload.duedAt).format(),
      id: invoice?.id || '',
      location: payload.location,
      remark: payload.remark || '',
    };
    yield call(API.post, `/v1/sale/invoices-update`, invoiceUpdate);
    yield put(
      addAlert({
        message: 'ອັບເດດໃບຮຽກເກັບເງິນສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.updateInvoiceSuccess());
    yield put(
      push({
        pathname: `/tiers/${auth.accept.tier?.name}/sale/invoices`,
        search: location?.search || '',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateInvoiceFailure('updateInvoiceFailure'));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getInvoiceData({
  payload,
}: ReturnType<typeof actions.getInvoiceDataRequest>) {
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const {
      data: { invoice },
    }: GetSaleInvoiceResponse = yield call(
      API.get,
      `v1/sale/invoices/${payload}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const {
      data: { purchaseOrder },
    }: GetOrderResponse = yield call(
      API.get,
      `/v1/sale/purchaseOrders/${invoice.purchaseOrderId}`
    );
    yield put(
      actions.getInvoiceDataSuccess({
        invoice: invoice,
        order: purchaseOrder,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getInvoiceDataFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* printInvoice() {
  const {
    saleInvoices: {
      printInvoice: { invoice },
    },
    superAdmin: {
      company: { company },
    },
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state) => state);
  if (!invoice) {
    yield put(actions.printInvoiceFailure());
    return;
  }
  try {
    const transactions: SaleTransaction[] = yield getAllDataWithCursorNextPage<
      SaleTransaction,
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/transactions${queries}&invoiceId=${invoice.id}&tier=${
            tier?.name || ''
          }`
        ),
      keyResponse: 'transactions',
    });
    const {
      data: { purchaseOrder },
    }: GetOrderResponse = yield call(
      API.get,
      `/v1/sale/purchaseOrders/${invoice.purchaseOrderId}`
    );

    if (company.data.id.length === 0) {
      yield put(companyActions.getCompanyRequest());
      const companyRes:
        | ReturnType<typeof companyActions.getCompanyFailure>
        | ReturnType<typeof companyActions.getCompanySuccess> = yield take([
        companyActions.Types.getCompanyFailure,
        companyActions.Types.getCompanySuccess,
      ]);
      if (companyRes.type === companyActions.Types.getCompanyFailure) {
        yield put(actions.printInvoiceFailure());
        return;
      }
    }
    const sortTransactions = transactions.sort(
      (a, b) => Number.parseFloat(a.number) - Number.parseFloat(b.number)
    );
    yield put(
      actions.setDataToPrint({
        transactions: sortTransactions,
        order: purchaseOrder,
      })
    );
    yield delay(100);
    yield put(actions.printInvoiceReady());
  } catch (error) {
    yield put(actions.printInvoiceFailure());
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield mappingParamsToPayload({
      values: {
        ...payload,
        tier: accept.tier?.name || '',
      },
      keysParams: [
        'tier',
        'status',
        'customer',
        'startedAt',
        'type',
        'endedAt',
      ],
    });
    const { data } = yield call(
      API.post,
      `/v1/sale/invoices-exportCsv`,
      {},
      { params: queries }
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍການໃບຮຽກເກັບເງິນ${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openCreateInvoicePage() {
  yield put(actions.getOrdersRequest());
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetInvoices() {
  yield takeLatest(actions.Types.getInvoicesRequest, getInvoices);
}
function* watchOnChangeInvoicesListPage() {
  yield takeLatest(
    actions.Types.onChangeInvoicesListPage,
    onChangeInvoicesListPage
  );
}
function* watchOnChangeInvoicesListPageSize() {
  yield takeLatest(
    actions.Types.onChangeInvoicesListPageSize,
    onChangeInvoicesListPageSize
  );
}
function* watchOpenCreateInvoicePage() {
  yield takeLatest(actions.Types.openCreateInvoicePage, openCreateInvoicePage);
}
function* watchCreateInvoice() {
  yield takeLeading(actions.Types.createInvoiceRequest, createInvoice);
}
function* watchGetOrders() {
  yield takeLatest(actions.Types.getOrdersRequest, getOrders);
}
function* watchGetInvoice() {
  yield takeLatest(actions.Types.getInvoiceRequest, getInvoice);
}
function* watchUpdateInvoice() {
  yield takeLeading(actions.Types.updateInvoiceRequest, updateInvoice);
}
function* watchUpdateSummaryInvoice() {
  yield takeLeading(
    actions.Types.updateSummaryInvoiceRequest,
    updateSummaryInvoice
  );
}
function* watchGetInvoiceData() {
  yield takeLatest(actions.Types.getInvoiceDataRequest, getInvoiceData);
}
function* watchPrintInvoice() {
  yield takeLeading(actions.Types.printInvoiceRequest, printInvoice);
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetInvoices),
    fork(watchOnChangeInvoicesListPage),
    fork(watchOnChangeInvoicesListPageSize),
    fork(watchOpenCreateInvoicePage),
    fork(watchCreateInvoice),
    fork(watchGetOrders),
    fork(watchGetInvoice),
    fork(watchUpdateInvoice),
    fork(watchUpdateSummaryInvoice),
    fork(watchGetInvoiceData),
    fork(watchPrintInvoice),
    fork(watchExportCsv),
  ]);
}
export default saga;
