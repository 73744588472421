import { FC, ReactNode, useCallback } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { SuspenseLoading } from 'components';

import { useFirstRenderEffect } from './hooks';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { authorizeRequest } from 'store/auth';
interface Props {
  children: ReactNode;
}

const Authorization: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const accessToken = localStorage.getItem('accessToken');
  useFirstRenderEffect({
    effect: useCallback(() => {
      if (accessToken && window.location.pathname.indexOf('login') === -1) {
        dispatch(authorizeRequest());
      }
    }, [accessToken, dispatch]),
  });
  if (!accessToken && window.location.pathname.indexOf('login') === -1) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (isAuthenticated === 'PENDING') {
    return <SuspenseLoading />;
  }
  return <>{children}</>;
};

export default Authorization;
