import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  User,
  CreateUserForm,
  UsersFilter,
  DefaultQueries,
  Role,
  UpdatePasswordForm,
  Zone,
} from 'models';
export type dialogId = null | 'updatePassword';
export interface InitialState {
  list: {
    users: User[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  create: {
    isCreating: boolean;
    isLoading: boolean;
    error: string | null;
    roles: Role[];
    zones: Zone[];
  };
  update: {
    isUpdating: boolean;
    error: string | null;
    user: User | null;
    isLoading: boolean;
    roles: Role[];
    zones: Zone[];
    isAfterUpdate: boolean;
  };
  dialogId: dialogId;
}
const initialState: InitialState = {
  list: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    users: [],
  },
  create: {
    isCreating: false,
    error: null,
    isLoading: false,
    roles: [],
    zones: [],
  },
  update: {
    isUpdating: false,
    error: null,
    user: null,
    isLoading: false,
    roles: [],
    isAfterUpdate: false,
    zones: [],
  },
  dialogId: null,
};
export enum Types {
  cancelRequestAPI = 'super-admin/users@cancelRequestAPI',
  getUsersRequest = 'super-admin/users@getUsersRequest',
  getUsersSuccess = 'super-admin/users@getUsersSuccess',
  getUsersFailure = 'super-admin/users@getUsersFailure',
  onChangeUsersPage = 'super-admin/users@onChangeUsersPage',
  onChangeUsersPageSize = 'super-admin/users@onChangeUsersPageSize',
  openPageCreateUserRequest = 'super-admin/users@openPageCreateUserRequest',
  openPageCreateUserSuccess = 'super-admin/users@openPageCreateUserSuccess',
  openPageCreateUserFailure = 'super-admin/users@openPageCreateUserFailure',
  createUsersRequest = 'super-admin/users@createUsersRequest',
  createUsersSuccess = 'super-admin/users@createUsersSuccess',
  createUsersFailure = 'super-admin/users@createUsersFailure',
  updateUsersRequest = 'super-admin/users@updateUsersRequest',
  updateUsersSuccess = 'super-admin/users@updateUsersSuccess',
  updateUsersFailure = 'super-admin/users@updateUsersFailure',

  getUserRequest = 'super-admin/users@getUserRequest',
  getUserSuccess = 'super-admin/users@getUserSuccess',
  getUserFailure = 'super-admin/users@getUserFailure',
  setUser = 'super-admin/users@setUser',
  setDialogId = 'super-admin/users@setDialogId',
  updatePasswordRequest = 'super-admin/users@updatePasswordRequest',
  updatePasswordSuccess = 'super-admin/users@updatePasswordSuccess',
  updatePasswordFailure = 'super-admin/users@updatePasswordFailure',
  resetUpdatePasswordForm = 'super-admin/users@resetUpdatePasswordForm',
}
export type GetUsersSuccess = DefaultQueries & {
  users: User[];
};
export type GetUserSuccess = {
  user: User;
  roles: Role[];
  zones: Zone[];
};
export type OpenPageCreateUserSuccess = {
  roles: Role[];
  zones: Zone[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getUsersRequest = createAction<UsersFilter>(Types.getUsersRequest);
export const getUsersSuccess = createAction<GetUsersSuccess>(
  Types.getUsersSuccess
);
export const getUsersFailure = createAction<string>(Types.getUsersFailure);
export const onChangeUsersPage = createAction<UsersFilter>(
  Types.onChangeUsersPage
);
export const onChangeUsersPageSize = createAction<UsersFilter>(
  Types.onChangeUsersPageSize
);
export const createUsersRequest = createAction<CreateUserForm>(
  Types.createUsersRequest
);
export const createUsersSuccess = createAction(Types.createUsersSuccess);
export const createUsersFailure = createAction<string>(
  Types.createUsersFailure
);
export const updateUsersRequest = createAction<CreateUserForm>(
  Types.updateUsersRequest
);
export const updateUsersSuccess = createAction(Types.updateUsersSuccess);
export const updateUsersFailure = createAction<string>(
  Types.updateUsersFailure
);
export const openPageCreateUserRequest = createAction(
  Types.openPageCreateUserRequest
);
export const openPageCreateUserSuccess = createAction<
  OpenPageCreateUserSuccess,
  Types.openPageCreateUserSuccess
>(Types.openPageCreateUserSuccess);
export const openPageCreateUserFailure = createAction<
  string,
  Types.openPageCreateUserFailure
>(Types.openPageCreateUserFailure);
export const getUserRequest = createAction<string>(Types.getUserRequest);
export const getUserSuccess = createAction<GetUserSuccess>(
  Types.getUserSuccess
);
export const getUserFailure = createAction<string>(Types.getUserFailure);
export const setUser = createAction<User>(Types.setUser);
export const setDialogId = createAction<dialogId>(Types.setDialogId);
export const updatePasswordRequest = createAction<UpdatePasswordForm>(
  Types.updatePasswordRequest
);
export const updatePasswordSuccess = createAction(Types.updatePasswordSuccess);
export const updatePasswordFailure = createAction<string>(
  Types.updatePasswordFailure
);
export const resetUpdatePasswordForm = createAction(
  Types.resetUpdatePasswordForm
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(getUsersRequest, (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    })
    .addCase(getUsersSuccess, (state, { payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getUsersFailure, (state, { payload }) => {
      state.list.isLoading = false;
      state.list.error = payload;
    })
    .addCase(createUsersRequest, (state) => {
      state.create.isCreating = true;
      state.create.error = null;
    })
    .addCase(createUsersSuccess, (state) => {
      state.create.isCreating = false;
    })
    .addCase(createUsersFailure, (state, { payload }) => {
      state.create.isCreating = false;
      state.create.error = payload;
    })
    .addCase(openPageCreateUserRequest, (state) => {
      state.create.isLoading = true;
      state.create.error = null;
    })
    .addCase(openPageCreateUserSuccess, (state, { payload }) => {
      state.create.isLoading = false;
      state.create.roles = payload.roles;
      state.create.zones = payload.zones;
    })
    .addCase(openPageCreateUserFailure, (state, { payload }) => {
      state.create.isLoading = false;
      state.create.error = payload;
    })
    .addCase(getUserRequest, (state) => {
      state.update.error = null;
      state.update.isLoading = true;
    })
    .addCase(getUserSuccess, (state, { payload }) => {
      state.update.isUpdating = false;
      state.update.isLoading = false;
      state.update.user = payload.user;
      state.update.roles = payload.roles;
      state.update.zones = payload.zones;
    })
    .addCase(getUserFailure, (state, { payload }) => {
      state.update.error = payload;
      state.update.isLoading = false;
    })
    .addCase(updateUsersRequest, (state) => {
      state.update.isUpdating = true;
      state.update.error = null;
    })
    .addCase(updateUsersSuccess, (state) => {
      state.update.isUpdating = false;
    })
    .addCase(updateUsersFailure, (state, { payload }) => {
      state.update.isUpdating = false;
      state.update.error = payload;
    })
    .addCase(setUser, (state, { payload }) => {
      state.update.user = payload;
      state.dialogId = 'updatePassword';
    })
    .addCase(setDialogId, (state, { payload }) => {
      state.dialogId = payload;
    })
    .addCase(updatePasswordRequest, (state) => {
      state.update.isUpdating = true;
      state.update.error = null;
    })
    .addCase(updatePasswordSuccess, (state) => {
      state.update.isUpdating = false;
      state.update.isAfterUpdate = true;
      state.dialogId = null;
    })
    .addCase(updatePasswordFailure, (state, { payload }) => {
      state.update.isUpdating = false;
      state.update.error = payload;
    })
    .addCase(resetUpdatePasswordForm, (state) => {
      state.update.isAfterUpdate = false;
    });
});

export default reducer;
