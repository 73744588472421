import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  WalletAccount,
  WalletStatement,
  WalletStatementFilter,
  WalletTransactionFormCreate,
} from 'models';
interface Statement {
  isLoading: boolean;
  error: string | null;
  data: WalletStatement[];
  page: number;
  pageSize: number;
  pageTokens: Array<string>;
  accountNumber: string;
}
export type DialogId = 'form_create' | null;
export interface InitialState {
  dialogId: DialogId;
  list: {
    accounts: WalletAccount[];
    isLoading: boolean;
    error: string | null;
    statement: Statement;
  };
  create: {
    isCreating: boolean;
    isLoading: boolean;
    error: string | null;
    accounts: WalletAccount[];
    isSuccess: boolean;
  };
}
const initialState: InitialState = {
  dialogId: null,
  list: {
    accounts: [],
    error: null,
    isLoading: false,
    statement: {
      error: null,
      isLoading: false,
      data: [],
      page: 1,
      pageSize: 25,
      pageTokens: [],
      accountNumber: '',
    },
  },
  create: {
    isCreating: false,
    isLoading: false,
    error: null,
    accounts: [],
    isSuccess: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/wallet@cancelRequestAPI',
  firstRenderPageRequest = 'tier/wallet@firstRenderPageRequest',
  firstRenderPageSuccess = 'tier/wallet@firstRenderPageSuccess',
  firstRenderPageFailure = 'tier/wallet@firstRenderPageFailure',
  getWalletAccountsOfTierRequest = 'tier/wallet@getWalletAccountsOfTierRequest',
  getWalletAccountsOfTierSuccess = 'tier/wallet@getWalletAccountsOfTierSuccess',
  getWalletAccountsOfTierFailure = 'tier/wallet@getWalletAccountsOfTierFailure',
  getWalletStatementsRequest = 'tier/wallet@getWalletStatementsRequest',
  getWalletStatementsSuccess = 'tier/wallet@getWalletStatementsSuccess',
  getWalletStatementsFailure = 'tier/wallet@getWalletStatementsFailure',
  statementOnChangePage = 'tier/wallet@statementOnChangePage',
  statementOnChangePageSize = 'tier/wallet@statementOnChangePageSize',
  onChangeTabAccount = 'tier/wallet@onChangeTabAccount',
  createTransactionRequest = 'tier/wallet@createTransactionRequest',
  createTransactionSuccess = 'tier/wallet@createTransactionSuccess',
  createTransactionFailure = 'tier/wallet@createTransactionFailure',
  onChangeDialogId = 'tier/wallet@onChangeDialogId',
  openDialogFormCreate = 'tier/wallet@openDialogFormCreate',
  setWalletAccounts = 'tier/wallet@setWalletAccounts',
  getWalletAccountsFailure = 'tier/wallet@getWalletAccountsFailure',
  afterCreateTransactionSuccess = 'tier/wallet@afterCreateTransactionSuccess',
}
export type GetWalletStatementsSuccess = OptionalOne<
  Statement,
  keyof Statement
>;

export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const firstRenderPageRequest = createAction<DefaultQueries>(
  Types.firstRenderPageRequest
);
export const firstRenderPageSuccess = createAction(
  Types.firstRenderPageSuccess
);
export const firstRenderPageFailure = createAction<string | null>(
  Types.firstRenderPageFailure
);
export const getWalletAccountsOfTierRequest = createAction(
  Types.getWalletAccountsOfTierRequest
);
export const getWalletAccountsOfTierSuccess = createAction<
  WalletAccount[],
  Types.getWalletAccountsOfTierSuccess
>(Types.getWalletAccountsOfTierSuccess);
export const getWalletAccountsFailure = createAction<
  string | null,
  Types.getWalletAccountsFailure
>(Types.getWalletAccountsFailure);
export const getWalletStatementsRequest = createAction<WalletStatementFilter>(
  Types.getWalletStatementsRequest
);
export const getWalletStatementsSuccess = createAction<
  GetWalletStatementsSuccess,
  Types.getWalletStatementsSuccess
>(Types.getWalletStatementsSuccess);
export const getWalletStatementsFailure = createAction<
  string | null,
  Types.getWalletStatementsFailure
>(Types.getWalletStatementsFailure);
export const statementOnChangePage = createAction<WalletStatementFilter>(
  Types.statementOnChangePage
);
export const statementOnChangePageSize = createAction<WalletStatementFilter>(
  Types.statementOnChangePageSize
);
export const onChangeTabAccount = createAction<
  string,
  Types.onChangeTabAccount
>(Types.onChangeTabAccount);

type CreateTransactionRequest = {
  transaction: WalletTransactionFormCreate;
};
export const createTransactionRequest = createAction<CreateTransactionRequest>(
  Types.createTransactionRequest
);
export const createTransactionFailure = createAction<
  string | null,
  Types.createTransactionFailure
>(Types.createTransactionFailure);
export const onChangeDialogId = createAction<DialogId, Types.onChangeDialogId>(
  Types.onChangeDialogId
);
export const openDialogFormCreate = createAction(Types.openDialogFormCreate);
export const createTransactionSuccess = createAction<
  string,
  Types.createTransactionSuccess
>(Types.createTransactionSuccess);
export const setWalletAccounts = createAction<
  WalletAccount[],
  Types.setWalletAccounts
>(Types.setWalletAccounts);
export const getWalletAccountsOfTierFailure = createAction<
  string | null,
  Types.getWalletAccountsOfTierFailure
>(Types.getWalletAccountsOfTierFailure);

export const afterCreateTransactionSuccess = createAction(
  Types.afterCreateTransactionSuccess
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(firstRenderPageRequest, (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    })
    .addCase(firstRenderPageSuccess, (state) => {
      state.list.isLoading = false;
    })
    .addCase(firstRenderPageFailure, (state, action) => {
      state.list.isLoading = false;
      state.list.error = action.payload;
    })
    .addCase(getWalletAccountsOfTierRequest, (state) => {
      state.list.isLoading = true;
      state.list.error = null;
      state.list.accounts = [];
    })
    .addCase(getWalletAccountsOfTierSuccess, (state, action) => {
      state.list.accounts = action.payload;
      state.list.isLoading = false;
    })
    .addCase(getWalletAccountsOfTierFailure, (state, action) => {
      state.list.error = action.payload;
      state.list.isLoading = false;
    })
    .addCase(getWalletStatementsRequest, (state) => {
      state.list.statement.isLoading = true;
      state.list.statement.error = null;
    })

    .addCase(getWalletStatementsSuccess, (state, action) => {
      state.list.statement = {
        ...state.list.statement,
        ...action.payload,
      };
      state.list.statement.isLoading = false;
    })
    .addCase(getWalletStatementsFailure, (state, { payload }) => {
      state.list.statement.isLoading = false;
      state.list.statement.error = payload;
    })
    .addCase(createTransactionRequest, (state) => {
      state.create.isCreating = true;
      state.create.error = null;
      state.create.isSuccess = false;
    })
    .addCase(createTransactionSuccess, (state) => {
      state.create.isCreating = false;
      state.create.isSuccess = true;
      state.dialogId = null;
    })
    .addCase(createTransactionFailure, (state, { payload }) => {
      state.create.isCreating = false;
      state.create.error = payload;
      state.create.isSuccess = false;
    })
    .addCase(onChangeDialogId, (state, { payload }) => {
      state.dialogId = payload;
    })
    .addCase(openDialogFormCreate, (state) => {
      state.dialogId = 'form_create';
    })
    .addCase(setWalletAccounts, (state, { payload }) => {
      state.create.accounts = payload;
      state.create.isLoading = false;
    })
    .addCase(afterCreateTransactionSuccess, (state) => {
      state.create.isSuccess = false;
    });
});

export default reducer;
