import {
  all,
  fork,
  put,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  CreateExpense,
  GetExpensesResponse,
  GetExpenseTypesResponse,
  UpdateExpense,
  ExpensesFilter,
  DefaultQueries,
} from 'models';
import { findErrorToData, setPageInfo } from 'utils';
import { addAlert } from 'store/notify';
import { nanoid } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
let cancels: Canceler[] = [];

function* getExpenses({
  payload,
}: ReturnType<typeof actions.getExpensesRequest>) {
  const { expensesList }: actions.InitialState = yield select(
    (state) => state.expenses
  );

  const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
  let params: ExpensesFilter & DefaultQueries = {
    ...payloadParams,
    pageSize: pageSize || expensesList.pageSize,
  };
  if (startedAt && !endedAt) {
    params.createdAt = startedAt;
  }
  if (startedAt && endedAt) {
    params.startedAt = startedAt;
    params.endedAt = endedAt;
  }
  try {
    const { data }: GetExpensesResponse = yield call(
      API.get,
      `/v1/finance/expenses`,
      {
        params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: expensesList.page,
        pageSize: expensesList.pageSize,
        pageTokens: expensesList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetExpensesSuccess = {
      ...pageInfo,
      expenses: data.expenses,
    };
    yield put(actions.getExpensesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getExpensesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeExpensesPage({
  payload,
}: ReturnType<typeof actions.onChangeExpensesPage>) {
  const {
    expensesList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.expenses);
  const { nextOrPrev, ...payloadParams } = payload;
  let params: ExpensesFilter & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getExpensesRequest(params));
}
function* createExpense({
  payload,
}: ReturnType<typeof actions.createExpensesRequest>) {
  const {
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state) => state);
  const expense: CreateExpense = {
    amount: payload.amount,
    occurredAt: payload.occurredAt,
    remark: payload.remark || '',
    title: payload.title,
    typeName: payload.type,
    referenceId: nanoid(18),
    tier: tier?.name || '',
  };
  try {
    yield call(API.post, `/v1/finance/expenses`, expense, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.createExpensesSuccess());
    cancels = [];
    yield put(actions.getExpensesRequest({}));
    yield put(
      addAlert({
        message: 'ສ້າງສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createExpensesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateExpense({
  payload,
}: ReturnType<typeof actions.updateExpensesRequest>) {
  const {
    expenses: { updateExpense },
  }: RootState = yield select((state) => state);

  const expense: UpdateExpense = {
    amount: payload.amount,
    occurredAt: payload.occurredAt,
    remark: payload.remark || '',
    title: payload.title,
    typeName: payload.type,
    referenceId: nanoid(18),
    id: updateExpense.expense?.id || '',
  };
  try {
    yield call(API.post, `/v1/finance/expenses-update`, expense, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.updateExpensesSuccess());
    cancels = [];
    yield put(actions.getExpensesRequest({}));
    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateExpensesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getExpenseTypes() {
  try {
    const { data }: GetExpenseTypesResponse = yield call(
      API.get,
      `/v1/finance/expenseTypes?pageSize=250`
    );
    yield put(actions.getExpenseTypeSuccess(data.expenseTypes));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getExpenseTypeFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openDialogForm({
  payload,
}: ReturnType<typeof actions.onChangeDialogId>) {
  const {
    auth: {
      accept: { resourceAccess },
    },
    expenses: { expenseTypeList },
  }: RootState = yield select((state) => state);
  if (
    resourceAccess['expenseTypes'].read &&
    expenseTypeList.expenseType.length === 0 &&
    (payload === 'addExpense' || payload === 'updateExpense')
  ) {
    yield put(actions.getExpenseTypeRequest());
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetExpenses() {
  yield takeLeading(actions.Types.getExpensesRequest, getExpenses);
}
function* watchOnChangeExpensesPage() {
  yield takeLatest(actions.Types.onChangeExpensesPage, onChangeExpensesPage);
}
function* watchOnChangeExpensesPageSize() {
  yield takeLatest(
    actions.Types.onChangeExpensesPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeExpensesPageSize>) {
      yield put(actions.getExpensesRequest(payload));
    }
  );
}
function* watchCreateExpense() {
  yield takeLeading(actions.Types.createExpensesRequest, createExpense);
}
function* watchGetExpenseTypes() {
  yield takeLatest(actions.Types.getExpenseTypeRequest, getExpenseTypes);
}
function* watchUpdateExpense() {
  yield takeLeading(actions.Types.updateExpensesRequest, updateExpense);
}
function* watchOpenDialogForm() {
  yield takeLeading(actions.Types.onChangeDialogId, openDialogForm);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetExpenses),
    fork(watchCreateExpense),
    fork(watchOnChangeExpensesPage),
    fork(watchOnChangeExpensesPageSize),
    fork(watchGetExpenseTypes),
    fork(watchUpdateExpense),
    fork(watchOpenDialogForm),
  ]);
}
export default saga;
