import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  WalletAccount,
  WalletsAccountsSuperAdminFilter as AccountsFilter,
  CreateWalletAccountForm,
  Tier,
} from 'models';
export type DialogId = 'createAccount' | null;
export interface InitialState {
  accountsList: {
    accounts: WalletAccount[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  create: {
    isCreating: boolean;
    error: string | null;
  };
  dialogId: DialogId;
  tiersList: {
    tiers: Tier[];
    isLoading: boolean;
    error: string | null;
  };
}
const initialState: InitialState = {
  accountsList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    accounts: [],
  },
  create: {
    isCreating: false,
    error: null,
  },
  dialogId: null,
  tiersList: {
    error: null,
    isLoading: false,
    tiers: [],
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/wallet-accounts@cancelRequestAPI',
  getAccountsRequest = 'super-admin/wallet-accounts@getAccountsRequest',
  getAccountsSuccess = 'super-admin/wallet-accounts@getAccountsSuccess',
  getAccountsFailure = 'super-admin/wallet-accounts@getAccountsFailure',
  onChangeAccountsPage = 'super-admin/wallet-accounts@onChangeAccountsPage',
  onChangeAccountsPageSize = 'super-admin/wallet-accounts@onChangeAccountsPageSize',
  createAccountRequest = 'super-admin/wallet-accounts@createAccountRequest',
  createAccountSuccess = 'super-admin/wallet-accounts@createAccountSuccess',
  createAccountFailure = 'super-admin/wallet-accounts@createAccountFailure',
  onChangeDialogId = 'super-admin/wallet-accounts@onChangeDialogId',
}
export type GetAccountsSuccess = DefaultQueries & {
  accounts: WalletAccount[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getSeasonsRequest = createAction<AccountsFilter>(
  Types.getAccountsRequest
);
export const getSeasonsSuccess = createAction<GetAccountsSuccess>(
  Types.getAccountsSuccess
);
export const getSeasonsFailure = createAction<string>(Types.getAccountsFailure);
export const onChangeSeasonsPage = createAction<AccountsFilter>(
  Types.onChangeAccountsPage
);
export const onChangeSeasonsPageSize = createAction<AccountsFilter>(
  Types.onChangeAccountsPageSize
);
export const createAccountRequest = createAction<CreateWalletAccountForm>(
  Types.createAccountRequest
);
export const createAccountSuccess = createAction(Types.createAccountSuccess);
export const createAccountFailure = createAction<string>(
  Types.createAccountFailure
);
export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      state.accountsList.isLoading = false;
      state.create.isCreating = false;
      state.tiersList.isLoading = false;
    })
    .addCase(getSeasonsRequest, (state) => {
      state.accountsList.isLoading = true;
      state.accountsList.error = null;
    })
    .addCase(getSeasonsSuccess, (state, { payload }) => {
      return {
        ...state,
        accountsList: {
          ...state.accountsList,
          ...payload,
          isLoading: false,
        },
      };
    })
    .addCase(getSeasonsFailure, (state, { payload }) => {
      state.accountsList.isLoading = false;
      state.accountsList.error = payload;
    })
    .addCase(createAccountRequest, (state) => {
      state.create.isCreating = true;
      state.create.error = null;
    })
    .addCase(createAccountSuccess, (state) => {
      state.create.isCreating = false;
      state.dialogId = null;
    })
    .addCase(createAccountFailure, (state, { payload }) => {
      state.create.isCreating = false;
      state.create.error = payload;
    })
    .addCase(onChangeDialogId, (state, { payload }) => {
      state.dialogId = payload;
    });
});

export default reducer;
