import { combineReducers } from 'redux';
import purchases from './purchases';
import sales from './sales';
import inventories from './inventories';
import expenses from './expenses';
import wallets from './wallets';

export default combineReducers({
  purchases,
  sales,
  inventories,
  expenses,
  wallets,
});
